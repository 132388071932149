import { Outlet } from "react-router-dom";
import { useLoggedInOnly } from "../../lib/hooks/useLoggedInOnly.tsx";

export default function AcademyLayout() {
  useLoggedInOnly();

  return (
    <>
      <Outlet />
    </>
  );
}
