import { type AssistantEvaluationReturnType } from "./../../../../backend/src/api/promptingAssistant/promptingAssistantTypes";
import type {
  ApiCreateWorkshop,
  ApiWorkshop,
  ApiWorkshopParticipantStats,
  CreateApiSolutionAttempt,
  CreateApiWorkshopAnalyticsEvent,
  Payload,
} from "apiTypes";
import _, { defaultTo } from "lodash";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "../../router";
import {
  useOrganizationApi,
  useRootApi,
  useRootResource,
  useRootSWR,
} from "../hooks/useApi";
import type { useChallengeEvaluationsScore } from "../prompts/specific/challenge";
import { trpc } from "./trpc/trpc.ts";

export function getPayloadId(doc: { id: number } | number) {
  return typeof doc === "object" ? doc.id : doc;
}

// this function will take a payload type of T|number and return the T or throw an error
export function unwrapPayloadArray<T>(doc: (T | number)[]): T[] {
  if (doc.some((d) => typeof d == "number")) {
    throw new Error("Invalid payload reference");
  }
  return doc as T[];
}

export function useCourses() {
  const { i18n } = useTranslation();
  return useRootResource<Payload.Course[]>(
    "elearning/courses?locale=" + i18n.resolvedLanguage
  );
}

export function useCourse(courseId: string) {
  const { i18n } = useTranslation();
  return useRootResource<CourseWithCompletion>(
    `elearning/courses/${courseId}?locale=${i18n.resolvedLanguage}`
  );
}

export function useCourseProgress(courseId: string) {
  const course = useCourse(courseId);
  const firstUnsolvedExercise = course?.exercises
    .sort((a, b) => a.order - b.order)
    .find((e) => !(e.exercise as ExerciseWithCompletion).completed);
  const numCompleted = course?.exercises.filter(
    (e) => (e.exercise as ExerciseWithCompletion).completed
  ).length;
  const numTotal = course?.exercises.length;

  return {
    numCompleted,
    numTotal,
    firstUnsolvedExerciseId: firstUnsolvedExercise?.exercise
      ? getPayloadId(firstUnsolvedExercise?.exercise) + ""
      : undefined,
  };
}

export type ExerciseWithCompletion = Payload.Exercise & {
  completed: boolean;
};

export type CourseWithCompletion = Payload.Course & {
  exercises: ExerciseDetails[];
};

export type ExerciseDetails = {
  exercise: ExerciseWithCompletion;
  order: number;
  isRequired: boolean;
  id?: string | null;
};

export function useExercise(courseId: string, exerciseId: string) {
  const { i18n } = useTranslation();

  return useRootResource<ExerciseWithCompletion>(
    `elearning/courses/${courseId}/exercises/${exerciseId}?locale=${i18n.resolvedLanguage}`
  );
}

export function useMutateCourse(courseId: string) {
  const { i18n } = useTranslation();
  return useRootSWR(
    `elearning/courses/${courseId}?locale=${i18n.resolvedLanguage}`
  ).mutate;
}

export function useMutateExercise(courseId: string, exerciseId: string) {
  const { i18n } = useTranslation();

  const mutateExercise = useRootSWR(
    `elearning/courses/${courseId}/exercises/${exerciseId}?locale=${i18n.resolvedLanguage}`
  ).mutate;
  const mutateCourse = useMutateCourse(courseId);
  return async () => {
    await mutateExercise();
    await mutateCourse();
  };
}

export function useNavigateToNextExercise() {
  const { courseId, organizationId, workshopId } = useParams(
    "/:organizationId/learn/:workshopId/course/:courseId/exercise/:exerciseId"
  );
  const navigate = useNavigate();

  const nextId = useNextExerciseId();

  if (nextId === undefined) {
    return () =>
      navigate("/:organizationId/learn/:workshopId/course/:courseId/done", {
        params: {
          organizationId,
          courseId,
          workshopId,
        },
      });
  }

  return () =>
    navigate(
      "/:organizationId/learn/:workshopId/course/:courseId/exercise/:exerciseId",
      {
        params: {
          organizationId,
          courseId,
          exerciseId: nextId ?? "",
          workshopId,
        },
      }
    );
}

export function usePreloadNextExercise() {
  const nextId = useNextExerciseId();
  // in case this is the next one, don't try to preload the next. because hooks cant be called conditionally, this is a workaround
  const currentId = useParams(
    "/:organizationId/learn/:workshopId/course/:courseId/exercise/:exerciseId"
  ).exerciseId;
  useExercise(
    useParams("/:organizationId/learn/:workshopId/course/:courseId").courseId,
    nextId ?? currentId
  );
}

export function useNextExerciseId() {
  const { courseId, exerciseId } = useParams(
    "/:organizationId/learn/:workshopId/course/:courseId/exercise/:exerciseId"
  );

  const exercises = useCourse(courseId)?.exercises.sort(
    (a, b) => a.order - b.order
  );

  if (!exercises) {
    return undefined;
  }

  const currentIndex = exercises.findIndex(
    (e) => getPayloadId(e.exercise) + "" === exerciseId
  );

  if (currentIndex === -1) {
    return undefined;
  }

  const nextExercise = exercises[currentIndex + 1];

  if (!nextExercise) {
    return undefined;
  }

  return getPayloadId(nextExercise.exercise) + "";
}

export function useWorkshopParticipantsStats(
  workshopId: string,
  refreshInterval: number = 5000
): ApiWorkshopParticipantStats[] | null | undefined {
  return useRootResource(`workshops/${workshopId}/participants/overview`, {
    refreshInterval,
  });
}

export function useCreateWorkshop() {
  const utils = trpc.useUtils();
  const { mutateAsync: createWorkshop } =
    trpc.elearning.workshop.create.useMutation({
      trpc: {
        context: {
          errorMessage: "errors.workshopCreationFailed",
        },
      },
    });

  return async (workshop: ApiCreateWorkshop) => {
    await createWorkshop(workshop);
    await utils.elearning.workshop.getAll.invalidate();
  };
}

export function useCreateSolutionAttempt() {
  const orgApi = useOrganizationApi();
  return async (solutionAttempt: CreateApiSolutionAttempt) => {
    await orgApi.post("elearning/solution-attempts", solutionAttempt);
  };
}

type ChallengeEvaluationReturnType =
  | ReturnType<typeof useChallengeEvaluationsScore>["evaluations"]
  | null;

export function useSolutionAttemptPoster(
  promptingAssistantEvaluation: AssistantEvaluationReturnType,
  promptingAssistantEnabled: boolean,
  challengeEvaluation: ChallengeEvaluationReturnType,
  challengeSolutionAttempt: number,
  courseId: string,
  exerciseId: number,
  chatId: string
) {
  const solved = challengeEvaluation?.every((ce) => ce.completed);

  const createSolutionAttempt = useCreateSolutionAttempt();

  // this hook should post whenever the promptingAssistantEvaluation and challengeEvaluation have both been updated. use _.isEqual to compare the two objects
  const lastPromptingAssistantEvaluation =
    useRef<AssistantEvaluationReturnType>([]);
  const lastChallengeSolutionAttempt = useRef<number>(challengeSolutionAttempt);
  const promptingAssistantHasChanged = useRef(false);
  const challengeHasChanged = useRef(false);

  if (
    !_.isEqual(
      lastPromptingAssistantEvaluation.current,
      promptingAssistantEvaluation
    )
  ) {
    promptingAssistantHasChanged.current = true;
    lastPromptingAssistantEvaluation.current = promptingAssistantEvaluation;
    console.log("promptingAssistantEvaluation has changed");
  }

  if (lastChallengeSolutionAttempt.current !== challengeSolutionAttempt) {
    challengeHasChanged.current = true;
    lastChallengeSolutionAttempt.current = challengeSolutionAttempt;
    console.log("challengeSolutionAttempt has changed");
  }

  const mutateExercise = useMutateExercise(courseId, exerciseId + "");

  // promptingAssistant: either it is disabled or it has changed and a current value
  // challengeEvaluation: it has changed and has a current value
  if (
    ((promptingAssistantEnabled &&
      promptingAssistantEvaluation &&
      promptingAssistantHasChanged.current) ||
      !promptingAssistantEnabled) &&
    challengeHasChanged.current &&
    challengeEvaluation
  ) {
    promptingAssistantHasChanged.current = false;
    challengeHasChanged.current = false;
    createSolutionAttempt({
      courseId: parseInt(courseId),
      exerciseId: exerciseId,
      chatId: chatId,
      SolutionAttemptEvaluation: challengeEvaluation.map((ce) => ({
        challengeEvaluationId: ce.id + "",
        passed: ce.completed,
      })),
      solved: solved ?? false,
      skipped: false,
    })
      .catch(console.error)
      .finally(() => {
        mutateExercise().catch(console.error);
      });
  }
}

export function useUpdateWorkshop() {
  const rootApi = useRootApi();
  const utils = trpc.useUtils();

  return async (workshopId: string, workshop: Partial<ApiWorkshop>) => {
    await rootApi.patch(`workshops/admin/${workshopId}`, workshop);
    await utils.elearning.workshop.getAll.invalidate();
  };
}

export function useCaptureAnalyticsEvent() {
  const { courseId, exerciseId, workshopId } = useParams(
    "/:organizationId/learn/:workshopId/course/:courseId/exercise/:exerciseId"
  );

  const api = useRootApi({ disableErrorToast: true });
  return (
    eventType: CreateApiWorkshopAnalyticsEvent["eventType"],
    eventData?: Partial<Omit<CreateApiWorkshopAnalyticsEvent, "eventType">>
  ) => {
    const parsedCourseId =
      eventData?.courseId ?? defaultTo(parseInt(courseId), null);
    const parsedExerciseId =
      eventData?.exerciseId ?? defaultTo(parseInt(exerciseId), null);

    void api.post("elearning/analytics", {
      eventType,
      courseId: parsedCourseId,
      exerciseId: parsedExerciseId,
      workshopEventId: workshopId != "none" ? workshopId : null,
    } satisfies CreateApiWorkshopAnalyticsEvent);
  };
}
