import type { ApiWorkshop, Payload } from "apiTypes";
import { useTranslation } from "react-i18next";
import { useRootResource } from "../hooks/useApi";

export function useMyWorkshops() {
  return useRootResource("workshops/myworkshops/", {
    refreshInterval: 1500,
  }) as { id: string; name: string }[] | null | undefined;
}
export function useWorkshop(workshopId: string) {
  return useRootResource(`workshops/${workshopId}`, {
    refreshInterval: 1500,
  }) as ApiWorkshop | null | undefined;
}

export function useWorkshopCourses(workshopId: string) {
  const locale = useTranslation().i18n.language;
  return useRootResource<Payload.Course[]>(
    `elearning/courses?workshopId=${workshopId}&locale=${locale}`
  );
}

export function useRecommendedWorkshopCourses(workshopId: string) {
  return useRootResource<{
    recommendedCourse: Payload.Course | null;
    easyCourses: Payload.Course[];
    mediumCourses: Payload.Course[];
    hardCourses: Payload.Course[];
    otherCourses: Payload.Course[];
  }>(`elearning/courses/recommended?workshopId=${workshopId}`);
}
