import {
  Analytics,
  AutoAwesome,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  LiveHelp,
  School,
  Settings,
} from "@mui/icons-material";
import {
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/joy";
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Sheet from "@mui/joy/Sheet";
import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useOrganization } from "../../lib/api/organization";
import { useMe } from "../../lib/api/user";
import { useTranslation } from "../../lib/i18n";
import { comparePath } from "../../lib/routeUtils";
import { Link as RouterLink, useNavigate, useParams } from "../../router";

import { UserMenu } from "../auth/UserMenu";
import { BrandedLogo } from "../branding/BrandedLogo";
import { HelpCenterModal } from "../util/HelpCenterModal";
import ChatTree from "./ChatTree";
import { OrganizationTree } from "./OrganizationTree";
import ToolsList from "./ToolsList";
import { useGuide } from "../onboarding/useGuide";
import type { OrganizationPhase } from "../../../../backend/src/api/organization/organizationTypes";
import { differenceInDays } from "date-fns";

export function Sidebar({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (o: boolean) => void;
}) {
  const { t } = useTranslation();

  const params = useParams("/:organizationId");
  const path = useLocation().pathname;

  const organizationId = params.organizationId;

  const organisation = useOrganization();
  const me = useMe();

  const navigate = useNavigate();

  const [openHelpCenter, setOpenHelpCenter] = useState(false);

  const { setStep, step, completed } = useGuide();

  function makeTourStep() {
    if (!completed) {
      setStep(step + 1);
    }
  }

  return (
    <Sheet
      className="Sidebar"
      sx={{
        zIndex: 20,
        position: "sticky",
        height: "100dvh",
        width: "320px",
        top: 0,
        px: 1.5,
        py: 2,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        borderRight: "1px solid",
        borderColor: "divider",
        marginLeft: open ? 0 : "-320px",
        transition: "margin-left 0.2s",
      }}
      id="sidebar"
    >
      <HelpCenterModal open={openHelpCenter} setOpen={setOpenHelpCenter} />
      <Tooltip title={open ? t("sidebar.collapse") : t("sidebar.open")}>
        <IconButton
          style={{
            position: "absolute",
            right: 2,
            top: 5,
            transform: open ? "translateX(0)" : "translateX(120%)",
          }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          {open ? <KeyboardDoubleArrowLeft /> : <KeyboardDoubleArrowRight />}
        </IconButton>
      </Tooltip>
      <div
        className="flex w-full cursor-pointer flex-col items-center py-5"
        onClick={() => {
          navigate(`/:organizationId`, {
            params,
          });
        }}
      >
        <BrandedLogo className="h-16 px-10" />
      </div>
      <List
        sx={{
          "--ListItem-radius": "8px",
          "--List-gap": "4px",
          "--List-nestedInsetStart": "40px",
          flexGrow: 0,
        }}
      >
        <RouterLink
          to="/:organizationId"
          params={{
            organizationId,
          }}
          onClick={makeTourStep}
        >
          <ListItem id="newChatButton">
            <ListItemButton variant="solid" color="primary">
              <ListItemDecorator>
                <AutoAwesome fontSize="small" />
              </ListItemDecorator>
              <ListItemContent className="flex flex-row items-center gap-2">
                {t("newChat")}
              </ListItemContent>
            </ListItemButton>
          </ListItem>
        </RouterLink>
      </List>
      <Box
        sx={{
          minHeight: 0,
          overflow: "hidden auto",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <div className="flex flex-col justify-start">
          <ChatTree />
          <ToolsList />
          <OrganizationTree />
        </div>
      </Box>
      <List
        sx={{
          mt: "auto",
          flexGrow: 0,
          "--ListItem-radius": "8px",
          "--List-gap": "8px",
        }}
      >
        {organisation?.defaultWorkshopId && (
          <ListItem>
            <ListItemButton
              onClick={() => {
                navigate("/:organizationId/learn/:workshopId", {
                  params: {
                    organizationId: organizationId,
                    workshopId: organisation.defaultWorkshopId!,
                  },
                });
              }}
            >
              <ListItemDecorator>
                <School />
              </ListItemDecorator>
              <ListItemContent>{t("e-learning")}</ListItemContent>
            </ListItemButton>
          </ListItem>
        )}
        {me?.isOrganizationAdmin && (
          <>
            <ListItem>
              <ListItemButton
                selected={comparePath(path, "/:organizationId/adoption")}
                onClick={() => {
                  navigate("/:organizationId/adoption", {
                    params: {
                      organizationId: organizationId,
                    },
                  });
                }}
              >
                <ListItemDecorator>
                  <Analytics />
                </ListItemDecorator>
                <ListItemContent>{t("settings.tabs.metrics")}</ListItemContent>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                selected={comparePath(path, "/:organizationId/settings")}
                onClick={() => {
                  navigate("/:organizationId/settings", {
                    params: {
                      organizationId: organizationId,
                    },
                  });
                }}
              >
                <ListItemDecorator>
                  <Settings />
                </ListItemDecorator>
                <ListItemContent>{t("einstellungen")}</ListItemContent>
              </ListItemButton>
            </ListItem>
          </>
        )}
        <ListItem>
          <ListItemButton
            onClick={() => {
              setOpenHelpCenter(true);
            }}
          >
            <ListItemDecorator>
              <LiveHelp />
            </ListItemDecorator>
            <ListItemContent>{t("helpAndFeedback")}</ListItemContent>
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      {organisation && organisation?.phase !== "NORMAL" && (
        <PhaseContainer
          phase={organisation.phase}
          start={organisation.phaseStartDate ?? ""}
          end={organisation.phaseEndDate ?? ""}
        />
      )}
      <div className="flex items-center gap-3">
        <UserMenu languageSelector />
        <div className="text-sm font-bold">
          @ {organisation && organisation.name}
        </div>
      </div>
      <Divider />
      <div className="-my-2 flex flex-col items-center gap-1">
        <div className="text-xs">
          powered by{" "}
          <a
            href="https://meingpt.com/"
            className="text-blue-500 underline transition-all hover:text-blue-700"
          >
            meinGPT
          </a>
        </div>
      </div>
    </Sheet>
  );
}

interface PhaseContainerProps {
  phase: OrganizationPhase;
  start: string;
  end: string;
}
function PhaseContainer({ phase, start, end }: PhaseContainerProps) {
  const { t } = useTranslation();

  const isPilot = phase === "PILOT";

  const { range, remaining } = useMemo(() => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const now = new Date();

    return {
      range: differenceInDays(endDate, startDate),
      remaining: differenceInDays(endDate, now),
    };
  }, [start, end]);

  // only show pilot if it is about to run out
  if (isPilot && remaining > 14) return;
  return (
    <Stack gap={1}>
      <Stack direction="row" flex={1} justifyContent="space-between">
        <Typography level="body-sm" fontWeight="500">
          {isPilot ? t("trial.pilotActive") : t("trial.trialActive")}
        </Typography>
        <Typography level="body-sm">
          {t("trial.daysLeft", { count: Math.max(remaining, 0) })}
        </Typography>
      </Stack>
      <LinearProgress
        determinate
        sx={{ backgroundColor: "rgba(30, 93, 194, .2)" }}
        value={Math.min(Math.max(((range - remaining) / range) * 100, 1), 100)}
        color="primary"
        variant="soft"
      />
    </Stack>
  );
}
