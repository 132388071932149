import type { TextareaProps } from "@mui/joy";
import { FormControl, FormHelperText, FormLabel, Textarea } from "@mui/joy";
import type { FieldProps } from "formik";
import { Field, getIn } from "formik";

export interface FormikTextAreaProps
  extends Omit<TextareaProps, "value" | "slots"> {
  helperText?: string;
  label?: string;
  containerClass?: string;
}

function FormikTextAreaAdapter({
  form: { isSubmitting, touched, errors },
  field,
  disabled,
  helperText,
  required,
  label,
  containerClass,
  ...props
}: FormikTextAreaProps & FieldProps) {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return (
    <FormControl
      disabled={disabled ?? isSubmitting}
      error={showError}
      required={required}
      className={containerClass ?? ""}
    >
      {label && <FormLabel>{label}</FormLabel>}
      <Textarea {...field} {...props} />
      <FormHelperText>{showError ? fieldError : helperText}</FormHelperText>
    </FormControl>
  );
}

export function FormikTextarea(props: FormikTextAreaProps) {
  return <Field {...props} component={FormikTextAreaAdapter} />;
}
