import { Delete, MoreVert } from "@mui/icons-material";
import {
  Card,
  CardActions,
  CardContent,
  Chip,
  CircularProgress,
  Dropdown,
  IconButton,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/joy";
import type { Meeting } from "../../../../../../../backend/src/api/tools/meetingRecorder/meetingRecorderTypes.ts";
import { trpc } from "../../../../../lib/api/trpc/trpc.ts";
import { MeetingDate } from "./MeetingDate.tsx";
import { MeetingDuration } from "./meetingDuration.tsx";

interface MeetingCardProps {
  meeting: Meeting;
  onClick?: () => void;
}

export function MeetingCard({ meeting, onClick }: MeetingCardProps) {
  const { mutateAsync: deleteMeeting, isPending } =
    trpc.tools.meetingRecorder.delete.useMutation();
  const utils = trpc.useUtils();

  const confirmDelete = async (meetingId: string) => {
    if (confirm("Möchtest du das Meeting wirklich löschen?")) {
      await deleteMeeting({ meetingId });
      void utils.tools.meetingRecorder.invalidate();
    }
  };
  return (
    <Card
      onClick={onClick}
      className="h-full cursor-pointer"
      sx={{
        pt: 1,
        "&:hover": {
          boxShadow: "md",
          borderColor: "neutral.outlinedHoverBorder",
        },
      }}
    >
      <div className="-mb-2">
        <MeetingStatusChip status={isPending ? "DELETING" : meeting.status} />
      </div>
      <CardContent>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            level="title-lg"
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {meeting.name}
          </Typography>
          <Dropdown>
            <MenuButton
              onClick={(e) => e.stopPropagation()}
              slots={{ root: IconButton }}
              slotProps={{ root: { variant: "plain", color: "neutral" } }}
            >
              <MoreVert />
            </MenuButton>
            <Menu placement="bottom-end">
              <MenuItem
                color="danger"
                disabled={isPending}
                onClick={(e) => {
                  e.stopPropagation();
                  void confirmDelete(meeting.id);
                }}
              >
                <ListItemDecorator>
                  <Delete />
                </ListItemDecorator>
                Löschen
              </MenuItem>
            </Menu>
          </Dropdown>
        </Stack>

        {meeting.summary ? (
          <Typography className="!line-clamp-3">{meeting.summary}</Typography>
        ) : (
          <Typography color="neutral" fontStyle="italic">
            Keine Zusammenfassung verfügbar
          </Typography>
        )}
      </CardContent>

      <CardActions sx={{ gap: 3 }}>
        <MeetingDate date={meeting.date} />
        <MeetingDuration duration={meeting.duration} />
      </CardActions>
    </Card>
  );
}

function MeetingStatusChip({
  status,
}: {
  status: Meeting["status"] | "DELETING";
}) {
  switch (status) {
    case "DONE":
      return null;
    case "SUMMARY_GENERATION":
    case "TRANSCRIBING":
      return (
        <Chip
          color="primary"
          startDecorator={
            <CircularProgress
              sx={{
                mr: 0.5,
                "--CircularProgress-size": "16px",
                "--CircularProgress-trackThickness": "2px",
                "--CircularProgress-progressThickness": "2px",
              }}
            />
          }
        >
          {status === "TRANSCRIBING"
            ? "Wird verarbeitet"
            : "Protokoll wird erstellt"}
        </Chip>
      );
    case "DELETING":
      return (
        <Chip
          color="danger"
          startDecorator={
            <CircularProgress
              color="danger"
              sx={{
                mr: 0.5,
                "--CircularProgress-size": "16px",
                "--CircularProgress-trackThickness": "2px",
                "--CircularProgress-progressThickness": "2px",
              }}
            />
          }
        >
          Wird gelöscht
        </Chip>
      );
    case "FAILED":
      return <Chip color="danger">Fehlgeschlagen</Chip>;
  }
}
