import { useTranslation } from "react-i18next";
import { Card, Typography } from "@mui/joy";
import {
  imageStyles,
  type ImageStyle,
} from "../../../../backend/src/api/tools/images/imagesTypes";
import { imageStyleExamples } from "./styles";

export function ImageStyleSelector({
  selectedStyle,
  setSelectedStyle,
  onClose,
}: {
  selectedStyle: ImageStyle | null;
  setSelectedStyle: (style: ImageStyle) => void;
  onClose: () => void;
}) {
  const { t } = useTranslation();

  return (
    <div className="flex w-[min(80vw,1300px)] flex-col items-center gap-10 overflow-x-hidden p-10">
      <Typography level="h1">{t("images.style.selectstyle")}</Typography>
      <div className="grid grid-cols-1 gap-x-12 gap-y-5 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
        {imageStyles.map((style) => (
          <StyleCard
            key={style}
            style={style}
            selected={selectedStyle === style}
            onClick={() => {
              setSelectedStyle(style);
              onClose();
            }}
          />
        ))}
      </div>
    </div>
  );
}

function StyleCard({
  style,
  selected,
  onClick,
}: {
  style: ImageStyle;
  selected: boolean;
  onClick: () => void;
}) {
  const { t } = useTranslation();
  const example = imageStyleExamples[style];

  return (
    <div className="my-3 flex flex-col items-center gap-2">
      <Card
        onClick={onClick}
        className="cursor-pointer transition-all hover:shadow-lg"
        variant={selected && style !== "none" ? "solid" : "outlined"}
        color={selected ? "primary" : "neutral"}
        sx={{
          width: 250,
          height: 250,
          border: style === "none" && selected ? "8px solid" : "",
        }}
      >
        {typeof example === "string" ? (
          <img src={example} alt={style} />
        ) : (
          example
        )}
      </Card>
      <Typography level="title-lg">{t(`images.styles.${style}`)}</Typography>
    </div>
  );
}
