import type { WorkflowInput } from "../../../../backend/src/api/workflow/workflowTypes.ts";

/**
 * Checks if the input options are incomplete.
 *
 * @param input - The workflow input to check.
 * @returns `true` if the input options are incomplete, otherwise `false`.
 */
export function inputOptionsIncomplete(input: WorkflowInput) {
  if (!input.name) return true;

  if (input.type === "enum" && input.options.length === 0) return true;

  if (input.type === "toggle") {
    return (
      input.options.length !== 2 ||
      input.options.filter((o) => o.value).length == 0
    );
  }

  return input.options.some((o) => !o.label || !o.value);
}
