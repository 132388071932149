import { Box } from "@mui/joy";
import { useTranslation } from "../../../lib/i18n";
import { useCallback, useEffect, useState } from "react";
import { trpc } from "../../../lib/api/trpc/trpc";
import {
  base64ToBlob,
  downloadBlob,
  fileToBase64,
  splitByExtention,
  targetToSourceLanguage,
} from "../../../lib/util";
import { toast } from "react-toastify";
import TranslateTable from "../TranslateTable";
import { type SelectedLanguages } from "../../../pages/[organizationId]/tools/translateContent";
import { Ready } from "./translationStates/Ready";
import { FileChosen } from "./translationStates/FileChosen";
import { DocumentTranslationError } from "./translationStates/DocumentTranslationError";
import type {
  DeepLSourceLanguageWithDetect,
  DeepLTargetLanguage,
} from "../../../../../backend/src/api/tools/translateContent/deeplTranslator/deeplTranslatorLanguages";
import * as Sentry from "@sentry/react";

// Sizes in bytes
const MAX_SIZE_LIMITS = {
  doc: 31_457_280, // 30MB
  docx: 31_457_280, // 30MB
  pptx: 31_457_280, // 30MB
  pdf: 31_457_280, // 30MB
  txt: 1_048_576, // 1MB
  html: 5_242_880, // 5MB
  htm: 5_242_880, // 5MB
  xlf: 10_485_760, // 10MB
  xliff: 10_485_760, // 10MB
  srt: 1_048_576, // 1MB
};

export type TranslationState = "READY" | "FILE_CHOSEN" | "ERROR";

export default function DocumentPanel({
  selectedLanguages,
  setSelectedLanguages,
  glossaryId,
}: {
  selectedLanguages: SelectedLanguages;
  setSelectedLanguages: (languages: SelectedLanguages) => void;
  glossaryId?: string;
}) {
  const { t } = useTranslation();
  const [file, setFile] = useState<File | undefined>();
  const [translationState, setTranslationState] =
    useState<TranslationState>("READY");

  const translateMutation =
    trpc.tools.translateContent.documentTranslator.translate.useMutation({
      trpc: {
        context: {
          silentError: true,
        },
      },
    });

  const uploadDocument = translateMutation.mutateAsync;

  const checkSizeLimit = useCallback(
    (newFile: File) => {
      const { name, size } = newFile;
      const [, extension] = splitByExtention(name);
      const max = MAX_SIZE_LIMITS[extension];
      const valid = size < max;
      if (!valid)
        toast.error(t("errors.fileTooLarge", { size: max / (1024 * 1024) })); // convert bytes to MB

      return valid;
    },
    [t]
  );

  useEffect(() => {
    // toasts inside the function
    if (file && !checkSizeLimit(file)) {
      setFile(undefined);
    }
  }, [file, checkSizeLimit]);

  const { tooltip, title } = {
    tooltip: `${file?.name} (${file?.size})`,
    title: file?.name ?? "",
  };

  const { sourceLanguage, targetLanguage } = selectedLanguages;

  const handleTranslation = async () => {
    if (!file) {
      toast.error(t("common.upload.failed", { fileName: "" }));
      setTranslationState("READY");
      return;
    }
    try {
      setTranslationState("FILE_CHOSEN");
      const { name, type } = file;
      const [filename, extension] = splitByExtention(name);
      const content = (await fileToBase64(file)).split(",")[1]; // split to remove metadata

      const {
        document: { data },
      } = await uploadDocument({
        content,
        extension,
        source_lang: sourceLanguage as DeepLSourceLanguageWithDetect,
        target_lang: targetLanguage as DeepLTargetLanguage,
        glossaryId,
      });

      const blob = base64ToBlob(data, type);
      const cleanLanguage = targetToSourceLanguage(targetLanguage);
      downloadBlob(blob, `${filename}_${cleanLanguage}`);
      setFile(undefined);
      setTranslationState("READY");
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      setTranslationState("ERROR");
    }
  };

  return (
    <TranslateTable
      selectedLanguages={selectedLanguages}
      isLoading={translateMutation.isPending}
      setSelectedLanguages={setSelectedLanguages}
    >
      <Box
        display="flex"
        sx={{
          alignItems: "center",
          justifyContent: "center",
          height: "50vh",
          maxHeight: "600px",
        }}
      >
        <div className="flex h-full w-full flex-col items-center justify-center gap-10">
          {
            {
              READY: (
                <Ready
                  setFile={setFile}
                  setTranslationState={setTranslationState}
                />
              ),
              FILE_CHOSEN: (
                <FileChosen
                  tooltip={tooltip}
                  title={title}
                  setFile={setFile}
                  handleTranslation={handleTranslation}
                  loading={translateMutation.isPending}
                />
              ),
              ERROR: (
                <DocumentTranslationError
                  handleTranslation={handleTranslation}
                  message={translateMutation.error?.message}
                  resetMutation={translateMutation.reset}
                  setTranslationState={setTranslationState}
                  setFile={setFile}
                />
              ),
            }[translationState]
          }
        </div>
      </Box>
    </TranslateTable>
  );
}
