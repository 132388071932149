import {
  Chip,
  IconButton,
  ModalDialog,
  Table,
  Typography,
  Modal,
  ModalClose,
  Button,
} from "@mui/joy";
import {
  useMe,
  useMutateOrganizationUsers,
  useOrganizationUsers,
  useToggleUserPermissions,
} from "../../../lib/api/user";
import type { ApiOrganizationUser } from "apiTypes";
import { UserAvatar } from "../../auth/UserMenu";
import { Delete, Edit } from "@mui/icons-material";
import { useBoolean } from "usehooks-ts";
import { toast } from "react-toastify";
import { useTranslation } from "../../../lib/i18n";
import { DelayedLoader } from "../../util/DelayadLoader";
import ConfirmationDialog from "../ConfirmationDialog";
import { useState } from "react";
import { useOrganizationApi } from "../../../lib/hooks/useApi";
import { SettingsPage } from "../SettingsPage";

export function UsersEditor() {
  const { t } = useTranslation();

  const users = useOrganizationUsers();
  const [removeUserId, setRemoveUserId] = useState<string | null>(null);
  const api = useOrganizationApi();
  const mutate = useMutateOrganizationUsers();

  if (!users) return <DelayedLoader />;

  const removeUser = async () => {
    try {
      await api.delete(`users/${removeUserId}`);
      toast.success(t("userRemoved"));
      await mutate();
    } catch (e) {
      console.error(e);
      toast.error(t("userRemoveFailed"));
    }
    setRemoveUserId(null);
  };

  return (
    <SettingsPage title={t("users")}>
      <Table stickyHeader>
        <thead>
          <tr>
            <th>{t("name")}</th>
            <th>E-Mail</th>
            {/* <th style={{ width: "9%" }}>{t("prompts")}</th> */}
            {/* <th style={{ width: "10%" }}>{t("lastLogin")}</th> */}
            {/* <th style={{ width: "10%" }}>{t("lastActivity")}</th> */}
            <th style={{ width: "15%" }}>{t("permissions")}</th>
            <th style={{ width: "6%" }}></th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <User key={user.id} user={user} setRemoveUserId={setRemoveUserId} />
          ))}
        </tbody>
      </Table>
      <ConfirmationDialog
        open={removeUserId !== null}
        onClose={() => setRemoveUserId(null)}
        title={t("areYouSure")}
        description={t("userRemoveInfo")}
        onConfirm={removeUser}
      />
    </SettingsPage>
  );
}

function User({
  user,
  setRemoveUserId,
}: {
  user: ApiOrganizationUser;
  setRemoveUserId: (id: string) => void;
}) {
  const { t } = useTranslation();

  const me = useMe();
  const isMe = me?.id === user.id;
  if (!me) return null;

  return (
    <>
      <tr>
        <td>
          <div className="flex flex-row items-center gap-3">
            <UserAvatar userId={user.id} size="sm" />
            {user.firstName} {user.lastName}
            {isMe && (
              <Chip color="primary" variant="solid">
                {t("youLabel")}
              </Chip>
            )}
          </div>
        </td>
        <td>{user.primaryEmail}</td>
        {/* <td>{user.totalMessages}</td> */}
        {/* <td>{niceDate(user.lastLogin)}</td> */}
        {/* <td>{niceDate(user.lastActive)}</td> */}
        <td>
          <PermissionsChip hasAdmin={user.isOrganizationAdmin} user={user} />
        </td>
        <td>
          {!user.isOrganizationAdmin && (
            <IconButton
              onClick={() => {
                setRemoveUserId(user.id);
              }}
            >
              <Delete />
            </IconButton>
          )}
        </td>
      </tr>
    </>
  );
}

// function niceDate(date: string | null) {
//   if (!date) return "-";
//   const d = new Date(date);
//   return `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`;
// }

function PermissionsChip({
  hasAdmin,
  user,
}: {
  hasAdmin: boolean;
  user: ApiOrganizationUser;
}) {
  const { t } = useTranslation();

  const me = useMe();
  const isMe = me?.id === user.id;

  const togglePermissions = useToggleUserPermissions();

  const onToggleConfirm = () => {
    togglePermissions(user.id)
      .then(() => {
        toast.success(
          t("changedPermissionsOfUser", {
            name: `${user.firstName} ${user.lastName}`,
          })
        );
      })
      .catch(() => {
        toast.error(
          t("changePermissionsOfUserFailed", {
            name: `${user.firstName} ${user.lastName}`,
          })
        );
      })
      .finally(onClose);
  };

  const {
    value: isOpen,
    setTrue: onOpen,
    setFalse: onClose,
  } = useBoolean(false);

  return (
    <div className="flex flex-row items-center gap-1">
      {hasAdmin ? (
        <Chip color="danger" variant="solid">
          Admin
        </Chip>
      ) : (
        <Chip>{t("standartPermissions")}</Chip>
      )}
      {!isMe && (
        <IconButton onClick={!isMe ? onOpen : () => {}}>
          <Edit fontSize="small" />
        </IconButton>
      )}
      <Modal open={isOpen} onClose={onClose}>
        <ModalDialog>
          <ModalClose />
          <Typography level="h4">
            {t("changePermissionsFor")} {user.firstName} {user.lastName}
          </Typography>
          <Typography>
            {t("changePermissionsFromToForConfirm", {
              email: user.primaryEmail,
              from: t(hasAdmin ? "administrator" : "standartPermissions"),
              to: t(hasAdmin ? "standartPermissions" : "administrator"),
            })}
          </Typography>
          <div className="flex flex-row gap-2">
            <Button variant="solid" color="danger" onClick={onToggleConfirm}>
              {t(
                hasAdmin
                  ? "changePermissionsToStandardConfirm"
                  : "changePermissionsToAdminConfirm"
              )}
            </Button>
            <Button variant="outlined" color="neutral" onClick={onClose}>
              {t("cancel")}
            </Button>
          </div>
        </ModalDialog>
      </Modal>
    </div>
  );
}
