import { Card, IconButton, Input, Typography } from "@mui/joy";
import { DynamicIcon } from "../../util/DynamicIcon";
import { useState } from "react";
import * as Icons from "@mui/icons-material";

export function Autocomplete({
  options,
  value,
  iconValue,
  onChange,
  onIconChange,
}: {
  options: string[];
  value: string;
  iconValue?: string;
  onChange: (value: string) => void;
  onIconChange: (value: string) => void;
}) {
  const [open, setOpen] = useState(true);
  return (
    <div>
      <Input
        value={value}
        placeholder="MUI Icon Name"
        startDecorator={
          <DynamicIcon
            iconState={iconValue}
            iconName={value}
            sx={{ fontSize: "20px" }}
            onChange={onIconChange}
          />
        }
        endDecorator={
          <IconButton
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? <Icons.KeyboardArrowUp /> : <Icons.KeyboardArrowDown />}
          </IconButton>
        }
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
      {open && (
        <Card
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            marginTop: 1,
            overflowY: "auto",
            maxHeight: "800px",
            alignContent: "flex-start",
          }}
        >
          {options.map((option) => (
            <span
              key={option}
              onClick={() => {
                onIconChange(option);
                onChange(option);
                setOpen(false);
              }}
              className="flex w-32 cursor-pointer flex-col items-center gap-2 overflow-hidden rounded hover:bg-gray-100"
            >
              <DynamicIcon iconName={option} sx={{ fontSize: "30px" }} />
              <Typography sx={{ fontSize: "11px" }}>{option}</Typography>
            </span>
          ))}
        </Card>
      )}
    </div>
  );
}
