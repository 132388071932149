import {
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Stack,
  Textarea,
  Typography,
} from "@mui/joy";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { trpc } from "../../lib/api/trpc/trpc";
import type { CompanyDataInput } from "./CreateOrganizationModal";
import { PopoverPicker } from "../settings/PopoverPicker";
import { Clear } from "@mui/icons-material";

interface CompanyMetaContainerProps {
  companyData: CompanyDataInput;
  setCompanyData: React.Dispatch<React.SetStateAction<CompanyDataInput>>;
}

export default function CompanyMetaContainer({
  companyData,
  setCompanyData,
}: CompanyMetaContainerProps) {
  const { t } = useTranslation();

  const {
    data: metaData,
    refetch,
    isLoading: metaLoading,
  } = trpc.organization.fetchMetaData.useQuery(
    { domain: companyData.domain },
    { enabled: false }
  );

  useEffect(() => {
    if (!metaData) return;
    setCompanyData((prev) => ({ ...prev, ...metaData }));
  }, [metaData, setCompanyData]);

  const fetchData = async () => {
    if (!companyData.domain) return;
    await refetch();
  };

  return (
    <>
      <FormControl sx={{ flex: 1 }}>
        <FormLabel>{t("createOrg.domain")}</FormLabel>
        <Stack direction="row" height={30} gap={1}>
          <Input
            fullWidth
            placeholder="www.selectcode.de"
            value={companyData.domain}
            onChange={(e) =>
              setCompanyData((prev) => ({
                ...prev,
                domain: e.target.value,
              }))
            }
          />
          <Button
            disabled={!companyData.domain.length}
            loading={metaLoading}
            onClick={fetchData}
            sx={{ whiteSpace: "nowrap" }}
          >
            {t("createOrg.fetchData")}
          </Button>
        </Stack>
      </FormControl>

      <FormControl sx={{ flex: 1, mt: 2 }}>
        <Textarea
          maxRows={6}
          minRows={3}
          placeholder={t("createOrg.companyDescription")}
          value={companyData.description}
          onChange={(e) =>
            setCompanyData((prev) => ({
              ...prev,
              description: e.target.value,
            }))
          }
        />
      </FormControl>
      <Stack direction="row" sx={{ mt: 1, flex: 1, gap: 2 }}>
        <Stack gap={2}>
          <FormControl>
            <FormLabel>{t("createOrg.primaryColor")}</FormLabel>
            <ColorPicker
              color={companyData.primaryColor}
              setColor={(primaryColor) =>
                setCompanyData((prev) => ({ ...prev, primaryColor }))
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t("createOrg.secondaryColor")}</FormLabel>
            <ColorPicker
              color={companyData.secondaryColor}
              setColor={(secondaryColor) =>
                setCompanyData((prev) => ({ ...prev, secondaryColor }))
              }
            />
          </FormControl>
        </Stack>
        <Stack
          sx={{
            flex: 1,
            alignItems: "center",
          }}
        >
          <FormLabel>{t("createOrg.logo")}</FormLabel>
          <LogoContainer
            uri={companyData.imageUri}
            onClick={() =>
              setCompanyData((prev) => ({ ...prev, imageUri: "" }))
            }
          />
        </Stack>
      </Stack>
    </>
  );
}

interface LogoContainer {
  uri: string;
  onClick: () => void;
}
function LogoContainer({ uri, onClick }: LogoContainer) {
  const [hover, setHover] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
      className="mx-auto mt-2 flex h-28 w-28 cursor-pointer items-center justify-center overflow-hidden rounded-lg bg-neutral-100"
    >
      {uri && !hover && (
        <img
          className="h-full w-full object-contain"
          src={uri}
          alt="company Logo"
        />
      )}
      {uri && hover && (
        <Typography level="body-sm">{t("createOrg.removeLogo")}</Typography>
      )}
      {!uri && (
        <Typography level="body-sm">{t("createOrg.noLogoAdded")}</Typography>
      )}
    </div>
  );
}

interface ColorPickerProps {
  color: string;
  setColor: (color: string) => void;
}
function ColorPicker({ color, setColor }: ColorPickerProps) {
  return (
    <Input
      startDecorator={<PopoverPicker color={color} onChange={setColor} />}
      endDecorator={
        color && (
          <IconButton onClick={() => setColor("")}>
            <Clear />
          </IconButton>
        )
      }
      value={color}
      onChange={(e) => setColor(e.target.value)}
    />
  );
}
