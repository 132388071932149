import { useCallback } from "react";
import { useOrganizationApi } from "./hooks/useApi";

export function useDownloadAsWord(
  markdown: string,
  filename: string
): () => void {
  const orgApi = useOrganizationApi();
  const downloadDocument = useCallback(async () => {
    const res = await orgApi.post(
      `documents/download-as-docx`,
      {
        markdown,
        filename,
      },
      {
        responseType: "blob",
      }
    );
    const href = URL.createObjectURL(res.data);
    const link = document.createElement("a");
    link.href = href;
    link.download = `${filename}.docx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [markdown, filename, orgApi]);

  return downloadDocument;
}
