import { useTranslation } from "../../lib/i18n";

export function CustomError({ error = undefined }: { error?: string }) {
  const { t } = useTranslation();

  return (
    <div className="w-hull h-full flex-1 items-stretch justify-stretch p-4">
      <div className="flex flex-1 flex-col items-center justify-center gap-3 rounded border-2 border-red-500 bg-red-500 bg-opacity-50 p-3">
        <h1 className="text-4xl font-thin text-red-800">
          {t("errorDisplay.title")}
        </h1>
        <span className="text-red-800">
          {error ?? t("errorDisplay.helpText")}
        </span>
      </div>
    </div>
  );
}
