import styled from "@emotion/styled";
import { Check, ChevronLeft, Edit } from "@mui/icons-material";
import GroupIcon from "@mui/icons-material/Group";
import PinIcon from "@mui/icons-material/Pin";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  List,
  Modal,
  ModalClose,
  ModalDialog,
  Sheet,
  Skeleton,
  Textarea,
  Typography,
} from "@mui/joy";
import ListItem from "@mui/joy/ListItem";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { UserMenu } from "../../../components/auth/UserMenu.tsx";
import { WorkshopCountDown } from "../../../components/elearning/LiveWorkshopCard";
import { useCourses } from "../../../lib/api/learning.ts";
import { trpc } from "../../../lib/api/trpc/trpc.ts";
import { useAllowedRolesOnly } from "../../../lib/hooks/useAllowedRolesOnly.tsx";
import {
  useRootApi,
  useRootResource,
  useRootSWR,
} from "../../../lib/hooks/useApi";
import { Link, useParams } from "../../../router";
import { CopyWorkshopLinkButton, TestRunButton } from "../index.tsx";

const WorkshopGrid = styled.div`
  display: grid;
  grid-template-columns: clamp(300px, 20%, 650px) minmax(0, 1fr);
  grid-template-areas: "header header" "task main" "help main";
  grid-template-rows: min-content min-content 1fr;
`;

export default function TeachingWorkshopLayout() {
  useAllowedRolesOnly("TEACHER");
  const { t } = useTranslation();
  const { workshopId } = useParams("/teach/:workshopId");

  const helpRequests = useRootResource(`workshops/${workshopId}/help/all`, {
    refreshInterval: 1000,
  });
  const mutateHelpRequests = useRootSWR(
    `workshops/${workshopId}/help/all`
  ).mutate;

  const { data: workshop } = trpc.elearning.workshop.byId.useQuery({
    id: workshopId,
  });

  const api = useRootApi();

  function clearHelpRequest(helpRequestId: string) {
    api
      .post(`workshops/${workshopId}/help/${helpRequestId}/resolve`)
      .then(() => {
        mutateHelpRequests().catch(console.error);
      })
      .catch(console.error);
  }

  return (
    <Sheet variant="soft" className="h-full min-h-screen w-full px-8 py-4">
      <WorkshopGrid className="h-full w-full gap-6">
        <div style={{ gridArea: "header" }}>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <IconButton component={Link} to="/teach">
                <ChevronLeft />
              </IconButton>
              <Typography level="h3">
                {workshop == null ? (
                  <Skeleton>{t("workshopName")}</Skeleton>
                ) : (
                  workshop.name
                )}
              </Typography>
            </div>
            <UserMenu languageSelector />
          </div>
        </div>
        <Card sx={{ gridArea: "task" }}>
          <CardContent>
            <SetTaskModal workshopId={workshopId} />
            <Typography level="body-sm">{t("workshopInfo")}</Typography>
            <div className="align-center  flex gap-2">
              <GroupIcon color="disabled" />
              <Typography>
                {workshop?.participantCount} {t("participants")}
              </Typography>
            </div>
            <div className="align-center  flex gap-2">
              <PinIcon color="disabled" />
              <Typography>{workshop?.loginCode}</Typography>
            </div>
            <div>
              <CopyWorkshopLinkButton
                loginCode={workshop?.loginCode ?? "000000"}
              />
              <TestRunButton
                orgId={workshop?.organizationId ?? ""}
                workshopId={workshop?.id ?? ""}
              />
            </div>
            <Typography level="body-sm" mt={2}>
              {t("currentTask")}
            </Typography>
            <Typography level="body-lg" mb={2}>
              {(workshop?.currentTask ?? "") != ""
                ? workshop?.currentTask
                : "-"}
            </Typography>

            <Typography level="body-sm">Timer</Typography>
            <Typography level="body-lg">
              <WorkshopCountDown
                target={new Date(workshop?.currentTaskTimer ?? 0).getTime()}
              />
            </Typography>
            <Typography level="body-sm" mt={2}>
              {t("visibleCourses")}
            </Typography>
            <CourseManager workshopId={workshopId} />
          </CardContent>
        </Card>
        {workshop?.organization?.defaultWorkshopId !== workshopId && (
          <Card sx={{ gridArea: "help" }}>
            <CardContent>
              <Typography level="body-sm">{t("reachOut")}</Typography>
              <List>
                {helpRequests?.map((helpRequest, index) => (
                  <>
                    <ListItem
                      key={helpRequest.id}
                      endAction={
                        <IconButton
                          color="success"
                          onClick={() => clearHelpRequest(helpRequest.id)}
                        >
                          <Check />
                        </IconButton>
                      }
                    >
                      {helpRequest.participant.firstName}{" "}
                      {helpRequest.participant.lastName}
                    </ListItem>
                    {index != helpRequests?.length - 1 && <Divider />}
                  </>
                ))}
              </List>
            </CardContent>
          </Card>
        )}
        <div style={{ gridArea: "main" }}>
          <Outlet />
        </div>
      </WorkshopGrid>
    </Sheet>
  );
}

function SetTaskModal({ workshopId }: { workshopId: string }) {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  const [task, setTask] = useState("");
  const [timer, setTimer] = useState(0);

  const api = useRootApi();

  const onSubmit = () => {
    api
      .patch(`workshops/admin/${workshopId}`, {
        currentTask: task,
        currentTaskTimer: new Date(new Date().getTime() + timer * 60 * 1000),
      })
      .catch(console.error);
  };

  return (
    <>
      <IconButton
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
        }}
        onClick={() => {
          setModalOpen(true);
        }}
      >
        <Edit />
      </IconButton>
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <ModalDialog>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
              setModalOpen(false);
            }}
            className="] flex flex-col gap-6 p-4"
          >
            <ModalClose />
            <FormControl>
              <FormLabel>{t("task")}</FormLabel>
              <Textarea
                name="task"
                value={task}
                onChange={(e) => setTask(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Timer (in {t("minutes")})</FormLabel>
              <Input
                type="number"
                name="timer"
                value={timer}
                onChange={(e) => setTimer(parseInt(e.target.value))}
              />
            </FormControl>
            <Button type="submit">{t("setTask")}</Button>
          </form>
        </ModalDialog>
      </Modal>
    </>
  );
}

function CourseManager({ workshopId }: { workshopId: string }) {
  const resourcePath = `workshops/admin/${workshopId}`;
  const workshop = useRootResource(resourcePath);
  const mutateWorkshop = useRootSWR(resourcePath).mutate;
  const courses = useCourses();

  const api = useRootApi();

  const workshopCourses = useMemo(() => {
    if (!workshop || !courses) return [];

    return (
      courses
        .filter((course) => workshop.allCoursesAIDs.includes(course.id + ""))
        .map((course) => ({
          ...course,
          visible: workshop?.visibleCoursesAIDs.includes(course.id + ""),
        }))
        //sort by title
        .sort((a, b) => a.title.localeCompare(b.title))
    );
  }, [workshop, courses]);

  return (
    <div className="flex flex-col gap-1">
      {workshopCourses.map((course) => (
        <Checkbox
          label={course.title}
          checked={course.visible}
          key={course.id}
          onChange={() => {
            api
              .post(`workshops/admin/${workshopId}/courses/${course.id}/toggle`)
              .then(() => {
                mutateWorkshop().catch(console.error);
              })
              .catch(console.error);
          }}
        />
      ))}
    </div>
  );
}
