import { AutoAwesome } from "@mui/icons-material";
import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalClose,
  ModalDialog,
  Textarea,
  Typography,
} from "@mui/joy";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useCurrentOrganizationId } from "../../lib/api/trpc/helpers/useCurrentOrganizationId";
import { trpc } from "../../lib/api/trpc/trpc";

import { useNavigate } from "../../router";

import { FullScreenLoader } from "../util/FullScreenLoader";

export function WorkflowCreationWizard({
  onClose,
  open,
}: {
  onClose: VoidFunction;
  open: boolean;
}) {
  const { t, i18n } = useTranslation();
  const currentOrgId = useCurrentOrganizationId();

  const navigate = useNavigate();
  const utils = trpc.useUtils();

  const createWorkflow = trpc.workflows.wizard.generate.useMutation();

  const [input, setInput] = useState("");

  const onCreate = async () => {
    const workflow = await createWorkflow
      .mutateAsync({
        language: i18n.language,
        query: input,
      })
      .catch(() => {
        toast.error(t("errors.workflowCreationInvalidStructure"));
      });

    await utils.organization.department.invalidate();

    if (workflow)
      navigate("/:organizationId/workflows/:workflowId", {
        params: {
          organizationId: currentOrgId,
          workflowId: workflow.id,
        },
      });
    onClose();
  };

  if (createWorkflow.isPending) {
    return <FullScreenLoader />;
  }

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog
        variant="soft"
        sx={{
          width: "min(70vw, 2000px)",
          overflow: "auto",
        }}
      >
        <ModalClose />
        <div className="h-full p-4">
          <div className="flex flex-row items-center gap-2">
            {/* <AutoAwesome /> */}
            <Typography level="h3">{t("workflowWizard.title")}</Typography>
            <AutoAwesome fontSize="small" />
          </div>

          <div>
            <div className="flex flex-col gap-3">
              <div className="flex flex-row items-center gap-2 pb-4">
                <Typography level="body-sm">
                  {t("workflowWizard.description")}
                </Typography>
              </div>
              <FormControl>
                <FormLabel>{t("workflowWizard.promptLabel")}</FormLabel>
                <Textarea
                  value={input}
                  sx={{ background: "white" }}
                  onChange={(e) => setInput(e.target.value)}
                  placeholder={t("workflowWizard.placeholder")}
                  minRows={3}
                  maxRows={8}
                />
              </FormControl>
              <div className="flex">
                <Button
                  onClick={async () => {
                    await onCreate();
                  }}
                >
                  <div className="flex flex-row items-center gap-2">
                    {t("workflowWizard.create")}
                    <AutoAwesome fontSize="small" />
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ModalDialog>
    </Modal>
  );
}
