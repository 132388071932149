import { AudioFile, Close, CloudUpload, Info } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Option,
  Select,
  Switch,
  Textarea,
  Typography,
  Alert,
} from "@mui/joy";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { trpc } from "../../../../../lib/api/trpc/trpc.ts";
import { readableFileSize } from "../../../../../lib/util.ts";

export function Uploader({ onUpload }: { onUpload: () => void }) {
  const { mutateAsync: createAndUpload } =
    trpc.tools.meetingRecorder.createAndUpload.useMutation();

  const { mutateAsync: process } =
    trpc.tools.meetingRecorder.startTranscription.useMutation();

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  // TODO convert to formik
  const [displayName, setDisplayName] = useState<string | null>(null);
  const [cleanUpAudio, setCleanUpAudio] = useState(false);
  const [languageCode, setLanguageCode] = useState("de");
  const [numSpeakers, setNumSpeakers] = useState(2);
  const [advancedDiarization, setAdvancedDiarization] = useState(false);
  const [vocabulary, setVocabulary] = useState(
    localStorage.getItem("transcriptVocabulary") ?? ""
  );

  const [uploading, setUploading] = useState<boolean>(false);

  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (!file) {
      return;
    }
    if (!displayName) {
      setDisplayName(file.name);
    }
    setUploadedFile(file);
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept } =
    useDropzone({
      onDrop,
      disabled: uploading,
      accept: {
        "audio/mp3": [".mp3"],
        "audio/aac": [".aac"],
        "audio/ogg": [".ogg"],
        "audio/wav": [".wav"],
        "audio/m4a": [".m4a"],
        "audio/flac": [".flac"],
      },
      multiple: false,
    });

  const createMeeting = async () => {
    if (!uploadedFile || !displayName) {
      return;
    }
    setUploading(true);
    const { uploadUrl, id: meetingId } = await createAndUpload({
      displayName,
      cleanUpAudio,
      languageCode,
      numSpeakers,
      advancedDiarization,
      vocabulary,
    });
    await fetch(uploadUrl, {
      method: "PUT",
      body: uploadedFile,
    });
    toast.success("Aufnahme hochgeladen");
    void process({ meetingId });
    onUpload();
  };

  let dropzoneText =
    "Ziehe eine MP3 Datei hierhin oder klicke, um eine Datei hochzuladen";
  if (isDragActive) {
    if (!isDragAccept) {
      dropzoneText = "Dateityp wird nicht unterstützt";
    } else {
      dropzoneText = "Datei hier ablegen zum hochladen";
    }
  }

  return (
    <div className="flex flex-col gap-8">
      <Typography level="title-lg">Neues Meeting</Typography>

      <FormControl required disabled={uploading}>
        <FormLabel>Name des Meetings</FormLabel>
        <Input
          type="text"
          required
          value={displayName ?? undefined}
          onChange={(e) => {
            setDisplayName(e.target.value ?? null);
          }}
        />
      </FormControl>
      <FormControl required disabled={uploading}>
        <FormLabel>Aufnahme</FormLabel>
        <Box
          sx={{
            border: "2px dashed",
            borderColor: "neutral.outlinedBorder",
            borderRadius: "sm",
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
            height: 120,
            cursor: "pointer",
            "&:hover": {
              bgcolor: "background.level1",
            },
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />

          {uploadedFile ? (
            <Card
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 2,
                p: 1,
              }}
            >
              <AudioFile color="primary" sx={{ fontSize: 40 }} />
              <Box sx={{ flex: 1 }}>
                <Typography>{uploadedFile.name}</Typography>
                <Typography level="body-sm" color="neutral">
                  {readableFileSize(uploadedFile.size)}
                </Typography>
              </Box>
              <IconButton
                disabled={uploading}
                size="sm"
                variant="plain"
                color="neutral"
                onClick={(e) => {
                  e.stopPropagation();
                  setUploadedFile(null);
                }}
              >
                <Close />
              </IconButton>
            </Card>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                alignItems: "center",
              }}
            >
              <CloudUpload sx={{ fontSize: 40 }} />
              <Typography textAlign="center">{dropzoneText}</Typography>
            </Box>
          )}
        </Box>
        <FormHelperText>
          Unterstützte Formate: mp3, aac, ogg, wav, m4a, flac. Maximale Länge: 2
          h 15 min
        </FormHelperText>
      </FormControl>

      <div className="flex gap-4">
        <FormControl sx={{ flexGrow: 1 }} disabled={uploading}>
          <FormLabel>Sprache der Aufnahme</FormLabel>
          <Select
            value={languageCode}
            onChange={(_e, newValue) => setLanguageCode(newValue ?? "de")}
          >
            <Option value="de">Deutsch</Option>
            <Option value="en">Englisch</Option>
            <Option value="fr">Französisch</Option>
            <Option value="it">Italienisch</Option>
            <Option value="es">Spanisch</Option>
          </Select>
        </FormControl>
        <FormControl sx={{ flexGrow: 1 }} disabled={uploading}>
          <FormLabel>Anzahl der Sprecher</FormLabel>
          <Select
            value={numSpeakers}
            onChange={(_e, newValue) => setNumSpeakers(Number(newValue))}
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((value) => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </Select>
        </FormControl>
      </div>

      <FormControl
        orientation="horizontal"
        sx={{ gap: 2 }}
        disabled={uploading}
      >
        <Switch
          checked={cleanUpAudio}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setCleanUpAudio(event.target.checked)
          }
        />
        <div>
          <FormLabel sx={{ m: 0 }}>Qualität verbessern</FormLabel>
          <FormHelperText sx={{ mt: 0 }}>
            Empfohlen für Aufnahmen, die mit einem Handy- oder Laptopmikrofon
            gemacht wurden oder viele Hintergrundgeräusche enthalten
          </FormHelperText>
        </div>
      </FormControl>
      <FormControl
        orientation="horizontal"
        sx={{ gap: 2 }}
        disabled={uploading}
      >
        <Switch
          checked={advancedDiarization}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setAdvancedDiarization(event.target.checked)
          }
        />
        <div>
          <FormLabel sx={{ m: 0 }}>Verbesserte Stimmerkennung</FormLabel>
          <FormHelperText sx={{ mt: 0 }}>
            Experimentelle Stimmerkennung, die bei schwierigen Aufnahmen helfen
            kann
          </FormHelperText>
        </div>
      </FormControl>

      <FormControl disabled={uploading}>
        <FormLabel>Vokabular</FormLabel>
        <Textarea
          minRows={3}
          maxRows={10}
          value={vocabulary}
          placeholder="meinGPT, Jour Fixe, ..."
          onChange={(e) => {
            setVocabulary(e.target.value);
            localStorage.setItem("transcriptVocabulary", e.target.value);
          }}
        />
        <FormHelperText>
          Verbessert die erkennung von Begriffen, die für die KI schwer zu
          verstehen sind, z.B. Firmennamen, Fachbegriffe, Akronyme usw.
        </FormHelperText>
      </FormControl>

      <Alert startDecorator={<Info />}>
        <Typography level="body-sm">
          Alle aufnahmen werden in der EU verarbeitet und gespeichert. Pro
          Stunde Aufnahme, die verarbeitet wird, werden <b>100 Credits</b>{" "}
          verbraucht.
        </Typography>
      </Alert>

      <Button
        onClick={createMeeting}
        disabled={!uploadedFile || !displayName || uploading}
        loading={uploading}
      >
        Hochladen und Transkribieren
      </Button>
    </div>
  );
}
