/**
 * Input for mutating contact info. All fields are optional.
 */

import z from 'zod';

export const ContactInfo = z
  .object({
    name: z.string().nullable(),
    email: z
      .string()
      .refine(
        (value) => value === '' || z.string().email().safeParse(value).success,
      )
      .nullable(),
    phone: z
      .string()
      .refine((val) => /^(\+?\d+)?$/.test(val))
      .nullable(),
    additionalInfo: z.string().nullable(),
    departmentName: z.string().nullable(),
  })
  .partial();

export type ContactInfo = z.infer<typeof ContactInfo>;
