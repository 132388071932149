import { Autocomplete, Typography } from "@mui/joy";
import { useOrganizationUsers } from "../../../lib/api/user";
import { UserAvatar } from "../../auth/UserMenu";
import { useTranslation } from "../../../lib/i18n";
export function UserSearch({
  selectedUserId,
  setSelectedUserId,
  blacklist,
}: {
  selectedUserId: string | null;
  setSelectedUserId: (id: string | null) => void;
  blacklist?: string[];
}) {
  const { t } = useTranslation();

  const allUsers = useOrganizationUsers()?.filter(
    (u) => !blacklist?.includes(u.id)
  );

  return (
    <Autocomplete
      style={{ width: "100%" }}
      placeholder={t("userSearch.searchUsers")}
      noOptionsText={
        (allUsers?.length ?? 0) > 1
          ? t("userSearch.userNotFound")
          : t("userSearch.noUsersFound")
      }
      loading={!allUsers}
      options={allUsers ?? []}
      value={allUsers?.find((u) => u.id === selectedUserId) ?? null}
      onChange={(_, value) => {
        setSelectedUserId(value?.id ?? null);
      }}
      getOptionLabel={(option) =>
        `${option.firstName} ${option.lastName} (${option.primaryEmail})`
      }
      renderOption={(props, option) => (
        <div
          className="flex flex-row gap-5 p-3 items-center hover:bg-gray-200 cursor-pointer transition-colors"
          key={option.id}
          onClick={() => setSelectedUserId(option.id)}
        >
          <UserAvatar userId={option.id} />
          <div className="flex flex-col">
            <Typography>
              {option.firstName} {option.lastName}
            </Typography>
            <Typography level="body-sm" color="neutral">
              {option.primaryEmail}
            </Typography>
          </div>
        </div>
      )}
    />
  );
}

// Version with dynamic debounce loading. For now we just load all users
// export function UserSearch({
//   selectedUserId,
//   setSelectedUserId,
//   blacklist,
// }: {
//   selectedUserId: string | null;
//   setSelectedUserId: (id: string | null) => void;
//   blacklist?: string[];
// }) {
//   const [search, setSearch] = useState("");
//   const [rawSearchResults, setSearchResults] = useState<ApiUser[]>([]);
//   const debouncedSearch = useDebounce(search, 500);
//   const organizationApi = useOrganizationApi();
//   const [loading, setLoading] = useState(false);

//   const aboutToOrLoading =
//     search.length > 0 && (loading || debouncedSearch !== search);

//   const searchResults = rawSearchResults.filter(
//     (u) => !blacklist?.includes(u.id)
//   );

//   useEffect(() => {
//     if (debouncedSearch.length < 1) {
//       setSearchResults([]);
//       return;
//     }
//     setLoading(true);
//     organizationApi
//       .get(`/users/search?q=${encodeURIComponent(debouncedSearch)}`)
//       .then((res) => {
//         setSearchResults(ApiUser.array().parse(res.data));
//       })
//       .catch((err) => {
//         console.error(err);
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [debouncedSearch]);

//   return (
//     <Autocomplete
//       loading={aboutToOrLoading}
//       placeholder="Mitarbeiter Suchen"
//       noOptionsText={
//         search ? "Keine Ergebnisse" : "Tippen um mit der Suche zu beginnen"
//       }
//       inputValue={search}
//       onInputChange={(_, newValue) => setSearch(newValue)}
//       options={searchResults}
//       value={rawSearchResults.find((u) => u.id === selectedUserId) ?? null}
//       onChange={(_, value) => {
//         setSelectedUserId(value?.id ?? null);
//       }}
//       getOptionLabel={(option) => option.firstName + " " + option.lastName}
//     />
//   );
// }
