import { Google, Microsoft, Send } from "@mui/icons-material";
import { Alert, Button, Divider, Input, Stack, Typography } from "@mui/joy";
import { useAuthMethods } from "../../lib/api/auth";
import { trpc } from "../../lib/api/trpc/trpc";
import { useTranslation } from "../../lib/i18n";
import { DelayedLoader } from "../util/DelayadLoader";
import { browserSessionKey } from "../../lib/api/trpc/TrpcProvider";

function redirectToAzure() {
  // preserve query params
  const url = new URL(window.location.href);
  const search = url.search;
  window.location.href = `/api/auth/azure/login${search}?sessionId=${browserSessionKey}`;
}

function redirectToGoogle() {
  // preserve query params
  const url = new URL(window.location.href);
  const search = url.search;
  window.location.href = `/api/auth/google/login${search}?sessionId=${browserSessionKey}`;
}

export function AuthDialog() {
  const authMethods = useAuthMethods();
  const { t } = useTranslation();

  if (!authMethods) return <DelayedLoader />;

  // if only one auth method is available, redirect directly
  if (!authMethods.email && authMethods.azure && !authMethods.google) {
    redirectToAzure();
    return null;
  } else if (!authMethods.email && !authMethods.azure && authMethods.google) {
    redirectToGoogle();
    return null;
  }

  return (
    <>
      <Stack gap={1}>
        <Typography level="h3">{t("login")}</Typography>
      </Stack>
      {authMethods.email && (
        <Stack gap={4} sx={{ mt: 2 }}>
          <MagicLinkForm />
        </Stack>
      )}
      {authMethods.email && (authMethods.azure || authMethods.google) && (
        <Divider>{t("or")}</Divider>
      )}
      <Stack gap={4} sx={{ mb: 2 }}>
        <Stack gap={2}>
          {authMethods.google && (
            <Button
              variant="soft"
              color="neutral"
              fullWidth
              startDecorator={<Google />}
              onClick={redirectToGoogle}
            >
              {t("continueWithGoogle")}
            </Button>
          )}
          {authMethods.azure && (
            <Button
              variant="soft"
              color="neutral"
              fullWidth
              startDecorator={<Microsoft />}
              onClick={redirectToAzure}
            >
              {t("continueWithMicrosoft")}
            </Button>
          )}
        </Stack>
      </Stack>
    </>
  );
}

function MagicLinkForm() {
  const { t } = useTranslation();

  const createMagicLink = trpc.auth.magicLinks.requestMagicLink.useMutation();

  return (
    <form
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        createMagicLink.mutate({
          email: (e.target as HTMLFormElement).email.value,
        });
      }}
    >
      <div className="flex flex-row gap-2">
        <Input
          type="email"
          name="email"
          required
          fullWidth
          placeholder={t("email")}
          autoFocus
        />
        <Button type="submit" loading={createMagicLink.isPending}>
          <Send />
        </Button>
      </div>
      {createMagicLink.isSuccess && (
        <Alert color="success">{t("magicLinkSent")}</Alert>
      )}
      {createMagicLink.error && (
        <Alert color="danger">{t(createMagicLink.error.message)}</Alert>
      )}
    </form>
  );
}
