import type { SwitchProps } from "@mui/joy";
import { FormControl, FormHelperText, FormLabel, Switch } from "@mui/joy";
import type { FieldProps } from "formik";
import { Field, getIn } from "formik";

interface FormikSwitchProps extends SwitchProps {
  label?: string;
  helperText?: string;
  containerClass?: string;
}

function FormikSwitchAdapter({
  field,
  form: { touched, errors, setFieldValue },
  label,
  helperText,
  containerClass,
  ...props
}: FormikSwitchProps & FieldProps) {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return (
    <FormControl
      error={showError}
      className={containerClass ?? ""}
      orientation="horizontal"
      sx={{ justifyContent: "space-between", alignSelf: "start", gap: 2 }}
    >
      <Switch
        {...field}
        {...props}
        checked={field.value}
        onChange={(e) => {
          void setFieldValue(field.name, e.target.checked);
        }}
      />
      <div>
        <FormLabel>{label}</FormLabel>
        <FormHelperText>{showError ? fieldError : helperText}</FormHelperText>
      </div>
    </FormControl>
  );
}

export function FormikSwitch(props: FormikSwitchProps & { name: string }) {
  return <Field {...props} component={FormikSwitchAdapter} />;
}
