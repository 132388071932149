import {
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  FormControl,
  FormLabel,
  Card,
  IconButton,
  Option,
  Typography,
  Select,
} from "@mui/joy";
import { useEffect, useState } from "react";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import { ImageStyleSelector } from "../../../components/image/ImageStyleCard";

import { DelayedLoader } from "../../../components/util/DelayadLoader";
import { trpc } from "../../../lib/api/trpc/trpc";
import { useTranslation } from "../../../lib/i18n";

import ToolPage from "../../../components/sidebar/ToolPage.tsx";

import bflIcon from "../../../assets/modelicons/bfl.png";
import { Close, History, Info, UnfoldMore } from "@mui/icons-material";
import { ImagePromptInputEditor } from "../../../components/image/ImagePromptInputEditor.tsx";
import { GeneratedImages } from "../../../components/image/GeneratedImage.tsx";
import {
  type ImageModel,
  type ImageRequest,
  DefaultImageRequest,
  type ImageProvider,
  type ImageAspectRatio,
  IMAGE_PROVIDERS_MODELS,
} from "../../../../../backend/src/api/tools/images/imagesTypes.ts";
import { MarkdownRenderer } from "../../../components/chat/MarkdownRenderer.tsx";
import { ImagesHistory } from "../../../components/image/ImagesHistory.tsx";
import { ImagePreviewModalContent } from "../../../components/image/ImagePreviewModal.tsx";
import { toast } from "react-toastify";

export { ErrorDisplay as Catch } from "../../../components/util/ErrorDisplay";

const modelIcons: Record<ImageProvider, string> = {
  flux: bflIcon,
};

export interface ImageModal {
  content: "styleSelector" | "guide" | "imagePreview" | null;
  urls?: string[];
  index?: number;
  prompt?: string;
  style?: string;
}

export default function ImageFactory() {
  const { t } = useTranslation();

  const [imageInput, setImageInput] = useState<ImageRequest>(
    DefaultImageRequest["flux"]
  );

  const [lastAspectRatio, setLastAspectRatio] =
    useState<ImageAspectRatio | null>(null);

  const [modal, setModal] = useState<ImageModal>({
    content: null,
  });

  const [historyPageOpen, setHistoryPageOpen] = useState(false);

  const { data: availableProviders } =
    trpc.tools.images.listConfigured.useQuery();
  const { data: imagesHistory } =
    trpc.tools.images.getImageGenerationHistory.useQuery();

  const availableModels = availableProviders?.flatMap(
    (provider) => IMAGE_PROVIDERS_MODELS[provider]
  );

  const {
    mutateAsync: generateImage,
    isPending: generationPending,
    data: generatedImages,
    error: generationError,
  } = trpc.tools.images.generateImages.useMutation({
    trpc: {
      context: {
        silentError: true,
      },
    },
  });

  const utils = trpc.useUtils();

  const onGenerate = async () => {
    setLastAspectRatio(imageInput.aspectRatio);
    generateImage(imageInput)
      .then((res) => {
        void utils.tools.images.getImageGenerationHistory.invalidate();
        res.errorMessage && toast.error(t("errors." + res.errorMessage));
      })
      .catch((e) => {
        console.error(e);
        setLastAspectRatio(null);
      });
  };

  const changeModel = (model: ImageModel) => {
    if (imageInput.model === model) return;
    setImageInput((prev) => ({
      ...prev,
      model,
    }));
  };

  useEffect(() => {
    (imagesHistory?.length ?? 0) === 0 && setHistoryPageOpen(false);
  }, [imagesHistory]);

  if (!availableModels) return <DelayedLoader />;

  return (
    <ToolPage
      title={t("generateImage")}
      subtitle={t("tools.imageFactory.welcome")}
      actions={
        (imagesHistory?.length ?? 0) > 0 && (
          <Button
            onClick={() => {
              setHistoryPageOpen((prev) => !prev);
            }}
            color={historyPageOpen ? "neutral" : "primary"}
            startDecorator={!historyPageOpen && <History />}
          >
            {historyPageOpen ? (
              <Close sx={{ stroke: 10 }} />
            ) : (
              t("images.history.title")
            )}
          </Button>
        )
      }
    >
      <Modal open={!!modal.content} onClose={() => setModal({ content: null })}>
        <ModalDialog className="overflow-y-auto">
          <ModalClose sx={{ right: "30px", top: "25px" }} />
          {modal.content === "styleSelector" && (
            <ImageStyleSelector
              selectedStyle={imageInput.style}
              setSelectedStyle={(style) => {
                setImageInput((prev) => ({ ...prev, style }));
              }}
              onClose={() => setModal({ content: null })}
            />
          )}
          {modal.content === "guide" && (
            <div className="p-5">
              <Typography level="h2" pb={2}>
                {t("images.tutorial.title")}
              </Typography>
              <MarkdownRenderer content={t("images.tutorial.body")} />
            </div>
          )}
          {modal.content === "imagePreview" && (
            <ImagePreviewModalContent modal={modal} setModal={setModal} />
          )}
        </ModalDialog>
      </Modal>
      {historyPageOpen ? (
        <ImagesHistory setModal={setModal} imagesHistory={imagesHistory} />
      ) : (
        <div className="flex w-full flex-wrap items-stretch gap-5">
          <Card
            className="flex min-w-[450px] flex-1 flex-col gap-5 "
            sx={{ pb: 4 }}
          >
            <div className="flex items-start justify-between">
              <div className="flex gap-8">
                <FormControl>
                  <FormLabel>{t("model")}</FormLabel>
                  <Select
                    disabled={generationPending}
                    value={imageInput.model}
                    className="w-[190px]"
                    startDecorator={
                      <img
                        src={modelIcons[imageInput.provider]}
                        className="h-6"
                      />
                    }
                    renderValue={(option) => t("images.model." + option?.value)}
                    onChange={(_, newValue) => {
                      changeModel(
                        newValue ??
                          DefaultImageRequest[imageInput.provider].model
                      );
                    }}
                  >
                    {availableModels.map((model) => (
                      <Option key={model} value={model}>
                        {t(`images.model.${model}`)}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>{t("images.genStyle")}</FormLabel>
                  <Button
                    disabled={generationPending}
                    variant="outlined"
                    color="neutral"
                    sx={{
                      backgroundColor: "white",
                      justifyContent: "flex-start",
                      width: "fit-content",
                    }}
                    startDecorator={
                      <ColorLensIcon
                        viewBox="2 2 20 20"
                        sx={{ height: "25px", width: "25px" }}
                      />
                    }
                    endDecorator={<UnfoldMore />}
                    onClick={() => setModal({ content: "styleSelector" })}
                  >
                    {t(`images.styles.${imageInput.style}`)}
                  </Button>
                </FormControl>
              </div>
              <IconButton onClick={() => setModal({ content: "guide" })}>
                <Info />
              </IconButton>
            </div>
            <ImagePromptInputEditor
              input={imageInput}
              setInput={setImageInput}
              onGenerate={onGenerate}
              generating={generationPending}
            />
          </Card>
          <GeneratedImages
            setModal={setModal}
            numberOfImages={
              generatedImages?.urls?.length ?? imageInput.numberOfImages
            }
            aspectRatio={lastAspectRatio ?? imageInput.aspectRatio}
            urls={generatedImages?.urls}
            generating={generationPending}
            error={generationError?.message}
          />
        </div>
      )}
    </ToolPage>
  );
}
