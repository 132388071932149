import ConfirmationDialog from "../settings/ConfirmationDialog";
import { useTranslation } from "react-i18next";

export function UnsavedChangesModal({
  title,
  description,
  open,
  proceed,
  cancel,
}: {
  title?: string;
  description?: string;
  open: boolean;
  proceed: () => void;
  cancel: () => void;
}) {
  const { t } = useTranslation();
  return (
    <ConfirmationDialog
      open={open}
      onClose={cancel}
      title={title ?? t("leavePage?")}
      description={description ?? t("unsavedChangesWarning")}
      onConfirm={proceed}
    />
  );
}
