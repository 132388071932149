import { Button, Checkbox, Stack } from "@mui/joy";
import { t } from "i18next";
import { toast } from "react-toastify";
import { useCourses } from "../../lib/api/learning";
import { useOrganization } from "../../lib/api/organization";
import { trpc } from "../../lib/api/trpc/trpc";
import {
  useRootApi,
  useRootResource,
  useRootSWR,
} from "../../lib/hooks/useApi";
import { SettingsPage } from "./SettingsPage";

export function EnableAcademy() {
  const api = useRootApi();

  const organization = useOrganization();
  const resourcePath = `workshops/admin/${organization?.defaultWorkshopId}`;
  const defaultWorkshop = useRootResource(resourcePath);
  const availableCourses = useCourses()?.sort((c1, c2) => c1.id - c2.id);
  const chosenCourses = defaultWorkshop?.allCoursesAIDs;

  const enableAcademy = trpc.organization.enableAcademy.useMutation();
  const disableAcademy = trpc.organization.disableAcademy.useMutation();
  const utils = trpc.useUtils();

  const mutateWorkshop = useRootSWR(resourcePath).mutate;
  const toggleAcademyAvailability = async (enabled: boolean) => {
    if (!organization) {
      throw new Error("Organization not yet loaded");
    }
    try {
      if (enabled) {
        await enableAcademy.mutateAsync({
          courses: chosenCourses ?? [],
        });
      } else {
        await disableAcademy.mutateAsync();
      }
      void utils.organization.invalidate();
    } catch (e) {
      console.error(e);
    }
  };

  const toggleCourseAvailability = async (course) => {
    try {
      await api.post(
        `workshops/admin/${defaultWorkshop?.id}/courses/${course.id}/toggleAvailability`
      );
      await mutateWorkshop();
    } catch (e) {
      console.error(e);
    }
  };

  const isActive = !!organization?.defaultWorkshopId;

  return (
    <SettingsPage
      title={t("enableAcademy")}
      subtitle={t("availableCourses")}
      actions={
        <Button
          color={isActive ? "danger" : "primary"}
          variant={isActive ? "outlined" : "solid"}
          onClick={async () => {
            await toggleAcademyAvailability(!isActive);
          }}
        >
          {isActive ? t("disable") : t("enable")}
        </Button>
      }
    >
      <Stack gap={1}>
        {availableCourses?.map((course) => (
          <Checkbox
            label={course.title}
            key={course.id}
            disabled={!defaultWorkshop}
            checked={
              defaultWorkshop?.allCoursesAIDs?.includes(course.id + "") ?? false
            }
            onChange={() => {
              toggleCourseAvailability(course).catch(() => {
                toast.error(t("errors.network"));
              });
            }}
          />
        ))}
      </Stack>
    </SettingsPage>
  );
}
