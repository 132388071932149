import { PlayCircleOutline } from "@mui/icons-material";

import ClassIcon from "@mui/icons-material/Class";
import GroupIcon from "@mui/icons-material/Group";
import LinkIcon from "@mui/icons-material/Link";
import PinIcon from "@mui/icons-material/Pin";
import UpdateIcon from "@mui/icons-material/Update";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Link as JoyLink,
  Modal,
  ModalClose,
  ModalDialog,
  Option,
  Select,
  Sheet,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
} from "@mui/joy";
import { tabClasses } from "@mui/joy/Tab";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import type { TeacherWorkshop } from "../../../../backend/src/api/elearning/workshop/workshopTypes.ts";
import Logo from "../../assets/logo.svg";
import { UserMenu } from "../../components/auth/UserMenu.tsx";
import { NewWorkshopButton } from "../../components/elearning/NewWorkshopModal.tsx";
import { useCourses, useUpdateWorkshop } from "../../lib/api/learning.ts";
import { trpc } from "../../lib/api/trpc/trpc.ts";
import { useUpdatePayloadApiKey, useUser } from "../../lib/api/user.ts";
import { handleGenericError } from "../../lib/errorHandling.tsx";
import { useAllowedRolesOnly } from "../../lib/hooks/useAllowedRolesOnly.tsx";
import { useSearchParamsState } from "../../lib/hooks/useSearchParamsState.ts";
import { Link } from "../../router";
import { CustomError } from "../../components/util/CustomError.tsx";

function IconRow({
  children,
  icon,
}: {
  children: React.ReactNode;
  icon: React.ReactNode;
}) {
  return (
    <div className="flex items-center gap-2">
      {icon}
      {children}
    </div>
  );
}

export function CopyWorkshopLinkButton({ loginCode }: { loginCode: string }) {
  const { t } = useTranslation();
  const onButtonClick = useCallback(async () => {
    await navigator.clipboard
      .writeText(`${window.location.origin}/workshops/join?code=${loginCode}`)
      .then(() => {
        toast.success(t("linkCopied"), { autoClose: 2000 });
      })
      .catch((e) => {
        handleGenericError(e, "errors.copyFailed");
      });
  }, [loginCode]);

  return (
    <Button
      startDecorator={<LinkIcon />}
      variant="plain"
      sx={{ flexGrow: "0 !important" }}
      onClick={onButtonClick}
    >
      {t("copyLink")}
    </Button>
  );
}

export function TestRunButton(props: { orgId: string; workshopId: string }) {
  const { t } = useTranslation();
  return (
    <Button
      variant="plain"
      startDecorator={<PlayCircleOutline />}
      component="a"
      href={`/${props.orgId}/learn/${props.workshopId}`}
      target="_blank"
    >
      {t("testRun")}
    </Button>
  );
}

function WorkshopCard({
  workshop: {
    id,
    loginCode,
    name,
    participantCount,
    status,
    courses,
    organization,
  },
}: {
  workshop: TeacherWorkshop;
}) {
  const { t } = useTranslation();

  const updateWorkshop = useUpdateWorkshop();

  async function updateWorkshopState(
    newValue: TeacherWorkshop["status"] | null
  ) {
    if (newValue != null) {
      await updateWorkshop(id, { status: newValue }).catch((e) => {
        console.error(e);
        toast.error(t("errors.workshopUpdateFailed"));
      });
    }
  }

  return (
    <Card sx={{ minWidth: "400px" }}>
      <div>
        <Typography
          sx={{ "&:hover": { textDecoration: "underline" } }}
          level="title-lg"
          component={Link}
          to="/teach/:workshopId"
          params={{
            workshopId: id,
          }}
        >
          {name}
        </Typography>
        <Typography level="body-md" color="neutral">
          {organization.name}
        </Typography>
      </div>
      <IconRow icon={<PinIcon color="disabled" />}>{loginCode}</IconRow>
      {status !== "PENDING" && (
        <IconRow icon={<GroupIcon color="disabled" />}>
          {participantCount} {t("participants")}
        </IconRow>
      )}
      <IconRow icon={<ClassIcon color="disabled" />}>
        {courses?.length} {t("courses")}
      </IconRow>
      <IconRow icon={<UpdateIcon color="disabled" />}>
        <Select
          onChange={(_e, newValue) => updateWorkshopState(newValue)}
          value={status}
        >
          <Option value="PENDING">{t("upcoming")}</Option>
          <Option value="ACTIVE">{t("active")}</Option>
          <Option value="COMPLETED">{t("completed")}</Option>
        </Select>
      </IconRow>
      <CardActions>
        <CopyWorkshopLinkButton loginCode={loginCode} />
        <TestRunButton orgId={organization.id} workshopId={id} />
      </CardActions>
    </Card>
  );
}

export default function WorkshopsOverviewPage() {
  const { isLoading, isAllowed } = useAllowedRolesOnly("TEACHER");
  const { t } = useTranslation();
  if (isLoading) {
    return null;
  }
  if (!isAllowed) {
    return <CustomError error={t("errors.notAllowed")} />;
  }
  return <WorkshopsOverviewPageContent />;
}

function WorkshopsOverviewPageContent() {
  const { t } = useTranslation();
  const { data: allWorkshops } = trpc.elearning.workshop.getAll.useQuery();

  const workshopGroups = [
    {
      title: t("upcoming"),
      workshops: allWorkshops?.filter((w) => "PENDING" === w.status) ?? [],
    },
    {
      title: t("active"),
      workshops: allWorkshops?.filter((w) => "ACTIVE" === w.status) ?? [],
    },
    {
      title: t("completed"),
      workshops: allWorkshops?.filter((w) => "COMPLETED" === w.status) ?? [],
    },
  ];

  const [activeTab, setActiveTab] = useSearchParamsState("tab", "0");

  const courses = useCourses();
  const sortedCourses =
    courses?.sort((a, b) => a.title.localeCompare(b.title)) ?? [];

  const currentUser = useUser("me");
  const updatePayloadApiKey = useUpdatePayloadApiKey();

  const [apiKey, setApiKey] = useState("");
  const isApiKeyValid =
    /^[\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12}$/.test(apiKey);

  function updateApiKey() {
    if (!isApiKeyValid) return;

    updatePayloadApiKey(apiKey).catch((e) => {
      console.error(e);
      toast.error(t("errors.apiUpdateFailed"));
    });
  }

  return (
    <Sheet
      variant="soft"
      className="flex min-h-screen flex-col overflow-x-hidden"
    >
      <div className="border-b-2 p-4">
        <div className="left-right-center">
          <div></div>
          <div className="flex flex-nowrap items-center gap-4">
            <img src={Logo} alt="Logo" className="h-10 w-10" />
            <Typography level="h2">Teacher Cockpit</Typography>
          </div>
          <div className="self-center justify-self-end">
            <UserMenu languageSelector />
          </div>
        </div>
      </div>
      <Tabs
        className="p-8"
        sx={{ flexGrow: 1 }}
        value={Number(activeTab)}
        onChange={(e, newValue) => {
          setActiveTab(String(newValue));
        }}
      >
        <TabList
          sx={{
            alignSelf: "flex-start",
            p: 0.5,
            gap: 0.5,
            borderRadius: "xl",
            bgcolor: "background.level1",
            [`& .${tabClasses.root}[aria-selected="true"]`]: {
              boxShadow: "sm",
              bgcolor: "background.surface",
            },
          }}
          disableUnderline
        >
          <Tab disableIndicator>Workshops</Tab>
          <Tab disableIndicator>{t("courses")}</Tab>
        </TabList>
        <TabPanel value={0} sx={{ px: 0 }}>
          <div className="flex h-full flex-col gap-4">
            <div className="flex justify-between">
              <Typography level="h3">Workshops</Typography>
              <NewWorkshopButton />
            </div>
            {workshopGroups.map(({ title, workshops }) => {
              if (workshops.length === 0) return null;

              return (
                <div key={title} className="overflow-hidden">
                  <Typography level="body-md" color="neutral" sx={{ mb: 1.5 }}>
                    {title}
                  </Typography>
                  {workshops.length === 0 ? (
                    <Typography
                      level="body-sm"
                      color="neutral"
                      className="py-10"
                    >
                      {t("noWorkshops")}
                    </Typography>
                  ) : (
                    <div className="flex w-full flex-wrap gap-6">
                      {workshops.map((workshop) => (
                        <WorkshopCard key={workshop.id} workshop={workshop} />
                      ))}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </TabPanel>
        <TabPanel value={1}>
          <div className="flex flex-col gap-4">
            {sortedCourses.map((c) => {
              return (
                <Card key={c.id}>
                  <CardContent>
                    <Typography
                      sx={{ "&:hover": { textDecoration: "underline" } }}
                      level="title-lg"
                      component={Link}
                      to="/teach/course/:courseId"
                      params={{
                        courseId: String(c.id),
                      }}
                    >
                      {c.title}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <div className="flex gap-2">
                      {c.Tags?.map((tag) => {
                        if (typeof tag == "number") return null;
                        return (
                          <Chip
                            key={tag.id}
                            title={tag.name}
                            color="primary"
                            size="md"
                            variant="soft"
                          >
                            {tag.name}
                          </Chip>
                        );
                      })}
                    </div>
                  </CardActions>
                </Card>
              );
            })}
          </div>
        </TabPanel>
      </Tabs>
      <Modal open={currentUser != null && !currentUser.hasPayloadKeySet}>
        <ModalDialog>
          <ModalClose />
          <Typography level="h2">{t("storeApiKey")}</Typography>
          <Typography>
            {t("storeApiKeyDescription")}{" "}
            <JoyLink
              href="https://cms.meingpt.com/admin/account"
              target="_blank"
            >
              {t("userSettings")}
            </JoyLink>{" "}
            in meinGPT Academy CMS.
          </Typography>
          <FormControl error={!isApiKeyValid}>
            <FormLabel>API Key</FormLabel>
            <Input
              name="apiKey"
              placeholder="XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX"
              error={!isApiKeyValid}
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
            />
            {!isApiKeyValid && (
              <FormHelperText>{t("invalidKeyFormat")}</FormHelperText>
            )}
          </FormControl>
          <div className="flex justify-end">
            <Button onClick={updateApiKey} disabled={!isApiKeyValid}>
              {t("save")}
            </Button>
          </div>
        </ModalDialog>
      </Modal>
    </Sheet>
  );
}
