import { Close, Search } from "@mui/icons-material";
import { Button, IconButton, Textarea } from "@mui/joy";
import React from "react";
import { useTranslation } from "../../lib/i18n";

interface SearchBarProps {
  query: string;
  setQuery: (query: string) => void;
  onCreate: (searchQuery: string) => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  query,
  setQuery,
  onCreate,
}) => {
  const { t } = useTranslation();

  const handleSubmit = async (
    e:
      | React.FormEvent<HTMLFormElement>
      | React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    onCreate(query);
  };

  return (
    <form className="grow" onSubmit={handleSubmit}>
      <div className="flex grow flex-row items-center justify-stretch gap-2">
        <Textarea
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
          }}
          slotProps={{
            endDecorator: {
              sx: {
                marginTop: 0,
              },
            },
          }}
          onKeyDown={(e) => {
            // submit the form on control+enter
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              void handleSubmit(e);
            }
          }}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder={t("search")}
          endDecorator={
            query ? (
              <IconButton onClick={() => setQuery("")} aria-label={t("remove")}>
                <Close />
              </IconButton>
            ) : undefined
          }
          autoFocus
          maxRows={10}
          minRows={1}
        />
        <Button startDecorator={<Search />} type="submit">
          {t("search")}
        </Button>
      </div>
    </form>
  );
};
