import type {
  CreateSupportBotEvent,
  SupportBotEvent,
  UpdateSupportBotEvent,
} from "../../../../backend/src/api/tools/supportAssistant/supportAssistantTypes.ts";
import { useOrganizationApi } from "../hooks/useApi.tsx";

export function useCreateBotEvent(): (
  eventData: CreateSupportBotEvent
) => Promise<SupportBotEvent> {
  const api = useOrganizationApi();
  return async (eventData: CreateSupportBotEvent) => {
    const response = await api.post(
      "ai/tools/support/metrics/event",
      eventData
    );
    return response.data;
  };
}

export function useUpdateBotEvent(): (
  eventId: string,
  event: UpdateSupportBotEvent
) => Promise<SupportBotEvent> {
  const api = useOrganizationApi();
  return async (eventId: string, event: UpdateSupportBotEvent) => {
    const response = await api.patch(
      `ai/tools/support/metrics/event/${eventId}`,
      event
    );
    return response.data;
  };
}
