import {
  CheckBox,
  CheckBoxOutlineBlank,
  ChevronRight,
  ContentCopy,
  Info,
  RestartAlt,
  TipsAndUpdates,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Modal,
  ModalClose,
  ModalDialog,
  Tooltip,
  Typography,
} from "@mui/joy";
import type { Payload } from "apiTypes";
import type { ReactNode } from "react";
import { useCallback, useEffect, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { twMerge } from "tailwind-merge";
import type { ExerciseWithCompletion } from "../../lib/api/learning.ts";
import {
  unwrapPayloadArray,
  useCaptureAnalyticsEvent,
  useCreateSolutionAttempt,
  useMutateExercise,
  useNavigateToNextExercise,
  usePreloadNextExercise,
  useSolutionAttemptPoster,
} from "../../lib/api/learning.ts";
import type { ChallengeEvaluationsScore } from "../../lib/prompts/specific/challenge.ts";
import { useChallengeEvaluationsScore } from "../../lib/prompts/specific/challenge.ts";
import { ChatInterface } from "../chat/ChatInterface.tsx";
import { ErrorBoundary } from "react-error-boundary";
import * as Sentry from "@sentry/react";
import { PromptingFeedbackAcademy } from "../chat/PromptingFeedbackAcademy.tsx";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import type { Message } from "../../../../backend/src/api/chat/message/messageTypes.ts";
import { trpc } from "../../lib/api/trpc/trpc.ts";

function concatenateAllPrompts(messages: Message[]) {
  return messages
    .filter((m) => !m.fromAi)
    .map((m) => m.content)
    .join("\n");
}

export function ExerciseFeedbackCard({
  challenges: {
    evaluations,
    numSolved,
    loading: challengesLoading,
    hasResults,
    solved,
    reEvaluate,
  },
  didJustSolve,
  exerciseActions,
  hint,
  sampleSolution,
  promptAssistant: { promptEvaluations, loading: promptAssistantLoading },
  promptingAssistantEnabled,
}: {
  didJustSolve: boolean;
  challenges: ChallengeEvaluationsScore;
  promptingAssistantEnabled: boolean;
  hint?: string;
  sampleSolution?: string;
  promptAssistant: {
    promptEvaluations: { rating: string; comment: string }[];
    loading: boolean;
  };
  exerciseActions: ReactNode;
}) {
  const { t } = useTranslation();
  return (
    <Card
      sx={{
        flexGrow: 1,
        overflow: "auto",
        gridArea: "feedback",
      }}
    >
      <div className="flex h-full flex-col justify-between">
        <div className="flex flex-col gap-6">
          <Typography level="h4" mb={2}>
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row items-center gap-2">
                <span>{t("tasks")}</span>
                <ErrorBoundary
                  fallbackRender={() => <div />} // Do not render anything if an error occurs, confetti is not critical
                  onError={(e) => {
                    console.error("Error while rendering Confetti:");
                    console.error(e);

                    // Log error to Sentry
                    Sentry.captureException(e);
                  }}
                >
                  {didJustSolve && <ConfettiExplosion />}
                </ErrorBoundary>
                {numSolved} / {evaluations.length}
                <CircularProgress
                  determinate
                  value={Math.round((numSolved / evaluations.length) * 100)}
                  size="sm"
                  color={
                    numSolved == evaluations.length ? "success" : "neutral"
                  }
                >
                  {numSolved == evaluations.length && <CheckBox />}
                </CircularProgress>
                {!solved && hasResults && (
                  <Tooltip title={t("checkSolved")}>
                    <IconButton disabled={challengesLoading}>
                      <RestartAlt onClick={reEvaluate} />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
              {(hint || sampleSolution) && (
                <Hint hint={hint} sampleSolution={sampleSolution} />
              )}
            </div>
          </Typography>
          <div className="flex flex-col gap-2">
            {evaluations.map((e) => {
              // eslint-disable-next-line no-nested-ternary
              const color = hasResults
                ? e.completed
                  ? "success"
                  : "danger"
                : "neutral";

              const loading = challengesLoading && !e.completed;

              const tooltip = e.prompt;

              return (
                <Tooltip key={e.id} title={tooltip}>
                  <Alert key={e.id} color={color}>
                    <div className="flex flex-row items-center gap-2">
                      {loading && <CircularProgress size="sm" />}
                      {!loading &&
                        (e.completed ? <CheckBox /> : <CheckBoxOutlineBlank />)}
                      <span>{e.title}</span>
                    </div>
                  </Alert>
                </Tooltip>
              );
            })}
          </div>
          {promptingAssistantEnabled && (
            <div>
              <Typography level="h4">
                <div className="flex flex-row items-center gap-2">
                  <span>Prompting Assistant</span>
                  <Tooltip title={t("promptingAssistant.tooltip")}>
                    <Typography level="body-sm">
                      <Info fontSize="small" />
                    </Typography>
                  </Tooltip>
                </div>
                <Box display="flex" alignItems="center">
                  <Typography level="body-xs">
                    {t("basedOnTheLastPrompt")}
                  </Typography>
                </Box>
              </Typography>
              {!promptAssistantLoading && !promptEvaluations && (
                <Typography fontStyle="italic">
                  {t("waitingForSolution")}
                </Typography>
              )}
              {promptAssistantLoading ? (
                <Alert>
                  <div className="flex flex-row items-center gap-2">
                    <CircularProgress size="sm" color="neutral" />
                    <span>{t("promptingAssistantEvaluates")}</span>
                  </div>
                </Alert>
              ) : (
                promptEvaluations && (
                  <>
                    <div className="flex flex-col items-center gap-2">
                      <PromptingFeedbackAcademy
                        promptEvaluations={promptEvaluations}
                      />
                    </div>
                  </>
                )
              )}
            </div>
          )}
        </div>
        <div className="mt-2 flex flex-col">{exerciseActions}</div>
      </div>
    </Card>
  );
}

function Hint({
  hint,
  sampleSolution,
}: {
  hint?: string;
  sampleSolution?: string;
}) {
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation();
  const copySampleSolution = () => {
    navigator.clipboard
      .writeText(sampleSolution ?? "")
      .then(() => {
        setCopied(true);

        setTimeout(() => {
          setCopied(false);
          setOpen(false);
        }, 1000);
      })
      .catch(() => {
        toast(t("errors.unknown"));
      });
  };
  return (
    <>
      <Tooltip title={t("getTip")}>
        <IconButton
          onClick={() => {
            setOpen(true);
          }}
        >
          <TipsAndUpdates fontSize="small" />
        </IconButton>
      </Tooltip>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <ModalDialog>
          <ModalClose />
          <Typography level="h3">Tipp</Typography>
          <Typography>{hint}</Typography>
          {sampleSolution && (
            <Button
              onClick={copySampleSolution}
              endDecorator={
                copied ? (
                  <CheckBox sx={{ fontSize: 20 }} />
                ) : (
                  <ContentCopy sx={{ fontSize: 20 }} />
                )
              }
            >
              {t("copySampleSolution")}
            </Button>
          )}
        </ModalDialog>
      </Modal>
    </>
  );
}

export function MessagesBlock({
  exercise,
  courseId,
}: {
  exercise: ExerciseWithCompletion;
  courseId: string;
}) {
  const [chatId, setChatId] = useState<string | null>(null);
  const [promptEvaluations, setPromptEvaluations] = useState<
    { rating: string; comment: string }[]
  >([]);

  const { t, i18n } = useTranslation();

  const promptAssistant =
    trpc.elearning.promptingAssistant.evaluatePrompt.useMutation();

  usePreloadNextExercise();

  const promptingAssistantEnabled = exercise.enablePromptingAssistant == "yes";

  const navigateToNextExercise = useNavigateToNextExercise();

  const captureAnalyticsEvent = useCaptureAnalyticsEvent();

  const onPrompt = async ({
    messageHistory,
  }: {
    messageHistory: Message[];
  }) => {
    void captureAnalyticsEvent("SOLUTION_ATTEMPT_SUBMITTED");
    if (promptingAssistantEnabled) {
      try {
        setPromptEvaluations(
          await promptAssistant.mutateAsync({
            prompt: concatenateAllPrompts(messageHistory),
            language: i18n.language.split("-")[0] ?? "de",
          })
        );
      } catch (e) {
        console.error(e);
        toast.error(t("errors.couldNotEvalutatePrompt"));
      }
    }
  };

  const c = unwrapPayloadArray<Payload.ChallengeEvaluation>(
    exercise.challengeEvaluations ?? []
  );
  const challenges = useChallengeEvaluationsScore(c ?? []);
  const [didJustSolve, setDidJustSolve] = useState(false);
  if (challenges.solved && !didJustSolve) {
    void captureAnalyticsEvent("EXERCISE_SOLVED");
    setDidJustSolve(true);
  }

  useSolutionAttemptPoster(
    promptEvaluations,
    promptingAssistantEnabled,
    challenges.evaluations,
    challenges.solutionAttempt,
    courseId,
    exercise.id,
    chatId ?? ""
  );

  const { mutateAsync: createChat } = trpc.chat.create.useMutation();
  const refreshChat = useCallback(async () => {
    await createChat({
      modelOverride: exercise.modelOverride ?? undefined,
      hidden: true,
      messages:
        exercise.chatHistory?.map((m) => ({
          content: m.content,
          fromAi: m.fromAi === "yes",
        })) ?? undefined,
    }).then((chat) => {
      setChatId(chat.id);
    });
  }, [createChat, exercise.modelOverride, exercise.chatHistory]);

  function onRetry() {
    challenges.resetResults();
    refreshChat().catch(console.error);
    setPromptEvaluations([]);
  }

  const createAttempt = useCreateSolutionAttempt();

  const mutateExercise = useMutateExercise(courseId, exercise.id + "");

  function onNextExerciseClick() {
    navigateToNextExercise();
  }

  const onSkip = async () => {
    void captureAnalyticsEvent("EXERCISE_SKIPPED");
    await createAttempt({
      courseId: parseInt(courseId),
      exerciseId: exercise.id,
      solved: true,
      skipped: true,
      chatId: chatId ?? "",
      SolutionAttemptEvaluation: [],
    });
    await mutateExercise();
    onNextExerciseClick();
  };

  useEffect(() => {
    if (!chatId) {
      refreshChat().catch(console.error);
    }
  }, [chatId, refreshChat]);

  return (
    <>
      <Typography
        level="body-lg"
        color="neutral"
        mb={0.5}
        ml={0.5}
        mt={2}
        sx={{ gridArea: "chat-title" }}
      >
        Chat
      </Typography>
      <Card sx={{ gridArea: "chat" }}>
        {chatId && (
          <ChatInterface
            autoFocus={false}
            customTemperature={exercise.temperature ?? undefined}
            customSystemPromptSuffix={exercise.appendSystemPrompt ?? undefined}
            key={chatId}
            onPrompt={onPrompt}
            sheetProps={{
              sx: {
                backgroundColor: "transparent",
              },
            }}
            onMessageHistoryChange={(messages, lastType) => {
              if (lastType == "response") {
                challenges.onMessageChange(messages).catch(console.error);
              }
            }}
            chatId={chatId}
            showSettings={false}
            showSmartIterations={false}
            showAttachmentButton={exercise.enableFileUpload == "yes"}
            embedded
          />
        )}
      </Card>
      <Typography
        level="body-lg"
        color="neutral"
        mb={0.5}
        ml={0.5}
        mt={2}
        sx={{ gridArea: "feedback-title" }}
      >
        Feedback
      </Typography>
      <ExerciseFeedbackCard
        hint={exercise.hint ?? undefined}
        sampleSolution={exercise.sampleSolution ?? undefined}
        didJustSolve={didJustSolve}
        challenges={challenges}
        promptingAssistantEnabled={promptingAssistantEnabled}
        promptAssistant={{
          promptEvaluations,
          loading: promptAssistant.isPending,
        }}
        exerciseActions={
          <>
            <div className="flex flex-row items-stretch gap-2">
              <Button
                onClick={onRetry}
                variant="outlined"
                color="neutral"
                fullWidth
              >
                <div className="flex flex-row items-center gap-2">
                  <RestartAlt />
                  <span>{t("restart")}</span>
                </div>
              </Button>
              {exercise.completed && (
                <Button
                  variant="solid"
                  color="success"
                  fullWidth
                  onClick={onNextExerciseClick}
                >
                  <div className="flex flex-row items-center gap-2">
                    <span>{t("next")}</span>
                    <ChevronRight />
                  </div>
                </Button>
              )}
            </div>
            <div
              className={twMerge(
                "-mb-2 mt-1 cursor-pointer text-center text-xs text-gray-500 underline transition-all hover:text-blue-500",
                exercise.completed && "cursor-default opacity-0"
              )}
              onClick={() => {
                !exercise.completed && onSkip().catch(console.error);
              }}
            >
              <span>{t("skipExercise")}</span>
            </div>
          </>
        }
      />
    </>
  );
}
