import { Button, Input, List, ListItem, Sheet, Typography } from "@mui/joy";
import { useState } from "react";
import { trpc } from "../../lib/api/trpc/trpc.ts";

export default function TestPage() {
  const { mutateAsync } = trpc.test.useMutation();
  const [results, setResults] = useState<
    { success: boolean; duration: number }[]
  >([]);
  const [isRunning, setIsRunning] = useState(false);
  const [delay, setDelay] = useState(60);

  const startTest = async () => {
    setIsRunning(true);

    for (let i = 0; i < 100; i++) {
      const start = Date.now();
      try {
        await mutateAsync(delay);
        setResults((prevResults) => [
          ...prevResults,
          { success: true, duration: Date.now() - start },
        ]);
      } catch (e) {
        setResults((prevResults) => [
          ...prevResults,
          { success: true, duration: Date.now() - start },
        ]);
      }
    }
    setIsRunning(false);
  };

  return (
    <Sheet sx={{ p: 8 }}>
      <Typography level="h1" mb={4}>
        Test Page
      </Typography>
      <Input
        type="number"
        value={delay}
        disabled={isRunning}
        sx={{ mb: 2 }}
        onChange={(e) => setDelay(Number(e.target.value))}
      />

      <Button onClick={startTest} loading={isRunning} sx={{ mr: 2 }}>
        Start test
      </Button>
      <Button
        onClick={() => navigator.clipboard.writeText(JSON.stringify(results))}
        disabled={results.length === 0}
      >
        Copy Results as JSON
      </Button>
      <List>
        {results.map((result, i) => (
          <ListItem key={i}>
            {result.success ? "✅" : "❌"} {result.duration}ms
          </ListItem>
        ))}
      </List>
    </Sheet>
  );
}
