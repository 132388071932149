import z from 'zod';

/**
 * A shallow object with an id field. Can represent a database entity.
 */
export const ShallowObject = z.object({
  id: z.string(),
});
export type ShallowObject = z.infer<typeof ShallowObject>;

/**
 * An array of shallow objects with an id field. Can represent a list of database entities.
 */
export const ShallowObjectArray = z.array(ShallowObject);
export type ShallowObjectArray = z.infer<typeof ShallowObjectArray>;
