import { Typography } from "@mui/joy";
import { Dialog, DialogPanel } from "@tremor/react";
import type { DateRangePickerValue } from "@tremor/react";
import {
  BarChart,
  Card,
  DateRangePicker,
  DateRangePickerItem,
  Metric,
  Text,
  Title,
} from "@tremor/react";
import {
  endOfDay,
  format,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfYear,
  subDays,
} from "date-fns";
import { de, enUS, es, fr, it } from "date-fns/locale";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useOrganizationResource } from "../../../lib/hooks/useApi.tsx";
import { useModals } from "../../../router.ts";

function getLocale(key: string) {
  const locale = {
    de: de,
    fr: fr,
    it: it,
    es: es,
  }[key];
  return locale ?? enUS;
}

export default function SupportAssistantAnalyticsModal() {
  const { t, i18n } = useTranslation();

  const modals = useModals();

  const [dateRange, setDateRange] = useState<DateRangePickerValue>({
    from: startOfDay(subDays(new Date(), 30)),
    to: endOfDay(new Date()),
    selectValue: "last_30_days",
  });

  const analytics = useOrganizationResource(
    `ai/tools/support/metrics?from=${dateRange.from?.toISOString()}&to=${dateRange.to?.toISOString()}`
  );

  const locale = getLocale(i18n.language);

  const chartData =
    analytics?.solvedRequestsByDay.map((item) => ({
      day: format(parseISO(item.day), "P", { locale }),
      [t("supportAssistant.analytics.problemSolved")]: item.solved_requests,
      [t("supportAssistant.analytics.ticketCreated")]: item.tickets_created,
      [t("supportAssistant.analytics.unknown")]: item.unknown_outcome,
    })) ?? [];

  let successRate = 0;
  if (analytics != null) {
    successRate = analytics?.totalIssuesSolved / analytics?.totalRequests;
  }

  return (
    <Dialog open={true} onClose={() => modals.close()}>
      <DialogPanel className="flex max-w-min flex-col gap-4">
        <div className="flex justify-between">
          <Typography level="h3">
            {t("supportAssistant.analytics.title")}
          </Typography>
          <DateRangePicker
            className="min-w-min"
            weekStartsOn={1}
            enableYearNavigation={false}
            selectPlaceholder={t("selectPredefinedDateRange")}
            placeholder={t("selectDateRange")}
            locale={locale}
            value={dateRange}
            onValueChange={(v) => setDateRange(v)}
            enableClear={false}
            defaultValue={{ selectValue: "last_30_days" }}
          >
            <DateRangePickerItem
              from={startOfDay(new Date())}
              to={endOfDay(new Date())}
              value="today"
            >
              {t("dateRange.today")}
            </DateRangePickerItem>
            <DateRangePickerItem
              from={startOfDay(subDays(new Date(), 7))}
              to={endOfDay(new Date())}
              value="last_seven_days"
            >
              {t("dateRange.lastSevenDays")}
            </DateRangePickerItem>
            <DateRangePickerItem
              from={startOfDay(subDays(new Date(), 30))}
              to={endOfDay(new Date())}
              value="last_30_days"
            >
              {t("dateRange.last30Days")}
            </DateRangePickerItem>
            <DateRangePickerItem
              from={startOfMonth(new Date())}
              to={endOfDay(new Date())}
              value="month_to_date"
            >
              {t("dateRange.monthToDate")}
            </DateRangePickerItem>
            <DateRangePickerItem
              from={startOfYear(new Date())}
              to={endOfDay(new Date())}
              value="year_to_date"
            >
              {t("dateRange.yearToDate")}
            </DateRangePickerItem>
          </DateRangePicker>
        </div>
        <Text>{t("supportAssistant.analytics.supportRequests")}</Text>
        <div className="flex gap-4">
          <Card>
            <Title>{t("supportAssistant.analytics.total")}</Title>
            <Metric>{analytics?.totalRequests}</Metric>
          </Card>
          <Card>
            <Title>{t("supportAssistant.analytics.problemSolved")}</Title>
            <Metric>{analytics?.totalIssuesSolved}</Metric>
          </Card>
          <Card>
            <Title>{t("supportAssistant.analytics.unknown")}</Title>
            <Metric>{analytics?.unknownOutcome}</Metric>
          </Card>
          <Card>
            <Title className="whitespace-nowrap">
              {t("supportAssistant.analytics.ticketCreated")}
            </Title>
            <Metric>{analytics?.totalTicketsCreated}</Metric>
          </Card>
          <Card>
            <Title>{t("supportAssistant.analytics.successQuota")}</Title>
            <Metric>
              {Number.isNaN(successRate)
                ? "-"
                : successRate.toLocaleString("de", { style: "percent" })}
            </Metric>
          </Card>
        </div>
        <BarChart
          categories={[
            t("supportAssistant.analytics.problemSolved"),
            t("supportAssistant.analytics.unknown"),
            t("supportAssistant.analytics.ticketCreated"),
          ]}
          data={chartData}
          index="day"
          stack
          colors={["green-500", "slate-500", "red-500"]}
          noDataText={t("supportAssistant.analytics.noData")}
        />
      </DialogPanel>
    </Dialog>
  );
}
