import {
  Button,
  FormControl,
  FormLabel,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
} from "@mui/joy";
import { useTranslation } from "../../lib/i18n";
import type { DatePickerValue } from "@tremor/react";
import {
  Card,
  DatePicker,
  Metric,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  Title,
} from "@tremor/react";
import { useCreditsUsage } from "../../lib/api/credits";
import { DelayedLoader } from "../util/DelayadLoader";
import type { ColumnDef, PaginationState } from "@tanstack/react-table";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import type { ApiCreditTransaction } from "apiTypes";
import { format, isSameDay } from "date-fns";
import { UserDisplay } from "../auth/UserMenu";
import { AdminPanelSettings, Info } from "@mui/icons-material";
import { useEffect, useMemo, useState } from "react";
import { useOrganizationFetcher } from "../../lib/hooks/useApi";
import useSWR from "swr";
import { trpc } from "../../lib/api/trpc/trpc";
import { toast } from "react-toastify";
import { useMe } from "../../lib/api/user";
import { SettingsPage } from "./SettingsPage";
import { PHASE_TYPES } from "../auth/CreateOrganizationModal";
import { useOrganization } from "../../lib/api/organization";

const TransactionTable = () => {
  const { t } = useTranslation();
  const columns: ColumnDef<ApiCreditTransaction>[] = useMemo(
    () => [
      {
        accessorKey: "type",
        header: t(`credits.transactions.type.header`),
        cell: ({ row }) =>
          t(`credits.transactions.type.${row.getValue("type")}`),
      },
      {
        accessorKey: "creditAmount",
        header: t(`credits.transactions.amount.header`),
      },
      {
        accessorKey: "generationModel",
        header: t(`credits.transactions.model.header`),
      },
      {
        accessorKey: "userId",
        header: t(`users`),
        cell: ({ row }) => <UserDisplay userId={row.getValue("userId")} />,
      },
      {
        accessorKey: "date",
        header: t(`credits.transactions.date.header`),
        cell: ({ row }) => {
          const usedAt = row.getValue("date") as string;
          return format(new Date(usedAt), "yyyy-MM-dd HH:mm:ss");
        },
      },
    ],
    [t]
  );

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const dataQuery = useSWR(
    `credits/transactions?page=${pagination.pageIndex + 1}`,
    useOrganizationFetcher(),
    { keepPreviousData: true }
  );

  const table = useReactTable({
    columns,
    data: dataQuery?.data?.rows ?? [],
    getCoreRowModel: getCoreRowModel(),
    pageCount: dataQuery.data?.pageCount ?? -1,
    getPaginationRowModel: getPaginationRowModel(),
    state: { pagination },
    onPaginationChange: setPagination,
    manualPagination: true,
  });

  return (
    <Card>
      <Title>{t("credits.transactions.title")}</Title>
      <Table>
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow
              key={headerGroup.id}
              className="border-b border-tremor-border"
            >
              {headerGroup.headers.map((header) => {
                return (
                  <TableHeaderCell key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHeaderCell>
                );
              })}
            </TableRow>
          ))}
        </TableHead>

        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div className="flex items-center justify-end space-x-2 py-4">
        <Button
          size="sm"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          Previous
        </Button>
        <Button
          size="sm"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Next
        </Button>
      </div>
    </Card>
  );
};

const CREDITS_DOCS_URL = "https://docs.meingpt.com/platform/credits";

export const CreditDisplay = () => {
  const { t } = useTranslation();
  const creditsUsage = useCreditsUsage();
  const creditsMutation = trpc.credits.addCredits.useMutation();
  const utils = trpc.useUtils();
  const me = useMe();

  if (!creditsUsage) return <DelayedLoader />;

  const addCredit = () => {
    const amount = parseFloat(
      prompt("Enter the amount of credits to add") ?? ""
    );
    if (isNaN(amount)) {
      alert("Please enter a valid number");
      return;
    }
    const confirm = parseFloat(
      prompt("Please retype the amount to confirm") ?? ""
    );
    if (amount !== confirm) {
      alert("Amounts do not match");
      return;
    }
    const comment = prompt("Enter a comment (optional)");
    creditsMutation
      .mutateAsync({
        creditAmount: amount,
        comment: comment ?? undefined,
      })
      .then(() => {
        void utils.credits.invalidate();
        toast.success(amount + " credits added");
      })
      .catch(() => {
        toast.error("Could not add credits");
      });
  };

  return (
    <SettingsPage title={<CreditsHeader />} sx={{ px: 1 }}>
      <Card className="flex items-center justify-between">
        <div>
          <Title>{t("credits.balance")}</Title>
          <span className="flex items-end space-x-1">
            <Metric>{creditsUsage.balance.toFixed(2)} Credits</Metric>
          </span>
          <Link href={CREDITS_DOCS_URL} target="_blank" rel="noreferrer">
            {t("moreInfo")}
          </Link>
        </div>
        {me?.isSuperUser && (
          <Button onClick={addCredit} startDecorator={<AdminPanelSettings />}>
            {t("addCredits")}
          </Button>
        )}
      </Card>
      <PhaseSettings />
      <TransactionTable />
    </SettingsPage>
  );
};

function CreditsHeader() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row gap-2">
      <span>{t("settings.tabs.credits")}</span>
      <a
        href={CREDITS_DOCS_URL}
        target="_blank"
        rel="noreferrer"
        style={{
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          "--Icon-fontSize": "1.4rem",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Tooltip title="Dokumentation" placement="right" size="sm">
          <Info fontSize="small" />
        </Tooltip>
      </a>
    </div>
  );
}

function PhaseSettings() {
  const [phase, setPhase] = useState(PHASE_TYPES[0].type);
  const [dateRange, setDateRange] = useState<{
    start: DatePickerValue;
    end: DatePickerValue;
  }>({ start: new Date(), end: new Date() });

  const organisation = useOrganization();
  const me = useMe();
  const { t } = useTranslation();

  const { mutateAsync: updateOrganization, isPending } =
    trpc.organization.mutateOrganization.useMutation();
  const utils = trpc.useUtils();

  const canUpdate = useMemo(() => {
    if (!organisation) return false;
    const {
      phaseStartDate: initStart,
      phaseEndDate: initEnd,
      phase: initPhase,
    } = organisation;

    let updatedDate = false;
    if (initStart && initEnd) {
      updatedDate = !(
        isSameDay(new Date(initStart), new Date(dateRange.start!)) &&
        isSameDay(new Date(initEnd), new Date(dateRange.end!))
      );
    }

    return initPhase !== phase || updatedDate;
  }, [organisation, phase, dateRange]);

  useEffect(() => {
    if (!organisation) return;
    const { phaseStartDate, phaseEndDate, phase: currentPhase } = organisation;
    setPhase(currentPhase);

    if (currentPhase === "NORMAL" || !phaseEndDate || !phaseStartDate) return;
    setDateRange({
      start: new Date(phaseStartDate),
      end: new Date(phaseEndDate),
    });
  }, [organisation]);

  const handleUpdate = async () => {
    if (!organisation) return;
    const { phase: initPhase } = organisation;

    try {
      const { start, end } = dateRange;
      await updateOrganization({
        phase: phase !== initPhase ? phase : undefined,
        phaseStartDate: start!.toISOString(),
        phaseEndDate: end!.toISOString(),
      });
      await utils.organization.invalidate();
    } catch (_) {
      toast.error("Fehler beim Phase update");
    }
  };

  if (organisation?.phase === "NORMAL") return;

  return (
    <Card>
      <Title>{t("credits.phase.title")}</Title>
      <Stack direction="row" flex={1} justifyContent="space-between">
        <Stack gap={1} mt={2}>
          <RadioGroup orientation="horizontal">
            {PHASE_TYPES.map(({ type, text }) => (
              <Radio
                onChange={() => setPhase(type)}
                key={type}
                checked={phase === type}
                value={text}
                label={text}
              />
            ))}
          </RadioGroup>
          <Stack direction="row" gap={2} sx={{ mt: 1, width: 500 }} flex={1}>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel>{t("createOrg.startDate")}</FormLabel>
              <DatePicker
                enableClear={false}
                value={dateRange.start}
                onValueChange={(start) =>
                  setDateRange((prev) => ({ ...prev, start }))
                }
              />
            </FormControl>

            <FormControl sx={{ flex: 1 }}>
              <FormLabel>{t("createOrg.endDate")}</FormLabel>
              <DatePicker
                enableClear={false}
                value={dateRange.end}
                onValueChange={(end) =>
                  setDateRange((prev) => ({ ...prev, end }))
                }
              />
            </FormControl>
          </Stack>
        </Stack>
        {me?.isSuperUser && (
          <Button
            disabled={!canUpdate}
            sx={{ my: "auto" }}
            onClick={handleUpdate}
            startDecorator={<AdminPanelSettings />}
            loading={isPending}
          >
            {t("credits.phase.update")}
          </Button>
        )}
      </Stack>
    </Card>
  );
}
