import { z } from 'zod';

export const WorkflowInputTypes = z
  .enum(['short_text', 'long_text', 'enum', 'toggle'])
  .default('short_text');
import { ModelOverride } from '../chat/chatTypes';

export const WorkflowInput = z.object({
  key: z.string(),
  name: z.string(),
  type: WorkflowInputTypes,
  options: z
    .array(
      z.object({
        label: z.string(),
        value: z.string(),
      }),
    )
    .default([]),
  placeholder: z.string().nullish(),
});

export const WorkflowStep = z.object({
  id: z.string(),
  order: z.number().int(),
  promptTemplate: z.string(),
  modelOverride: ModelOverride.nullable(),
});

export const Workflow = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  modelOverride: ModelOverride.nullable(),
  index: z.number(),
  departmentId: z.string(),
  estimatedMinutesSaved: z.number().nullable().optional(),
  includedDocuments: z.array(z.object({ id: z.string() })),
  steps: z.array(WorkflowStep),
  allowDocumentUpload: z.boolean().default(false),
  inputs: z.array(WorkflowInput).nullable().default([]),
});

export type WorkflowInputTypes = z.infer<typeof WorkflowInputTypes>;
export type Workflow = z.infer<typeof Workflow>;
export type WorkflowInput = z.infer<typeof WorkflowInput>;
export type WorkflowStep = z.infer<typeof WorkflowStep>;
