import React, { useMemo, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Tabs,
  TabList,
  Tab,
  Button,
  Divider,
  Alert,
  Chip,
  TabPanel,
} from "@mui/joy";
import { Article, ContentCopy, Info, OpenInNew } from "@mui/icons-material";
import { generateCodeExamples } from "./workflowApiClientUtils";
import { MarkdownRenderer } from "../chat/MarkdownRenderer";
import { useCopySafe } from "../../lib/hooks/useCopySafe";
import { useTranslation } from "react-i18next";
import type { Workflow } from "../../../../backend/src/api/workflow/workflowTypes";

export function WorkflowApiTab({ workflow }: { workflow: Workflow }) {
  const { t } = useTranslation();

  const [tabIndex, setTabIndex] = useState(0);

  const endpointUrl = `${window.location.origin}/api/workflows/v1/${workflow.id}/run`;

  const codeExamples = useMemo(
    () => generateCodeExamples(workflow, endpointUrl),
    [workflow, endpointUrl]
  );

  const copySafe = useCopySafe();

  return (
    <Box sx={{ p: 2 }}>
      <Typography level="h4" component="h2" sx={{ mb: 2 }}>
        <div className="flex flex-row gap-2">
          {t("workflowEditor.api.title")}
          <a
            href="https://docs.meingpt.com/platform/workflows/api"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              startDecorator={<Article />}
              endDecorator={<OpenInNew />}
              variant="outlined"
              color="primary"
              size="sm"
            >
              {t("documentation")}
            </Button>
          </a>
        </div>
      </Typography>
      <Alert
        variant="soft"
        color="primary"
        startDecorator={<Info />}
        sx={{ mb: 2 }}
      >
        {t("workflowEditor.api.explainNote")}
      </Alert>
      <Card variant="outlined" sx={{ mb: 3 }}>
        <CardContent>
          <Typography level="body-sm" sx={{ mb: 1 }}>
            {t("workflowEditor.api.endpointUrl")}:
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Typography level="body-lg" sx={{ flexGrow: 1 }}>
              <div className="flex flex-row items-center gap-2">
                <Chip variant="solid" color="warning" size="sm">
                  POST
                </Chip>{" "}
                <pre className="text-xs">{endpointUrl}</pre>
              </div>
            </Typography>
            <Button
              variant="outlined"
              color="neutral"
              startDecorator={<ContentCopy />}
              onClick={() => copySafe(endpointUrl)}
              size="sm"
            >
              {t("copy")}
            </Button>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Typography level="body-sm" sx={{ mb: 1 }}>
            {t("workflowEditor.api.exampleBodyTitle")}:
          </Typography>
          <MarkdownRenderer
            content={`\`\`\`json\n${codeExamples.payload}\n\`\`\``}
          />
        </CardContent>
      </Card>
      <Typography level="h4" component="h3" sx={{ mb: 2 }}>
        {t("workflowEditor.api.codeExamplesTitle")}
      </Typography>
      <Tabs
        value={tabIndex}
        onChange={(event, value) => setTabIndex(value as number)}
        sx={{ mb: 2 }}
      >
        <TabList>
          {codeExamples.examples.map((example, index) => (
            <Tab key={index}>{example.language}</Tab>
          ))}
        </TabList>
        {codeExamples.examples.map((example, index) => (
          <TabPanel key={index} value={index}>
            <MarkdownRenderer
              content={`\`\`\`${example.highlight}${example.code}\`\`\``}
            />
          </TabPanel>
        ))}
      </Tabs>
    </Box>
  );
}
