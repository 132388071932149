import {
  Delete,
  FolderOpen,
  InsertDriveFile,
  Lock,
  Upload as UploadIcon,
} from "@mui/icons-material";
import {
  Alert,
  Button,
  Card,
  IconButton,
  Modal,
  ModalClose,
  ModalDialog,
  ModalOverflow,
  Skeleton,
  Table,
  Tooltip,
  Typography,
} from "@mui/joy";
import { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useToggle } from "usehooks-ts";
import { trpc } from "../../../../lib/api/trpc/trpc.ts";
import { useDateFormatter } from "../../../../lib/hooks/useDateFormatter.tsx";
import { readableFileSize } from "../../../../lib/util.ts";

export function FileUploadConnectorModal({ id }: { id: string }) {
  const [open, toggle] = useToggle(false);
  const { t } = useTranslation();

  return (
    <>
      <Button startDecorator={<FolderOpen />} onClick={toggle} variant="soft">
        {t("modals.dataConnectors.fileUpload.title")}
      </Button>
      {open && <Editor id={id} onClose={toggle} />}
    </>
  );
}

function SkeletonTableRow() {
  return (
    <tr>
      {Array.from({ length: 2 }).map((_, i) => (
        <td key={i}>
          <Skeleton variant="text" />
        </td>
      ))}
    </tr>
  );
}

function Editor({ id, onClose }: { id: string; onClose: VoidFunction }) {
  const utils = trpc.useUtils();
  const { t } = useTranslation();
  const format = useDateFormatter();

  const { mutateAsync: generatePresignedPutUrls } =
    trpc.rag.dataConnectors.admin.uploads.generatePresignedPutUrls.useMutation();
  const [uploading, setUploading] = useState(false);

  async function onDrop(files: File[]) {
    try {
      const currentToast = toast.loading(t("common.upload.started"));
      setUploading(true);

      const urls = await generatePresignedPutUrls({
        dataConnectorId: id,
        files: files.map((file) => {
          return file.name;
        }),
      });

      for (let i = 0; i < files.length; i++) {
        toast.update(currentToast, {
          render: t("common.upload.inProgress", {
            currentFile: i + 1,
            totalFiles: files.length,
          }),
        });
        const file = files[i];
        const url = urls[i];

        await fetch(url!, {
          method: "PUT",
          body: file,
        }).catch((err) => {
          console.error(err);
          toast.error(t("common.upload.failed", { fileName: file.name }));
        });
      }

      await utils.rag.dataConnectors.admin.uploads.invalidate();
      toast.dismiss(currentToast);
      toast.success(t("common.upload.success"));
    } finally {
      setUploading(false);
    }
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFilePicker,
  } = useDropzone({
    onDrop,
    multiple: true,
    disabled: uploading,
    noClick: true,
    useFsAccessApi: false,
  });
  const { data: connector } = trpc.rag.dataConnectors.admin.get.useQuery({
    dataConnectorId: id,
  });

  const { data: files, isLoading: filesLoading } =
    trpc.rag.dataConnectors.admin.uploads.listFiles.useQuery({
      dataConnectorId: id,
    });

  const {
    mutateAsync: deleteFile,
    isPending: deleteFileLoading,
    variables: deleteFileVariables,
  } = trpc.rag.dataConnectors.admin.uploads.deleteFile.useMutation();

  return (
    <Modal open onClose={onClose}>
      <ModalOverflow>
        <ModalDialog
          maxWidth={800}
          className="transition-all"
          variant="outlined"
        >
          <ModalClose />
          <div className="flex flex-row gap-2">
            <Typography level="h3">{connector?.name}</Typography>
          </div>
          <Alert
            variant="soft"
            startDecorator={
              <Typography color="neutral">
                <Lock />
              </Typography>
            }
          >
            <Typography>
              {t("modals.dataConnectors.fileUpload.encryption")}
            </Typography>
          </Alert>
          <div className="max-h-96 overflow-y-auto">
            <Table stickyHeader>
              <thead>
                <tr>
                  <th colSpan={2}>{t("common.file")}</th>
                  <th>{t("common.lastModified")}</th>
                  <th>{t("common.size")}</th>
                </tr>
              </thead>
              {filesLoading &&
                Array.from({ length: 3 }).map((_, i) => (
                  <SkeletonTableRow key={i} />
                ))}
              {files?.map((file) => (
                <tr key={file.name}>
                  <td colSpan={2}>
                    <FileNameWithTooltip file={file} />
                  </td>
                  <td>
                    <Typography>
                      {file.lastModified
                        ? format(file.lastModified, "Pp")
                        : "-"}
                    </Typography>
                  </td>
                  <td>
                    <div className="flex items-center justify-between">
                      <Typography>{readableFileSize(file.size)}</Typography>
                      <IconButton
                        color="danger"
                        size="sm"
                        loading={
                          deleteFileLoading &&
                          deleteFileVariables?.name === file.name
                        }
                        onClick={() =>
                          void deleteFile({
                            dataConnectorId: id,
                            name: file.name,
                          }).then(
                            () =>
                              void utils.rag.dataConnectors.admin.uploads
                                .invalidate()
                                .then(() =>
                                  toast.success(
                                    t(
                                      "modals.dataConnectors.fileUpload.fileDeleted"
                                    )
                                  )
                                )
                          )
                        }
                      >
                        <Delete />
                      </IconButton>
                    </div>
                  </td>
                </tr>
              ))}
              {files && files.length === 0 && (
                <tr>
                  <td colSpan={2}>
                    <Typography>
                      {t("modals.dataConnectors.fileUpload.noFiles")}
                    </Typography>
                  </td>
                </tr>
              )}
            </Table>
          </div>
          <Card
            {...getRootProps()}
            sx={{
              cursor: "pointer",
              borderWidth: 1.5,
              borderColor: isDragActive ? "transparent" : undefined,
            }}
            variant={isDragActive ? "soft" : "outlined"}
            color={isDragActive ? "primary" : "neutral"}
            className="mt-1 cursor-pointer transition-all"
            onClick={openFilePicker}
          >
            <input {...getInputProps()} hidden id="dropzoneInput" />
            <div className="flex flex-col items-center justify-center space-y-2">
              <Button startDecorator={<UploadIcon />} loading={uploading}>
                {t("common.upload.files")}
              </Button>
              <Typography color="neutral" level="body-sm">
                {t("modals.dataConnectors.fileUpload.uploadHint")}
              </Typography>
            </div>
          </Card>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
}

const FileNameWithTooltip = ({ file }: { file: { name: string } }) => {
  const [isOverflow, setIsOverflow] = useState(false);
  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const el = textRef.current;
    if (el) {
      setIsOverflow(el.scrollWidth > el.clientWidth);
    }
  }, [file.name]);

  return (
    <div className="flex flex-row items-center gap-2">
      <InsertDriveFile />
      <Tooltip title={isOverflow ? file.name : ""}>
        <Typography ref={textRef} className="truncate">
          {file.name.split("/").pop()}
        </Typography>
      </Tooltip>
    </div>
  );
};
