import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Slider,
  Textarea,
} from "@mui/joy";

import { useTranslation } from "../../lib/i18n.ts";
import {
  type ImageAspectRatio,
  type ImageRequest,
} from "../../../../backend/src/api/tools/images/imagesTypes.ts";
import { SizeSelector } from "./SizeSelector.tsx";

export function ImagePromptInputEditor({
  input,
  setInput,
  onGenerate,
  generating,
}: {
  input: ImageRequest;
  setInput: (input: ImageRequest) => void;
  onGenerate: () => void;
  generating: boolean;
}) {
  const { t } = useTranslation();

  const handleEvent = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if ((e.ctrlKey || e.metaKey) && e.key === "Enter") {
      void onGenerate();
    }
  };

  return (
    <div className="flex flex-1 flex-col gap-5">
      <Textarea
        disabled={generating}
        onKeyDown={handleEvent}
        minRows={5}
        maxRows={5}
        value={input.prompt}
        onChange={(e) => {
          setInput({
            ...input,
            prompt: e.target.value,
          });
        }}
        placeholder={t("images.promptPlaceholderEmpty")}
      />

      <div className="flex flex-col gap-4 px-6">
        <FormControl>
          <FormLabel>{t("images.imageCount")}</FormLabel>
          <div className="flex items-center gap-5 pl-2">
            <Slider
              disabled={generating}
              defaultValue={input.numberOfImages}
              min={1}
              max={4}
              value={input.numberOfImages}
              variant="solid"
              onChange={(_, value) =>
                setInput({
                  ...input,
                  numberOfImages: value as number,
                })
              }
            />
            <FormHelperText sx={{ fontSize: "18px", pb: 1 }}>
              {input.numberOfImages}
            </FormHelperText>
          </div>
        </FormControl>
        <FormControl>
          <FormLabel>{t("images.aspectRatio")}</FormLabel>
          <SizeSelector
            disabled={generating}
            input={input.aspectRatio}
            setInput={(value: ImageAspectRatio) => {
              setInput({ ...input, aspectRatio: value });
            }}
          />
        </FormControl>
        <Button
          onClick={onGenerate}
          disabled={input.prompt.length === 0 || generating}
          size="md"
          sx={{ mt: 5 }}
        >
          {t("generate")}
        </Button>
      </div>
    </div>
  );
}
