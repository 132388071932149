import { AdminPanelSettings } from "@mui/icons-material";
import { Card, type ColorPaletteProp } from "@mui/joy";
import { type ReactNode } from "react";

export function SuperUserSettings({
  color = "primary",
  children,
}: {
  color?: ColorPaletteProp;
  children: ReactNode;
}) {
  return (
    <Card color={color}>
      <AdminPanelSettings
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          fontSize: "30px",
          transform: "translateY(-120%)",
        }}
      />
      {children}
    </Card>
  );
}
