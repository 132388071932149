import type { CompletionRequest, CompletionResponse } from "apiTypes";
import { useOrganizationApi } from "../hooks/useApi";
import type { LlmName } from "../../../../backend/src/ai/llmMeta";

export function useCompletion() {
  const orgApi = useOrganizationApi();

  return async (request: CompletionRequest, model: LlmName) => {
    const response = await orgApi.post(`/ai/llm/${model}/completions`, request);
    return response.data as CompletionResponse;
  };
}
