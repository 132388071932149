import type { InputProps } from "@mui/joy";
import { FormControl, FormHelperText, FormLabel, Input } from "@mui/joy";
import type { FieldProps } from "formik";
import { Field, getIn } from "formik";

export interface FormikTextFieldProps
  extends Omit<InputProps, "value" | "slots"> {
  helperText?: string;
  label?: string;
  containerClass?: string;
}

function FormikTextFieldAdapter({
  form: { isSubmitting, touched, errors },
  field,
  disabled,
  helperText,
  required,
  label,
  containerClass,
  ...props
}: FormikTextFieldProps & FieldProps) {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return (
    <FormControl
      disabled={disabled ?? isSubmitting}
      error={showError}
      required={required}
      className={containerClass ?? ""}
    >
      {label && <FormLabel>{label}</FormLabel>}
      <Input {...field} {...props} />
      <FormHelperText>{showError ? fieldError : helperText}</FormHelperText>
    </FormControl>
  );
}

export function FormikTextField(props: FormikTextFieldProps) {
  return <Field {...props} component={FormikTextFieldAdapter} />;
}
