import { FormHelperText, Input, Textarea, Typography } from "@mui/joy";
import type { TypographySystem } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useState } from "react";
import { ContactInfo } from "../../../../backend/src/api/organization/contactInfo/contactInfoTypes";

export const Settings = ({
  name,
  children,
  level = "title-md",
}: {
  name: string;
  children: React.ReactNode;
  level?: keyof TypographySystem;
}) => {
  return (
    <tr className="h-4">
      <td className="w-1/4 py-3 align-top">
        <Typography level={level}>{name}</Typography>
      </td>
      <td className="py-3">{children}</td>
    </tr>
  );
};

/**
 * Component for editing contact information.
 * @param contactInfo the values for the contact information.
 * @param setContactInfo used in DepartmentEditor where the changes are saved on submit.
 * @param onBlur used in GeneralSettings to save the changes of each input on blur.
 */
export function HelpCenterInputs({
  contactInfo,
  setContactInfo,
  onBlur,
  level,
}: {
  contactInfo: ContactInfo;
  setContactInfo?: (contactInfo: ContactInfo) => void;
  onBlur?: (e, key: keyof ContactInfo) => void;
  level?: keyof TypographySystem;
}) {
  const { t } = useTranslation();

  // Indicates if the email or phone input has an error.
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const inputProps = (key: keyof ContactInfo) => ({
    // default value should be set only if the value is not saved as a state.
    defaultValue: setContactInfo ? undefined : contactInfo[key] ?? "",
    // value should be set only if the value is saved as a state.
    value: setContactInfo && (contactInfo[key] ?? ""),
    // validates email and phone and saves the changes if onBlur is given.
    onBlur: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const phoneIsValid = ContactInfo.safeParse({
        phone: e.target.value,
      }).success;
      const emailIsValid = ContactInfo.safeParse({
        email: e.target.value,
      }).success;
      if (key === "phone" && !phoneIsValid) {
        setPhoneError(true);
        onBlur && toast.error(t("errors.saveFailed"));
      } else if (key === "email" && !emailIsValid) {
        setEmailError(true);
        onBlur && toast.error(t("errors.saveFailed"));
      } else {
        phoneIsValid && setPhoneError(false);
        emailIsValid && setEmailError(false);
        // Save the changes if onBlur is given and there are no errors.
        onBlur?.(e, key);
      }
    },
    // Stores the changes in the state if setContactInfo is given.
    onChange: (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setContactInfo?.({
        ...contactInfo,
        [key]: e.target.value,
      });
    },
  });
  return (
    <table className="!mb-8 w-full table-auto">
      <tbody>
        <Settings name={t("name")} level={level}>
          <Input {...inputProps("name")} />
        </Settings>
        <Settings name={t("email")} level={level}>
          <Input {...inputProps("email")} type="email" error={emailError} />
          {emailError && (
            <FormHelperText
              sx={{
                color: "#E35C69",
              }}
            >
              {t("errors.invalidEmail")}
            </FormHelperText>
          )}
        </Settings>

        <Settings name="Tel." level={level}>
          <Input {...inputProps("phone")} type="tel" error={phoneError} />
          {phoneError && (
            <FormHelperText
              sx={{
                color: "#E35C69",
              }}
            >
              {t("errors.invalidPhone")}
            </FormHelperText>
          )}
        </Settings>
        <Settings name={t("additionalInfo")} level={level}>
          <Textarea {...inputProps("additionalInfo")} sx={{ height: "80px" }} />
        </Settings>
      </tbody>
    </table>
  );
}
