import { Add, Cloud, CreateNewFolder, Web } from "@mui/icons-material";
import {
  Chip,
  Dropdown,
  ListDivider,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuItem,
  Typography,
} from "@mui/joy";
import { useState } from "react";
import { toast } from "react-toastify";
import { trpc } from "../../../../lib/api/trpc/trpc.ts";
import { AddSourceModal } from "./AddSourceModal.tsx";
import { ConnectorCard } from "./ConnectorCard.tsx";
import { useTranslation } from "react-i18next";
import type { ShallowObjectArray } from "../../../../../../backend/src/api/common/commonTypes.ts";

export function DataSourcesEditor({
  knowledgeCollectionId,
}: {
  knowledgeCollectionId: string;
}) {
  const { t } = useTranslation();
  const activeConnectors = trpc.rag.dataConnectors.admin.getAll.useQuery({
    knowledgeCollectionId,
  });

  const [addSourceModalOpen, setAddSourceModalOpen] = useState(false);

  const { mutateAsync: patch } =
    trpc.rag.knowledgeCollections.admin.update.useMutation();
  const utils = trpc.useUtils();

  async function setConnectors(dataConnectors: ShallowObjectArray) {
    await patch({
      knowledgeCollectionId,
      patch: {
        dataConnectors,
      },
    });
    void utils.rag.invalidate();
  }

  const { mutateAsync: newUploadDataConnector } =
    trpc.rag.dataConnectors.admin.uploads.create.useMutation({});

  async function createUploadConnector() {
    const connector = await newUploadDataConnector({
      name: t("knowledgeBase.dataSources.fileFolder"),
      description: t("knowledgeBase.dataSources.uploadFilesHere"),
    });
    await setConnectors([
      ...(activeConnectors.data ?? []),
      { id: connector.id },
    ]);
  }

  return (
    <div>
      <div className="mb-2 flex flex-row items-center justify-between">
        <Typography level="title-lg">
          {t("knowledgeBase.dataSources.title")}
        </Typography>
        <Dropdown>
          <MenuButton
            color="primary"
            size="sm"
            variant="outlined"
            startDecorator={<Add />}
          >
            {t("knowledgeBase.dataSources.addSource")}
          </MenuButton>
          <Menu placement="bottom-end">
            <MenuItem
              onClick={() => {
                void toast.promise(createUploadConnector(), {
                  success: t("knowledgeBase.dataSources.uploadFolder"),
                  error: t("knowledgeBase.dataSources.uploadFolder"),
                });
              }}
            >
              <ListItemDecorator>
                <CreateNewFolder />
              </ListItemDecorator>
              {t("knowledgeBase.dataSources.uploadFolder")}
            </MenuItem>
            <MenuItem disabled>
              <ListItemDecorator>
                <Web />
              </ListItemDecorator>
              {t("knowledgeBase.dataSources.websiteImport")}
              <Chip size="sm" color="primary">
                {t("common.available_soon")}
              </Chip>
            </MenuItem>
            <ListDivider />
            <MenuItem onClick={() => setAddSourceModalOpen(true)}>
              <ListItemDecorator>
                <Cloud />
              </ListItemDecorator>
              {t("knowledgeBase.dataSources.addOtherSource")}
            </MenuItem>
          </Menu>
        </Dropdown>
      </div>
      <ConnectorGrid
        emptyText={
          activeConnectors.data &&
          !activeConnectors.data.length &&
          t("knowledgeBase.dataSources.noSourcesAdded")
        }
      >
        {activeConnectors.data?.map((connector) => (
          <ConnectorCard
            key={connector.id}
            connector={connector}
            onRemove={() =>
              setConnectors(
                (activeConnectors.data ?? []).filter(
                  (c) => c.id != connector.id
                )
              )
            }
          />
        ))}
      </ConnectorGrid>
      <AddSourceModal
        open={addSourceModalOpen}
        onClose={() => setAddSourceModalOpen(false)}
        onAddConnector={async (connectorId) => {
          await setConnectors([
            ...(activeConnectors.data ?? []),
            { id: connectorId },
          ]);
          setAddSourceModalOpen(false);
        }}
      />
    </div>
  );
}

function ConnectorGrid({
  children,
  emptyText,
}: {
  children: React.ReactNode;
  emptyText?: string | false;
}) {
  return (
    <div>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
        {children}
      </div>
      {emptyText && (
        <Typography
          level="body-md"
          className="flex w-full justify-center pt-4"
          color="neutral"
        >
          {emptyText}
        </Typography>
      )}
    </div>
  );
}
