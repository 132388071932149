import { Add, Cloud } from "@mui/icons-material";
import {
  Avatar,
  Chip,
  List,
  ListDivider,
  ListItemContent,
  ListItemDecorator,
  ListSubheader,
  Modal,
  ModalClose,
  ModalDialog,
  ModalOverflow,
  Typography,
} from "@mui/joy";
import ListItemButton from "@mui/joy/ListItemButton";
import { trpc } from "../../../../lib/api/trpc/trpc.ts";
import { useParams } from "../../../../router.ts";
import { useTranslation } from "react-i18next";

export function DataSourceIcon({
  icon,
  ...props
}: {
  icon: string | null;
  className?: string;
}) {
  if (icon != null && icon?.startsWith("http")) {
    return <img src={icon} {...props} alt="source icon" />;
  } else {
    return <Cloud {...props} />;
  }
}

function DataSourceListItem({
  title,
  description,
  icon,
  preview = false,
  onClick,
}: {
  title: string;
  description: string | null;
  icon: string | null;
  preview?: boolean;
  onClick?: VoidFunction;
}) {
  const { t } = useTranslation();
  return (
    <ListItemButton
      className="group/source-icon"
      onClick={onClick}
      sx={{ gap: 3, py: 1 }}
      disabled={preview}
    >
      <ListItemDecorator>
        <Avatar size="lg">
          <Add
            className="group-hover/source-icon:block"
            sx={{ display: "none" }}
          />
          <DataSourceIcon
            className="group-hover/source-icon:hidden"
            icon={icon}
          />
        </Avatar>
      </ListItemDecorator>
      <ListItemContent>
        <Typography>
          {title}{" "}
          {preview && (
            <Chip size="sm" color="primary">
              {t("common.available_soon")}
            </Chip>
          )}
        </Typography>
        <Typography noWrap color="neutral">
          {description}
        </Typography>
      </ListItemContent>
    </ListItemButton>
  );
}

export function AddSourceModal({
  onClose,
  open,
  onAddConnector,
}: {
  open: boolean;
  onClose: VoidFunction;
  onAddConnector: (connectorId: string) => void;
}) {
  const { t } = useTranslation();
  const { id: collectionId } = useParams(
    "/:organizationId/settings/dataPool/:id"
  );

  const { data: allConnectors } = trpc.rag.dataConnectors.admin.getAll.useQuery(
    {}
  );

  const filteredConnectors = allConnectors
    ?.filter((c) => !c.knowledgeCollections?.some((k) => k.id === collectionId))
    .filter((c) => c.connectorType !== "MANUAL_UPLOAD");

  return (
    <Modal open={open} onClose={onClose}>
      <ModalOverflow>
        <ModalDialog maxWidth="sm" minWidth="sm">
          <ModalClose />
          <div className="flex flex-row gap-2">
            <Typography level="h3">
              {t("knowledge_base.data_pool.add_source_modal.title")}
            </Typography>
          </div>

          <List>
            {filteredConnectors?.length === 0 ? (
              <div className="mb-2 py-4">
                <Typography textAlign="center">
                  {t(
                    "knowledge_base.data_pool.add_source_modal.no_sources_available"
                  )}
                </Typography>
                <Typography textAlign="center" level="body-sm" color="neutral">
                  {t(
                    "knowledge_base.data_pool.add_source_modal.contact_support"
                  )}
                </Typography>
              </div>
            ) : (
              <ListSubheader>
                {t("knowledge_base.data_pool.available_sources")}
              </ListSubheader>
            )}
            {filteredConnectors?.map((connector, index) => (
              <>
                {index != 0 && <ListDivider inset="gutter" />}
                <DataSourceListItem
                  key={connector.id}
                  title={connector.name}
                  description={connector.description}
                  icon={connector.customIcon}
                  onClick={() => onAddConnector(connector.id)}
                />
              </>
            ))}
          </List>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
}
