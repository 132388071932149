import { Delete, Edit } from "@mui/icons-material";
import {
  Button,
  IconButton,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Option,
  Select,
} from "@mui/joy";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import type {
  WorkflowInput,
  WorkflowInputTypes,
} from "../../../../backend/src/api/workflow/workflowTypes.ts";
import { SelectionEditor } from "./SelectionEditor";
import { ToggleEditor } from "./ToggleEditor";
import { inputOptionsIncomplete } from "./workflowHelpers.ts";

export function InputRow({
  input,
  setInput,
  onDelete,
  isUsed,
}: {
  input: WorkflowInput;
  setInput: (input: WorkflowInput) => void;
  onDelete: () => void;
  isUsed: boolean;
}) {
  const { t } = useTranslation();

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [tempInput, setTempInput] = useState(input);

  const buttonDisabled = inputOptionsIncomplete(tempInput);

  function saveOptions() {
    setEditModalOpen(false);
    setInput(tempInput);
  }

  function newTypeInput(value: WorkflowInputTypes) {
    const toggleDefaultOptions = [
      { label: t("on"), value: "" },
      { label: t("off"), value: "" },
    ];
    return {
      ...input,
      type: value,
      options: value === "toggle" ? toggleDefaultOptions : [],
    };
  }

  function getInputOptionsPreview() {
    if (input.type === "enum") {
      return input.options
        .map((o) => (input.type === "enum" ? o.label : o.value))
        .join(", ");
    } else if (input.type === "toggle") {
      const onValue = input.options[0]?.value;
      const offValue = input.options[1]?.value;
      return t("workflowEditor.toggleOptionsPreview", {
        on: onValue ? onValue.substring(0, 15) + "..." : " - ",
        off: offValue ? offValue.substring(0, 15) + "..." : " - ",
      });
    }
  }

  const inputOptionsPreview = getInputOptionsPreview();

  return (
    <>
      <tr>
        <td
          className="text-md font-mono font-bold "
          style={{ color: "var(--joy-palette-primary-500)" }}
        >
          {"{{"}
          {input.key}
          {"}}"}
        </td>
        <td>
          <Input
            value={input.name}
            onChange={(e) => {
              setInput({ ...input, name: e.target.value });
            }}
            placeholder={t("inputName")}
          />
        </td>
        <td>
          <Select value={input.type}>
            {(
              [
                ["short_text", t("workflowEditor.shortText")],
                ["long_text", t("workflowEditor.textBlock")],
                ["enum", t("workflowEditor.selection")],
                ["toggle", `${t("on")}/${t("off")}`],
              ] as const
            ).map(([value, label]) => (
              <Option
                value={value}
                onClick={() => {
                  setInput(newTypeInput(value));
                  setTempInput(newTypeInput(value));
                }}
                key={value}
              >
                {label}
              </Option>
            ))}
          </Select>
        </td>
        <td>
          {input.type === "long_text" || input.type == "short_text" ? (
            "-"
          ) : (
            <>
              <div className="flex flex-row items-center gap-1">
                {input.options.length > 0 && !inputOptionsIncomplete(input) ? (
                  <div>{inputOptionsPreview}</div>
                ) : (
                  <div>
                    <em>{t("workflowEditor.optionsError")}</em>
                  </div>
                )}
                <IconButton
                  onClick={() => {
                    setEditModalOpen(true);
                  }}
                >
                  <Edit fontSize="small" />
                </IconButton>
              </div>
            </>
          )}
        </td>
        <td>
          <Input
            placeholder={`${t("forExampleAbbreviation")}  "${t("workflowEditor.input")}"`}
            value={input.placeholder ?? ""}
            onChange={(e) => {
              setInput({ ...input, placeholder: e.target.value });
            }}
          />
        </td>
        <td className="text-right">
          <IconButton
            color="danger"
            onClick={() => {
              onDelete();
            }}
            disabled={isUsed}
          >
            <Delete />
          </IconButton>
        </td>
      </tr>
      <Modal
        open={editModalOpen}
        onClose={(e, reason) => {
          if (reason !== "backdropClick") {
            setEditModalOpen(false);
            setTempInput(input);
          }
        }}
      >
        <ModalDialog minWidth="md">
          <ModalClose />
          {input.type === "enum" && (
            <SelectionEditor input={tempInput} setInput={setTempInput} />
          )}
          {input.type === "toggle" && (
            <ToggleEditor input={tempInput} setInput={setTempInput} />
          )}
          <div className="text-right">
            <Button onClick={saveOptions} disabled={buttonDisabled}>
              {t("save")}
            </Button>
          </div>
        </ModalDialog>
      </Modal>
    </>
  );
}
