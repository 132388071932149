import { Card, Typography } from "@mui/joy";
import { useTranslation } from "../../lib/i18n";

export function Citation({
  citations,
}: {
  citations?: {
    link: string;
    title: string;
  }[];
}) {
  const { t } = useTranslation();
  if (!citations || citations.length === 0) return null;
  return (
    <>
      <Typography>{t("sources")}</Typography>
      <div className="grid grid-cols-1 gap-3 pt-2 md:grid-cols-2 xl:grid-cols-4 xl:gap-2">
        {citations.map((citation, index) => (
          <a href={citation.link} key={index} target="_blank" rel="noreferrer">
            <Card
              variant="soft"
              size="sm"
              className="transition-shadow hover:shadow-lg"
            >
              <div>
                <Typography color="neutral" level="body-sm">
                  {citation.title.length > 30
                    ? `${citation.title.substring(0, 28)}...`
                    : citation.title}
                </Typography>

                <div className="flex flex-row items-center gap-2 pt-2">
                  <img
                    src={`https://icons.meingpt.com/icon?url=${citation.link}&size=24..32..64`}
                    width={20}
                  />
                  <Typography color="neutral" level="body-xs" variant="soft">
                    {citation.link.replace(
                      /^https?:\/\/(www\.)?|(\.[a-z]{2,})(\/.*)?$/gi,
                      ""
                    )}{" "}
                    • {index + 1}
                  </Typography>
                </div>
              </div>
            </Card>
          </a>
        ))}
      </div>
    </>
  );
}
