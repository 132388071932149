import prompts from "./generated.json";

export { prompts };

type Message = {
  role: string;
  content: string;
};

type Prompt = {
  messages: Message[];
  placeholders: string[];
};

export function compilePrompt<T extends Prompt>(
  prompt: T,
  placeholderValues: Record<T["placeholders"][number], string>
): Message[] {
  // check if all placeholders are provided
  const missingPlaceholders = prompt.placeholders.filter(
    (placeholder) => !placeholderValues[placeholder]
  );
  if (missingPlaceholders.length > 0) {
    throw new Error(
      `Missing placeholders: ${missingPlaceholders.join(", ")} in prompt`
    );
  }

  return prompt.messages.map((message) => {
    let contentWithReplacements = message.content;

    // Replace each placeholder with its value from the `placeholderValues` dictionary
    for (const [key, value] of Object.entries(placeholderValues) as [
      string,
      string
    ][]) {
      const placeholder = `{{${key}}}`;
      const placeholderRegex = new RegExp(placeholder, "g");
      contentWithReplacements = contentWithReplacements.replace(
        placeholderRegex,
        value
      );
    }

    return {
      ...message,
      content: contentWithReplacements,
    };
  });
}
