import { z } from 'zod';

const VectorSearchSettings = z.object({
  searchLimit: z.number().int().min(1).max(100).default(10),
  useHybridSearch: z.boolean().default(false),
  minScore: z.number().min(0).max(1).default(0.1),
});

const RerankSettings = z.object({
  enabled: z.boolean().default(false),
  topN: z.number().int().min(1).max(100).default(3),
  minScore: z.number().min(0).max(1).default(0.1),
});

export const SearchConfig = z.object({
  search: VectorSearchSettings.default({}),
  reranking: RerankSettings.default({}),
});
export type SearchConfig = z.infer<typeof SearchConfig>;
