import { ApiEmailInvite } from "apiTypes";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  useOrganizationApi,
  useOrganizationSchemaResource,
  useOrganizationSWR,
} from "../hooks/useApi";

export function useEmailInvites() {
  return useOrganizationSchemaResource("invites/email", ApiEmailInvite.array());
}

export function useMutateEmailInvites() {
  return useOrganizationSWR("invites/email").mutate;
}

export function useCreateEmailInvite() {
  const { t } = useTranslation();
  const mutate = useMutateEmailInvites();
  const api = useOrganizationApi();
  return async (email: string | string[]) => {
    if (Array.isArray(email)) {
      await api.post(
        "invites/emails",
        email.map((m) => ({ email: m })),
      );
      toast.success(t("invitesSent", { count: email.length }));
    } else {
      await api.post("invites/email", { email });
      toast.success(t("inviteSent", { email }));
    }
    await mutate();
  };
}

export function useDeleteEmailInvite() {
  const { t } = useTranslation();
  const mutate = useMutateEmailInvites();
  const api = useOrganizationApi();
  return async (email: string) => {
    await api.delete(`invites/email/${email}`);
    await mutate();
    toast.success(t("inviteDeleted", { email }));
  };
}
