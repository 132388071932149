// XP thresholds for levels 1 to 11 (after level 11, every 200 XP is a new level)
export const XP_THRESHOLDS = [10, 50, 40, 100, 200, 200, 200, 200, 200, 200];

export const LEVEL_NAMES = [
  "beginner",
  "novice",
  "prompt architect",
  "ai_pioneer",
  "ai_allstar",
  "ai_visionary",
  "ai_innovator",
  "ai_expert",
  "ai_master",
  "ai_mentor",
];

function determineLevel(xp: number): number {
  let level = 1;
  while (level < XP_THRESHOLDS.length && xp >= sumXPThresholds(level)) {
    level++;
  }
  if (level >= XP_THRESHOLDS.length) {
    const additionalLevels = Math.floor(
      (xp - XP_THRESHOLDS[XP_THRESHOLDS.length - 1]) / 200
    );
    level += additionalLevels;
  }
  return level;
}

function xpForNextLevel(level: number): number {
  return level < XP_THRESHOLDS.length - 1 ? XP_THRESHOLDS[level] : 200;
}

function sumXPThresholds(level: number) {
  if (level <= 0) {
    return 0;
  }
  let totalXP = 0;
  for (let i = 0; i < level; i++) {
    totalXP += XP_THRESHOLDS[i];
  }
  return totalXP;
}

/**
 * Calculate the current level, progress to the next level, and xp needed for the next level
 * For levels beyond the thresholds, every 200 XP is a new level
 * @param xp: a user's xp
 */
export function calcLevelProgress(xp: number): {
  currentLevel: number;
  currentLevelProgess: number;
  xpNeededForNextLevel: number;
  totalXP: number;
} {
  const currentLevel = determineLevel(xp);
  const xpForCurrentLevel = sumXPThresholds(currentLevel - 1);
  const currentLevelProgess = xp - xpForCurrentLevel;
  const xpNeededForNextLevel =
    xpForNextLevel(currentLevel - 1) - currentLevelProgess;
  return {
    currentLevel,
    currentLevelProgess,
    xpNeededForNextLevel,
    totalXP: xp,
  };
}

// Range of 0 to 100
export function normalizeCurrentProgress(
  currentLevelProgess: number,
  level: number
): number {
  const progress = (currentLevelProgess / xpForNextLevel(level - 1)) * 100;
  return Math.floor(Math.min(100, progress));
}
