import { type SxProps } from "@mui/system";
import * as Icons from "@mui/icons-material";

export function DynamicIcon({
  iconName,
  iconState,
  onChange,
  sx,
}: {
  iconName?: string;
  iconState?: string;
  onChange?: (value: string) => void;
  sx?: SxProps;
}) {
  if (!iconName) {
    return <Icons.QuestionMark sx={sx} />;
  }
  const IconComponent = Icons[iconName];
  if (!IconComponent) {
    iconState !== "" && onChange?.("");
    return <Icons.QuestionMark sx={sx} />;
  }
  iconState !== iconName && onChange?.(iconName);
  return <IconComponent sx={sx} />;
}
