import AccessTimeIcon from "@mui/icons-material/AccessTime";

import { Option, Select } from "@mui/joy";
import { Title } from "@tremor/react";
import { useTranslation } from "react-i18next";

function TimespanSelection({
  timespan,
  setTimespan,
  timespanMap,
}: {
  timespan: number;
  setTimespan: (timespan: number) => void;
  timespanMap: Record<number, string>;
}) {
  const { t } = useTranslation();

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div className="me-3">
        <Title>{t("timespan.title")}</Title>
      </div>
      <Select
        startDecorator={<AccessTimeIcon />}
        defaultValue={timespan}
        onChange={(_e, value) => {
          if (value != null) {
            setTimespan(value);
          }
        }}
        sx={{ minWidth: 120 }}
        value={timespan}
      >
        {Object.entries(timespanMap).map(([key, value]) => {
          return (
            <Option value={Number(key)} key={value}>
              {value}
            </Option>
          );
        })}
      </Select>
    </div>
  );
}
export default TimespanSelection;
