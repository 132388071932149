import { Button, ButtonGroup } from "@mui/joy";
import type { ComponentProps } from "react";

export function SegmentDisplay<T = string>({
  options,
  value,
  setValue,
  ...rest
}: {
  options: {
    label: string;
    value: T;
  }[];
  value: T;
  setValue: (value: T) => void;
} & ComponentProps<typeof ButtonGroup>) {
  return (
    <ButtonGroup size="lg" {...rest}>
      {options.map((option, i) => (
        <Button
          key={i}
          variant={option.value === value ? "solid" : "outlined"}
          color="primary"
          onClick={() => setValue(option.value)}
        >
          {option.label}
        </Button>
      ))}
    </ButtonGroup>
  );
}
