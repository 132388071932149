import {
  Button,
  Card,
  Chip,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Textarea,
  Typography,
} from "@mui/joy";

import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBlocker } from "react-router-dom";
import { LLM_META } from "../../../../backend/src/ai/llmMeta.ts";
import type { ModelOverride } from "../../../../backend/src/api/chat/chatTypes.ts";
import type {
  Workflow,
  WorkflowInput,
  WorkflowStep,
} from "../../../../backend/src/api/workflow/workflowTypes.ts";
import { useOrganization } from "../../lib/api/organization";
import { ModelIcon } from "../../lib/ModelIcon.tsx";
import { ModelSelectorModal } from "../input/ModelSelectorModal";
import { UnsavedChangesModal } from "../util/UnsavedChangesModal";
import { inputOptionsIncomplete } from "./workflowHelpers.ts";
import { WorkflowInputEditor } from "./WorkflowInputEditor.tsx";
import { WorkflowStepsEditor } from "./WorkflowStepsEditor.tsx";
import { trpc } from "../../lib/api/trpc/trpc.ts";
import { WorkflowApiTab } from "./WorkflowApiTab.tsx";

export function WorkflowEditor({
  initialValues,
  onSubmit,
}: {
  initialValues: Workflow;
  onSubmit: (values: Workflow) => void;
}) {
  const { t } = useTranslation();

  const [currentValues, setCurrentValues] = useState<Workflow>(initialValues);
  const defaultModel: ModelOverride =
    useOrganization()?.defaultModel ?? "gpt-4o-mini";
  const selectedModel = currentValues.modelOverride ?? defaultModel;
  const [open, setOpen] = useState(false);

  const { data: productConfig } = trpc.productConfig.get.useQuery();
  const enabledApiTab = !!productConfig?.workflowApi;

  // all variables that have not been edited yet, will be deleted when not used in the text
  const untouchedVars = useRef<string[]>([]);

  const inputsNotComplete = currentValues.inputs?.some(inputOptionsIncomplete);

  const saveDisabled =
    inputsNotComplete ||
    (currentValues?.name?.trim() ?? "") === "" ||
    !Object.keys(initialValues ?? {}).some(
      (input) =>
        !_.isEqual(initialValues?.[input] ?? "", currentValues[input] ?? "")
    );
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      !saveDisabled && currentLocation.pathname !== nextLocation.pathname
  );

  useEffect(() => {
    // Browser warning when leaving with unsaved changes
    const warn = (e) => {
      if (!saveDisabled) {
        e.preventDefault();
        //legacy support
        e.returnValue = true;
      }
    };

    window.addEventListener("beforeunload", warn);
    return () => {
      window.removeEventListener("beforeunload", warn);
    };
  }, [saveDisabled, t]);

  const findVariablesInSteps = useCallback((steps: WorkflowStep[]) => {
    const found = steps.flatMap((s) => {
      return (s.promptTemplate.match(/{{(.*?)}}/g) ?? []).map(
        (variable: string) => variable.replace(/{{(.*?)}}/, "$1")
      );
    });

    return Array.from(new Set(found)) ?? [];
  }, []);

  function updateSteps(steps: WorkflowStep[]) {
    const variablesInSteps = findVariablesInSteps(steps);

    const inputs = [...(currentValues.inputs ?? [])].filter(
      // filter out variables that are not present anymore and are untouched
      (input) =>
        !untouchedVars.current.includes(input.key) ||
        variablesInSteps.includes(input.key)
    );

    // add all variables that are present in the text but not in the inputs
    for (const variable of variablesInSteps) {
      if (!inputs.find((i) => i.key === variable)) {
        inputs.push({
          name: variable,
          type: "short_text",
          key: variable,
          options: [],
        });
        // mark as untouched, so it won't be deleted
        untouchedVars.current.push(variable);
      }
    }

    // cleanup the untouchedVars to prevent memory leaks
    untouchedVars.current = untouchedVars.current.filter((v) =>
      variablesInSteps.includes(v)
    );

    setCurrentValues({
      ...currentValues,
      steps,
      inputs: inputs.sort((a, b) => b.key.localeCompare(a.key) * -1),
    });
  }

  const variablesInText = findVariablesInSteps(currentValues.steps ?? []);

  function deleteInput(input: WorkflowInput) {
    setCurrentValues({
      ...currentValues,
      inputs: currentValues.inputs?.filter((i) => i.key !== input.key) ?? [],
    });

    if (untouchedVars.current.includes(input.key)) {
      untouchedVars.current = untouchedVars.current.filter(
        (v) => v !== input.key
      );
    }
  }

  function updateInput(input: WorkflowInput) {
    untouchedVars.current = untouchedVars.current.filter(
      (v) => v !== input.key
    );
    setCurrentValues({
      ...currentValues,
      inputs:
        currentValues.inputs?.map((i) => (i.key === input.key ? input : i)) ??
        [],
    });
  }

  return (
    <div className="flex flex-col items-stretch gap-4">
      <Tabs variant="soft" color="neutral">
        <TabList>
          <Tab>{t("generalSettings")}</Tab>
          <Tab>{t("workflowEditor.actionTab")}</Tab>
          {enabledApiTab && (
            <Tab>
              {t("workflowEditor.apiTab")}{" "}
              <Chip color="success">{t("new")}</Chip>
            </Tab>
          )}
        </TabList>
        <TabPanel
          value={0}
          sx={{
            padding: 0,
            paddingTop: 2,
          }}
        >
          <Card>
            <div className="flex flex-col gap-8">
              <div>
                <FormControl>
                  <FormLabel>{t("name")}</FormLabel>
                  <Input
                    required
                    value={currentValues.name}
                    onChange={(e) =>
                      setCurrentValues({
                        ...currentValues,
                        name: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </div>
              <FormControl>
                <FormLabel>{t("description")}</FormLabel>
                <Textarea
                  minRows={3}
                  maxRows={6}
                  value={currentValues.description ?? ""}
                  onChange={(e) =>
                    setCurrentValues({
                      ...currentValues,
                      description: e.target.value,
                    })
                  }
                />
              </FormControl>

              <div className="w-min">
                <FormControl>
                  <FormLabel>{t("workflowEditor.timeSaved")}</FormLabel>
                  <Input
                    value={currentValues.estimatedMinutesSaved + ""}
                    type="number"
                    endDecorator="min"
                    placeholder="5"
                    onChange={(e) => {
                      setCurrentValues({
                        ...currentValues,
                        estimatedMinutesSaved: Math.max(
                          parseInt(e.target.value),
                          0
                        ),
                      });
                    }}
                  />
                </FormControl>
              </div>
              <FormControl>
                <div className="mb-2 -space-y-1.5">
                  <FormLabel>{t("workflowEditor.whichLLM")}</FormLabel>
                  <Typography level="body-xs">
                    {t("workflowEditor.individualLLM")}
                  </Typography>
                </div>
                <div>
                  <Button
                    variant="outlined"
                    color="neutral"
                    onClick={() => {
                      setOpen(true);
                    }}
                    sx={{ px: 3, fontSize: "16px" }}
                  >
                    <ModelIcon
                      modelName={selectedModel}
                      style={{ height: "20px", marginRight: "5px" }}
                    />
                    {LLM_META[selectedModel]?.name ?? t("bestModel")}
                  </Button>
                  <ModelSelectorModal
                    open={open}
                    onRequestClose={() => setOpen(false)}
                    selectedModel={selectedModel}
                    setSelectedModel={(model: ModelOverride | null) => {
                      setCurrentValues({
                        ...currentValues,
                        modelOverride: model,
                      });
                    }}
                  />
                </div>
              </FormControl>
            </div>
          </Card>
        </TabPanel>
        <TabPanel value={1}>
          <Stack gap={6}>
            <WorkflowInputEditor
              variablesInText={variablesInText}
              currentValues={currentValues}
              updateDocumentIds={(ids) => {
                setCurrentValues((v) => ({
                  ...v,
                  includedDocuments: ids,
                }));
              }}
              updateAllowUploads={(v) =>
                setCurrentValues({
                  ...currentValues,
                  allowDocumentUpload: v,
                })
              }
              updateInput={updateInput}
              deleteInput={deleteInput}
            />
            <WorkflowStepsEditor
              defaultModel={selectedModel}
              steps={currentValues.steps ?? []}
              onChange={(steps) => updateSteps(steps)}
            />
          </Stack>
        </TabPanel>
        {enabledApiTab && (
          <TabPanel value={2}>
            <WorkflowApiTab workflow={initialValues} />
          </TabPanel>
        )}
      </Tabs>

      <div className="flex w-full justify-end">
        <Button
          onClick={() => {
            onSubmit(currentValues);
          }}
          disabled={saveDisabled}
        >
          {initialValues ? t("save") : t("create")}
        </Button>
      </div>
      <UnsavedChangesModal
        open={blocker.state === "blocked"}
        proceed={blocker.proceed ?? (() => {})}
        cancel={blocker.reset ?? (() => {})}
      />
    </div>
  );
}
