import { Box, Card, LinearProgress, Link, Stack, Typography } from "@mui/joy";
import { LanguagesPicker } from "./LanguagesPicker";
import type { SelectedLanguages } from "../../pages/[organizationId]/tools/translateContent";

interface TranslateTableProps {
  targetText?: string;
  setSourceText?: (text: string) => void;
  children: React.ReactNode;
  selectedLanguages: SelectedLanguages;
  setSelectedLanguages: (selectedLanguages: SelectedLanguages) => void;
  isLoading: boolean;
}

export default function TranslateTable({
  targetText,
  setSourceText,
  children,
  isLoading,
  selectedLanguages,
  setSelectedLanguages,
}: TranslateTableProps) {
  return (
    <Stack width="100%" position="relative">
      <Box className="w-full">
        <Card>
          <Stack height="100%">
            <Stack borderBottom="1px solid #CED4D9" pb={1}>
              <LanguagesPicker
                targetText={targetText}
                setSourceText={setSourceText}
                selectedLanguages={selectedLanguages}
                setSelectedLanguages={setSelectedLanguages}
                detectLanguage
              />
            </Stack>
            {children}
            {isLoading && (
              <LinearProgress
                sx={{
                  position: "absolute",
                  background: "transparent",
                  width: "100%",
                  left: 0,
                  top: 0,
                  "--LinearProgress-thickness": "4px",
                  "--LinearProgress-radius": "0px",
                  "--LinearProgress-progressRadius": "2px",
                }}
              />
            )}
          </Stack>
        </Card>
        <Typography level="body-sm" width="100%" textAlign="right">
          powered by{" "}
          <Link target="_blank" href="https://www.deepl.com/en/translator">
            DeepL
          </Link>
        </Typography>
      </Box>
    </Stack>
  );
}
