import { ApiDate } from 'apiTypes';
import z from 'zod';
import { LlmName } from '../../../ai/llmMeta';

const ResultComponent = z.object({
  score: z.number(),
  result: z.string(),
  documentTitle: z.string(),
  documentId: z.string(),
  sourceNumber: z.number().int().optional(),
});

export type ResultComponent = z.infer<typeof ResultComponent>;

const RagSource = z.object({
  id: z.string(),
  query: z.string().optional(),
  knowledgeCollectionId: z.string(),
  resultComponents: z.array(ResultComponent),
});
export type RagSource = z.infer<typeof RagSource>;

export const Message = z.object({
  id: z.string(),
  content: z.string(),
  createdAt: ApiDate,
  fromAi: z.boolean(),
  responseCompleted: z.boolean().nullable(),
  authorId: z.string().nullable(),
  chatId: z.string(),
  generationModel: LlmName.default('gpt-4o-mini' satisfies LlmName).nullable(),
  attachmentIds: z.array(z.string()).default([]),
  ragSources: z.array(RagSource).default([]),
  citations: z.array(z.string()).default([]),
  cancelled: z.boolean().nullable(),
  errorCode: z.string().nullable().optional(),
  tokens: z.number(),
});

export type Message = z.infer<typeof Message>;

export enum MessageGenerationStep {
  /** The RAG agent is generating queries and deciding which data pools to search in*/
  FormulatingQueries = 'formulatingQueries',
  /** The RAG system is queried for information */
  SearchingForInformation = 'searchingForInformation',
  /** The AI is generating the final response */
  GeneratingResponse = 'generatingResponse',
}

export type MessageGenerationProgress = {
  totalSteps?: number;
  currentStepNumber?: number;
  currentStepType?: MessageGenerationStep;
  queryCount?: number;
  dataPoolCount?: number;
};
