import { useEffect, useState } from "react";
import type { OrganizationPhase } from "../../../../backend/src/api/organization/organizationTypes";
import { trpc } from "../../lib/api/trpc/trpc";
import { useTranslation } from "react-i18next";
import { useNavigate } from "../../router";
import {
  Alert,
  Button,
  Card,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalDialog,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/joy";
import type { DatePickerValue } from "@tremor/react";
import { DatePicker } from "@tremor/react";
import CompanyMetaContainer from "./CompanyMetaContainer";
import { addMonths, startOfMonth } from "date-fns";
import { Info } from "@mui/icons-material";

const DEFAULT_CREDITS_IN_EUR_TRIAL = 5;
const DEFAULT_CREDITS_IN_EUR_PILOT = 200;

const INIT_COMPANY_STATE: CompanyDataInput = {
  domain: "",
  description: "",
  primaryColor: "",
  secondaryColor: "",
  imageUri: "",
};

export const PHASE_TYPES: { type: OrganizationPhase; text: string }[] = [
  {
    type: "NORMAL",
    text: "Normal",
  },
  {
    type: "TRIAL",
    text: "Trial",
  },
  {
    type: "PILOT",
    text: "Pilot",
  },
];

export interface CompanyDataInput {
  domain: string;
  description: string;
  primaryColor: string;
  secondaryColor: string;
  imageUri: string;
}

interface CreateOrganizationModalProps {
  open: boolean;
  onClose: () => void;
}

export default function CreateOrganizationModal({
  open,
  onClose,
}: CreateOrganizationModalProps) {
  const [name, setName] = useState<string>("");
  const [phase, setPhase] = useState(PHASE_TYPES[0].type);
  const [credits, setCredits] = useState<number>(0);
  const [dateRange, setDateRange] = useState<{
    start: DatePickerValue;
    end: DatePickerValue;
  }>({ start: new Date(), end: new Date() });
  const [companyData, setCompanyData] =
    useState<CompanyDataInput>(INIT_COMPANY_STATE);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutateAsync: createOrganization, isPending } =
    trpc.organization.create.useMutation();

  useEffect(() => {
    if (phase === "NORMAL") return;

    const today = new Date();
    if (phase === "TRIAL") {
      // Set to 7 days
      const range = new Date();
      range.setDate(today.getDate() + 7);

      setDateRange({
        start: today,
        end: range,
      });

      setCredits(DEFAULT_CREDITS_IN_EUR_TRIAL);
    }

    if (phase === "PILOT") {
      // Set for 3 months starting with first day of next month
      const firstDay = startOfMonth(addMonths(today, 1));
      const range = addMonths(firstDay, 3);

      setDateRange({
        start: firstDay,
        end: range,
      });

      setCredits(DEFAULT_CREDITS_IN_EUR_PILOT);
    }
  }, [phase]);

  const handleSubmit = async () => {
    const temporary = phase !== "NORMAL";

    const { id } = await createOrganization({
      name,
      phaseType: phase,
      initCredits: temporary ? credits * 100 : undefined,
      phaseStart: temporary ? dateRange.start?.toISOString() : undefined,
      phaseEnd: temporary ? dateRange.end?.toISOString() : undefined,
      description: companyData.description || undefined,
      domain: companyData.domain || undefined,
      primaryColor: companyData.primaryColor || undefined,
      secondaryColor: companyData.secondaryColor || undefined,
      imageUri: companyData.imageUri || undefined,
    });

    onClose();
    navigate("/:organizationId/settings", {
      params: {
        organizationId: id,
      },
      replace: true,
    });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog sx={{ p: 1 }}>
        <Card
          variant="plain"
          sx={{
            minWidth: 600,
            textAlign: "center",
          }}
        >
          <Typography level="h4">{t("createOrg.createNew")}</Typography>
          <Divider sx={{ my: 1 }} />
          <FormControl sx={{ flex: 1, mb: 2 }}>
            <FormLabel>{t("createOrg.name")}</FormLabel>
            <Input
              placeholder={t("createOrg.company")}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>

          <Stack direction="row">
            <FormControl sx={{ flex: 1 }}>
              <FormLabel>{t("createOrg.orgPhase")}</FormLabel>

              <RadioGroup orientation="horizontal">
                {PHASE_TYPES.map(({ type, text }) => (
                  <Radio
                    onChange={() => setPhase(type)}
                    key={type}
                    checked={phase === type}
                    value={text}
                    label={text}
                  />
                ))}
              </RadioGroup>
            </FormControl>

            {phase !== "NORMAL" && (
              <FormControl
                sx={{
                  flex: 1,
                  ml: 2,
                  alignContent: "end",
                }}
              >
                <FormLabel>{t("createOrg.preloadedCredits")}</FormLabel>
                <Input
                  value={credits}
                  onChange={(e) => setCredits(parseInt(e.target.value))}
                  sx={{ width: 200 }}
                  type="number"
                />
              </FormControl>
            )}
          </Stack>

          {phase !== "NORMAL" && (
            <Alert>
              <Info />
              {t("trial.taggedCoursesAdded", {
                tag: `${phase[0] + phase.slice(1).toLowerCase()}`,
              })}
            </Alert>
          )}

          {phase !== "NORMAL" && (
            <Stack direction="row" gap={2} sx={{ mt: 1 }}>
              <FormControl sx={{ flex: 1 }}>
                <FormLabel>{t("createOrg.startDate")}</FormLabel>
                <DatePicker
                  value={dateRange.start}
                  onValueChange={(start) =>
                    setDateRange((prev) => ({ ...prev, start }))
                  }
                />
              </FormControl>

              <FormControl sx={{ flex: 1 }}>
                <FormLabel>{t("createOrg.endDate")}</FormLabel>
                <DatePicker
                  value={dateRange.end}
                  onValueChange={(end) =>
                    setDateRange((prev) => ({ ...prev, end }))
                  }
                />
              </FormControl>
            </Stack>
          )}

          <Divider sx={{ my: 1, mx: 1 }} />

          <CompanyMetaContainer
            companyData={companyData}
            setCompanyData={setCompanyData}
          />

          <Button
            loading={isPending}
            onClick={handleSubmit}
            sx={{ mt: 4 }}
            fullWidth
            disabled={name.trim().length === 0}
          >
            {t("createOrg.create")}
          </Button>
        </Card>
      </ModalDialog>
    </Modal>
  );
}
