import { Button, Stack, Typography } from "@mui/joy";
import { useParams } from "../../router";
import { useQueuedMessagesStore } from "../../lib/context/queuedMessagesStore";
import { useTranslation } from "../../lib/i18n";
import { useState } from "react";
import { motion } from "framer-motion";
import type { ButtonProps } from "@mui/joy";
import useBreakingPoint from "../../lib/hooks/useBreakpoint";

export function SmartIterations({ disabled }: { disabled?: boolean }) {
  const { t } = useTranslation();
  const isSmall = useBreakingPoint("xl");

  const iterationOptions = [
    {
      label: t("iterations.shorter.label"),
      emoji: "➖",
      prompt: t("iterations.shorter.prompt"),
    },
    {
      label: t("iterations.detail.label"),
      emoji: "➕",
      prompt: t("iterations.detail.prompt"),
    },
    {
      label: t("iterations.improve.label"),
      emoji: "🔧",
      prompt: t("iterations.improve.prompt"),
    },
    {
      label: t("iterations.precise.label"),
      emoji: "🎯",
      prompt: t("iterations.precise.prompt"),
    },
    {
      label: t("iterations.formal.label"),
      emoji: "👔",
      prompt: t("iterations.formal.prompt"),
    },
    {
      label: t("iterations.informal.label"),
      emoji: "👋",
      prompt: t("iterations.informal.prompt"),
    },
    ...(t("iterations.duzensiezen.enabled") == "true"
      ? [
          {
            label: "Duzen/Siezen",
            emoji: "👥",
            prompt: "Vertausche duzen und siezen",
          },
        ]
      : []),
  ];

  const { chatId } = useParams("/:organizationId/chats/:chatId");
  const enqueueMessage = useQueuedMessagesStore((s) => s.addQueuedMessage);

  return (
    <Stack direction="row" spacing={1}>
      {iterationOptions.map((option) => (
        <ExpandingButton
          minimized={isSmall}
          disabled={disabled}
          key={option.label}
          size="sm"
          variant="outlined"
          startDecorator={
            <span className="-mr-[6px]" role="img" aria-label={option.label}>
              {option.emoji}
            </span>
          }
          onClick={() => {
            enqueueMessage({
              chatId,
              content: option.prompt,
            });
          }}
          label={option.label}
        ></ExpandingButton>
      ))}
    </Stack>
  );
}

interface ExpandingButtonProps extends ButtonProps {
  minimized: boolean;
  label: string;
}

function ExpandingButton({ label, minimized, ...props }: ExpandingButtonProps) {
  const [hovered, setHovered] = useState<boolean>(false);

  return (
    <Button
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{ overflow: "hidden", pr: -4 }}
      {...props}
    >
      <motion.div
        transition={{ duration: 0.15 }}
        initial={minimized ? "hidden" : "visible"}
        animate={!minimized || hovered ? "visible" : "hidden"}
        variants={{
          hidden: { width: 0, opacity: 0 },
          visible: { width: "auto", opacity: 1 },
        }}
      >
        <Typography
          color="primary"
          sx={{
            ml: 1,
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
          level="body-sm"
        >
          {label}
        </Typography>
      </motion.div>
    </Button>
  );
}
