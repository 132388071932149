import { FormControl, Switch, Button } from "@mui/joy";
import { FormLabel, Textarea } from "@mui/joy";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { trpc } from "../../../lib/api/trpc/trpc";
import { Autocomplete } from "./Autocomplete";
import * as Icons from "@mui/icons-material";

export function ModuleCreation({ onClose }: { onClose: () => void }) {
  const create = trpc.modules.createModule.useMutation();
  const utils = trpc.useUtils();

  const [inputs, setInputs] = useState({
    icon: "",
    iconSearch: "",
    title: "",
    description: "",
    price: 0,
    payment: "monthly",
  });

  let priceText = "";
  if (inputs.price !== 0) {
    priceText += inputs.price;
    if (inputs.payment === "monthly") {
      priceText += "m";
    }
  }

  const handleSave = async () => {
    create
      .mutateAsync({
        module: {
          ...inputs,
          price: priceText,
          activated: true,
        },
      })
      .then(() => {
        void utils.modules.invalidate();
        onClose();
        toast.success("Module created");
      })
      .catch(() => {
        toast.error("Error creating module");
      });
  };
  const ICON_KEYS = useMemo(
    () =>
      Object.keys(Icons).filter(
        (key) =>
          key !== "default" &&
          ["Outlined", "TwoTone", "Rounded", "Sharp"].every(
            (v) => !key.includes(v)
          ) &&
          key.toLowerCase().includes(inputs.iconSearch.toLowerCase())
      ),
    [inputs.iconSearch]
  );
  return (
    <div className="flex w-full flex-col gap-5 overflow-y-auto">
      <InputRow
        label="Icon"
        iconKeys={ICON_KEYS}
        value={inputs.iconSearch}
        secondaryValue={inputs.icon}
        onChange={(value) => setInputs({ ...inputs, iconSearch: value })}
        onSecondaryChange={(value) => setInputs({ ...inputs, icon: value })}
      />
      <InputRow
        label="Title"
        value={inputs.title}
        disabled={inputs.icon === ""}
        onChange={(value) => setInputs({ ...inputs, title: value })}
      />
      <InputRow
        label="Description"
        value={inputs.description}
        disabled={inputs.icon === ""}
        onChange={(value) => setInputs({ ...inputs, description: value })}
      />
      <div className="flex w-full items-center gap-4">
        <InputRow
          label="Price"
          value={inputs.price.toString()}
          disabled={inputs.icon === ""}
          isNumber
          onChange={(value) => setInputs({ ...inputs, price: parseInt(value) })}
        />
        <FormControl disabled={inputs.icon === ""}>
          <FormLabel>Monthly</FormLabel>
          <Switch
            checked={inputs.payment === "monthly"}
            onChange={() => {
              setInputs({
                ...inputs,
                payment: inputs.payment == "monthly" ? "one-time" : "monthly",
              });
            }}
          />
        </FormControl>
      </div>

      <Button
        disabled={!inputs.description || !inputs.title || !inputs.icon}
        onClick={handleSave}
      >
        Save
      </Button>
    </div>
  );
}

function InputRow({
  label,
  value,
  secondaryValue,
  onChange,
  disabled,
  iconKeys,
  onSecondaryChange,
  isNumber,
}: {
  label: string;
  value: string;
  secondaryValue?: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  iconKeys?: string[];
  onSecondaryChange?: (value: string) => void;
  isNumber?: boolean;
}) {
  return (
    <FormControl disabled={disabled} sx={{ flex: 1 }}>
      <FormLabel>{label}</FormLabel>
      {iconKeys && onSecondaryChange ? (
        <Autocomplete
          options={iconKeys}
          onChange={(search) => onChange(search)}
          value={value}
          iconValue={secondaryValue}
          onIconChange={onSecondaryChange}
        />
      ) : (
        <Textarea
          value={value}
          sx={{ flex: 1 }}
          onChange={(e) => {
            console.log(isNumber && e.target.value === "");
            if (isNumber && e.target.value === "") {
              onChange("0");
              return;
            }
            onChange(e.target.value);
          }}
          disabled={disabled}
          slotProps={
            isNumber
              ? {
                  textarea: {
                    component: "input",
                    type: "number",
                    min: 0,
                  },
                }
              : {}
          }
        />
      )}
    </FormControl>
  );
}
