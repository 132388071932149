import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, IconButton, Table } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import type { KnowledgeCollection } from "../../../../../backend/src/api/rag/knowledgeCollection/knowledgeCollectionTypes.ts";
import { useCurrentOrganizationId } from "../../../lib/api/trpc/helpers/useCurrentOrganizationId.ts";
import { useTranslateTrpcError } from "../../../lib/api/trpc/helpers/useTranslateTrpcError.ts";
import { trpc } from "../../../lib/api/trpc/trpc.ts";
import { useNavigate } from "../../../router.ts";
import { DelayedLoader } from "../../util/DelayadLoader.tsx";
import { SettingsPage } from "../SettingsPage.tsx";

function KnowledgeBaseRow({
  collection,
  onEdit,
  onDelete,
}: {
  collection: KnowledgeCollection;
  onEdit: VoidFunction;
  onDelete: VoidFunction;
}) {
  return (
    <tr>
      <td style={{ width: "min-content" }}>{collection.name}</td>
      <td>{collection.description}</td>
      <td>
        <div className="flex items-center justify-between">
          {collection.dataConnectors.length}
          <div>
            <IconButton onClick={onEdit}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      </td>
    </tr>
  );
}

export default function RAGSettings() {
  const organizationId = useCurrentOrganizationId();
  const { t } = useTranslation();
  const trpcUtils = trpc.useUtils();
  const translateError = useTranslateTrpcError();
  const navigate = useNavigate();

  const { data: knowledgeCollections, isLoading } =
    trpc.rag.knowledgeCollections.admin.getAll.useQuery();

  const { mutateAsync: deleteKnowledgeCollection } =
    trpc.rag.knowledgeCollections.admin.delete.useMutation();

  const { mutateAsync: createKnowledgeCollection } =
    trpc.rag.knowledgeCollections.admin.create.useMutation();

  async function onDelete(id: string) {
    try {
      await deleteKnowledgeCollection({
        knowledgeCollectionId: id,
      });
      toast.success(t("knowledgeBase.deleted"));
      void trpcUtils.rag.knowledgeCollections.invalidate();
    } catch (e) {
      console.error(e);
      toast.error(
        t("knowledgeBase.deleteFailed", { error: translateError(e) })
      );
    }
  }

  return (
    <SettingsPage
      title={t("knowledgeBase.title")}
      actions={
        <Button
          onClick={() => {
            void createKnowledgeCollection({
              name: "Neuer Datenpool",
            }).then((pool) => {
              navigate("/:organizationId/settings/dataPool/:id", {
                params: {
                  organizationId,
                  id: pool.id,
                },
              });
            });
          }}
        >
          {t("knowledgeBase.create")}
        </Button>
      }
    >
      <Table hoverRow sx={{ tableLayout: "auto" }}>
        <thead>
          <tr>
            <th>{t("name")}</th>
            <th>{t("description")}</th>
            <th>Quellen</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={3} className="mx-10">
                <DelayedLoader />
              </td>
            </tr>
          )}
          {knowledgeCollections?.map((collection) => (
            <KnowledgeBaseRow
              collection={collection}
              key={collection.id}
              onEdit={() => {
                navigate("/:organizationId/settings/dataPool/:id", {
                  params: {
                    organizationId,
                    id: collection.id,
                  },
                });
              }}
              onDelete={() => {
                if (
                  confirm(
                    t("knowledgeBase.confirmDelete", { name: collection.name })
                  )
                ) {
                  void onDelete(collection.id);
                }
              }}
            />
          ))}
        </tbody>
      </Table>
    </SettingsPage>
  );
}
