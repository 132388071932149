import { Card, CardContent, Typography } from "@mui/joy";
import { useEffect } from "react";
import { MessagesBlock } from "../../../components/elearning/MessagesBlock.tsx";
import {
  useCaptureAnalyticsEvent,
  useCourse,
  useExercise,
} from "../../../lib/api/learning.ts";
import { useParams } from "../../../router.ts";
import "./course-layout.css";
import { useTranslation } from "react-i18next";
import ExerciseOverview from "../../../components/elearning/ExerciseOverview.tsx";
import { SlateRenderer } from "../../../components/elearning/SlateRenderer.tsx";
import { Box } from "@mui/system";
import { LiveWorkshopCard } from "../../../components/elearning/LiveWorkshopCard.tsx";
import { twMerge } from "tailwind-merge";
import { useOrganization } from "../../../lib/api/organization.ts";

export default function ExerciseView() {
  const params = useParams(
    "/:organizationId/learn/:workshopId/course/:courseId/exercise/:exerciseId"
  );
  const exercise = useExercise(params.courseId, params.exerciseId);
  const organization = useOrganization();
  const course = useCourse(params.courseId);

  const isWorkshop = params.workshopId !== "none";
  const isLive =
    isWorkshop && params.workshopId !== organization?.defaultWorkshopId;

  const { t } = useTranslation();

  const captureAnalyticsEvent = useCaptureAnalyticsEvent();

  useEffect(() => {
    if (params.exerciseId) {
      void captureAnalyticsEvent("EXERCISE_STARTED");
    }
  }, [params.courseId, params.exerciseId, params.workshopId]);

  return (
    <div className="course-layout-stacked lg:course-layout-medium 2xl:course-layout-large flex-grow basis-0 gap-x-8 self-stretch 2xl:min-h-[600px]">
      <Box
        className={twMerge(
          "col-span-full flex w-full flex-col-reverse justify-between self-stretch",
          !isLive && "xl:mr-auto",
          isLive && "gap-2 lg:flex-row"
        )}
      >
        {course && exercise && (
          <ExerciseOverview
            className="md:min-w-[590px]"
            exercises={course.exercises.sort((a, b) => a.order - b.order)}
            exerciseInView={exercise}
          />
        )}

        {isLive && <LiveWorkshopCard workshopId={params.workshopId} />}
      </Box>

      <Typography
        level="body-lg"
        color="neutral"
        mb={0.5}
        ml={0.5}
        mt={2}
        style={{ gridArea: "content-title" }}
      >
        {t("task")}
      </Typography>
      <Card className="overflow-hidden" sx={{ gridArea: "content" }}>
        <CardContent className="overflow-auto" sx={{ pr: 1 }}>
          <SlateRenderer content={exercise?.exerciseDescription ?? []} />
        </CardContent>
      </Card>
      {exercise && (
        <MessagesBlock
          exercise={exercise}
          courseId={params.courseId}
          key={exercise.id}
        />
      )}
    </div>
  );
}
