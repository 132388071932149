import {
  Autorenew,
  ChevronLeft,
  ContentCopy,
  Delete,
  Download,
} from "@mui/icons-material";
import CachedIcon from "@mui/icons-material/Cached";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  Card,
  CircularProgress,
  Divider,
  IconButton,
  Input,
  Sheet,
  Typography,
} from "@mui/joy";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useDebounceValue } from "usehooks-ts";
import type { Transcript } from "../../../../../../backend/src/api/tools/meetingRecorder/meetingRecorderTypes";
import { MarkdownRenderer } from "../../../../components/chat/MarkdownRenderer";
import { trpc } from "../../../../lib/api/trpc/trpc";
import { useCopySafe } from "../../../../lib/hooks/useCopySafe";
import { Link, useNavigate, useParams } from "../../../../router";
import { MeetingDate } from "./_components/MeetingDate.tsx";
import { MeetingDuration } from "./_components/meetingDuration.tsx";

export default function MeetingTranscriptPage() {
  const navigate = useNavigate();
  const { meetingId, organizationId } = useParams(
    "/:organizationId/tools/meetingRecorder/:meetingId"
  );

  const { data: meeting } = trpc.tools.meetingRecorder.get.useQuery(
    { meetingId },
    {
      refetchInterval: (data) => {
        return data.state.data?.status === "TRANSCRIBING" ? 1000 : false;
      },
    }
  );

  const [retryingTranscription, setRetryingTranscription] = useState(false);

  const { mutateAsync: retryTranscription } =
    trpc.tools.meetingRecorder.startTranscription.useMutation();

  const { mutateAsync: deleteMeeting, isPending: deletePending } =
    trpc.tools.meetingRecorder.delete.useMutation();

  const trpcUtils = trpc.useUtils();

  async function confirmDelete() {
    if (confirm("Möchtest du das Meeting wirklich löschen?")) {
      await deleteMeeting({ meetingId });
      navigate(`/:organizationId/tools/meetingRecorder`, {
        params: { organizationId },
      });
    }
  }

  return (
    <Sheet variant="soft" className="min-h-screen">
      <div className="flex flex-col justify-center gap-8 p-20">
        <div className="grid grid-cols-[min-content_auto_min-content] gap-1 gap-y-3 ">
          <IconButton
            variant="plain"
            color="neutral"
            to="/:organizationId/tools/meetingRecorder"
            params={useParams("/:organizationId")}
            component={Link}
            sx={{ pl: 0 }}
          >
            <ChevronLeft sx={{ fontSize: 32 }} />
          </IconButton>
          <Typography level="h2">{meeting?.name}</Typography>
          <Typography className="col-start-2 line-clamp-3">
            {meeting?.summary}
          </Typography>
          {meeting ? (
            <div className="col-start-2 flex gap-3">
              <MeetingDate date={meeting.date} />
              <MeetingDuration duration={meeting.duration} />
            </div>
          ) : null}
          {meeting &&
          ["DONE", "FAILED", "TRANSCRIBING"].includes(meeting.status) ? (
            <IconButton
              className="col-start-3 row-start-1"
              onClick={confirmDelete}
              loading={deletePending}
            >
              <Delete />
            </IconButton>
          ) : null}
        </div>
        {meeting?.status === "FAILED" && (
          <div className="flex flex-col items-start gap-4">
            <Typography level="body-lg">
              Transkription fehlgeschlagen. Bitte versuche es erneut.
            </Typography>
            <div>
              <Button
                sx={{ mr: 2 }}
                startDecorator={<CachedIcon />}
                disabled={deletePending}
                loading={retryingTranscription}
                onClick={async () => {
                  // FIXME: Workaround for race condition - fix properly by returing from the mutation in the router as soon as the DB state is updated
                  setRetryingTranscription(true);
                  void retryTranscription({ meetingId });
                  setTimeout(() => {
                    void trpcUtils.tools.meetingRecorder.invalidate();
                    setRetryingTranscription(false);
                  }, 2000);
                }}
              >
                Erneut versuchen
              </Button>
              <Button
                startDecorator={<Delete />}
                onClick={confirmDelete}
                loading={deletePending}
                disabled={retryingTranscription}
              >
                Meeting löschen
              </Button>
            </div>
          </div>
        )}
        {meeting?.status == "TRANSCRIBING" && (
          <div className="mr-auto flex flex-col space-y-4">
            <div className="flex flex-row items-center gap-4">
              <CircularProgress size="sm" />
              <Typography>Deine Aufnahme wird transkribiert...</Typography>
            </div>
            <Button startDecorator={<Delete />} onClick={confirmDelete}>
              Meeting löschen
            </Button>
          </div>
        )}
        {meeting?.transcript ? (
          <TranscriptViewer
            transcript={meeting?.transcript}
            meetingId={meetingId}
            storedSpeakerNames={meeting?.speakerNames ?? {}}
          />
        ) : null}
      </div>
    </Sheet>
  );
}

function SpeakerEditor({
  numSpeakers,
  speakerNames,
  setSpeakerName,
}: {
  numSpeakers: number;
  speakerNames: Record<number, string>;
  setSpeakerName: (speaker: number, name: string) => void;
}) {
  return (
    <div className="flex flex-col gap-2 py-3">
      {Array.from({ length: numSpeakers }).map((_, i) => (
        <Input
          key={i}
          type="text"
          placeholder={`Sprecher ${i + 1}`}
          onChange={(e) => {
            setSpeakerName(i, e.target.value ?? null);
          }}
          value={speakerNames[i] ?? ""}
        />
      ))}
    </div>
  );
}

function ProtocolButtons({
  meetingId,
  onStartProtocolGeneration,
}: {
  meetingId: string;
  onStartProtocolGeneration: () => void;
}) {
  const { data: meetingStatus } = trpc.tools.meetingRecorder.getStatus.useQuery(
    { meetingId },
    {
      refetchInterval(data) {
        return data.state.data?.status === "SUMMARY_GENERATION" ? 1000 : false;
      },
    }
  );

  const { mutateAsync: getDownloadLink, isPending } =
    trpc.tools.meetingRecorder.createProtocolDownloadLink.useMutation();

  if (meetingStatus == null) {
    return null;
  } else if (meetingStatus.hasProtocol) {
    return (
      <ButtonGroup color="primary" variant="solid">
        <Button
          loading={isPending}
          startDecorator={<Download />}
          onClick={async () => {
            const url = await getDownloadLink({ meetingId });
            const link = document.createElement("a");
            link.href = url;
            link.download = `Protokoll.docx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }}
        >
          Protokoll herunterladen
        </Button>
        <IconButton
          onClick={() => {
            if (confirm("Protokoll neu erstellen?")) {
              onStartProtocolGeneration();
            }
          }}
        >
          <Autorenew />
        </IconButton>
      </ButtonGroup>
    );
  } else {
    return (
      <Button
        size="sm"
        loading={meetingStatus.status === "SUMMARY_GENERATION"}
        onClick={onStartProtocolGeneration}
      >
        Protokoll erstellen
      </Button>
    );
  }
}

function TranscriptViewer({
  transcript,
  meetingId,
  storedSpeakerNames,
}: {
  transcript: Transcript;
  meetingId: string;
  storedSpeakerNames: Record<number, string>;
}) {
  const [speakerNames, setSpeakerNames] =
    useState<Record<number, string>>(storedSpeakerNames);
  const [speakerNamesDebounced] = useDebounceValue(speakerNames, 500);
  const { markdown, numSpeakers } = useMemo(
    () => asMarkdown(transcript, speakerNamesDebounced),
    [transcript, speakerNamesDebounced]
  );

  const { mutateAsync: startProtocolGeneration } =
    trpc.tools.meetingRecorder.startProtocolGeneration.useMutation();
  const {
    mutateAsync: updateSpeakerNames,
    isPending: speakerNameUpdatePending,
  } = trpc.tools.meetingRecorder.updateSpeakerNames.useMutation();

  const trpcUtils = trpc.useUtils();

  const copy = useCopySafe();

  const speakerNamesChanged =
    JSON.stringify(speakerNames) !== JSON.stringify(storedSpeakerNames);

  return (
    <Card>
      <div className=" flex flex-row items-center justify-between gap-4 ">
        <Typography level="h3">Transkript</Typography>
        <div className="flex items-center gap-2">
          <Button
            size="sm"
            variant="outlined"
            startDecorator={<ContentCopy />}
            onClick={() => {
              copy(markdown);
            }}
          >
            Kopieren
          </Button>
          <ProtocolButtons
            meetingId={meetingId}
            onStartProtocolGeneration={async () => {
              await startProtocolGeneration({ meetingId, speakerNames });
              await trpcUtils.tools.meetingRecorder.invalidate();
            }}
          />
        </div>
      </div>
      <div className="max-w-lg">
        <Accordion variant="plain">
          <AccordionSummary>Sprecher umbenennen</AccordionSummary>
          <AccordionDetails>
            <SpeakerEditor
              numSpeakers={numSpeakers}
              speakerNames={speakerNames}
              setSpeakerName={(speaker, name) =>
                setSpeakerNames((prev) => ({
                  ...prev,
                  [speaker]: name,
                }))
              }
            />
            <Button
              className="self-end"
              loading={speakerNameUpdatePending}
              disabled={!speakerNamesChanged}
              onClick={async () => {
                await updateSpeakerNames({ meetingId, speakerNames });
                toast.success("Sprecher-Namen gespeichert");
                await trpcUtils.tools.meetingRecorder.invalidate();
              }}
            >
              Speichern
            </Button>
          </AccordionDetails>
        </Accordion>
      </div>
      <Divider inset="none" />
      <MarkdownRenderer content={markdown} />
    </Card>
  );
}

function combineBlocks(transcript: Transcript) {
  if (!transcript?.blocks?.length) return [];

  let currentSpeaker = -1;
  const combinedBlocks: Array<{ speaker: number; content: string }> = [];

  for (const phrase of transcript.blocks) {
    if (phrase.speaker !== currentSpeaker) {
      currentSpeaker = phrase.speaker;
      combinedBlocks.push({
        speaker: phrase.speaker,
        content: phrase.content,
      });
    } else {
      combinedBlocks[combinedBlocks.length - 1].content += " " + phrase.content;
    }
  }

  return combinedBlocks;
}

function countSpeakers(
  combinedBlocks: Array<{ speaker: number; content: string }>
) {
  const speakerSet = new Set<number>();
  for (const block of combinedBlocks) {
    speakerSet.add(block.speaker);
  }
  return speakerSet.size;
}

function blocksToMarkdown(
  combinedBlocks: Array<{ speaker: number; content: string }>,
  speakerNames: Record<number, string> = {}
) {
  let markdown = "";

  for (const block of combinedBlocks) {
    const name = speakerNames[block.speaker] || `Sprecher ${block.speaker + 1}`;
    markdown += `**${name.trim()}**: ${block.content}\n\n`;
  }

  return markdown;
}

function asMarkdown(
  transcript: Transcript,
  speakerNames: Record<number, string> = {}
) {
  const combinedBlocks = combineBlocks(transcript);
  const numSpeakers = countSpeakers(combinedBlocks);
  const markdown = blocksToMarkdown(combinedBlocks, speakerNames);

  return {
    markdown,
    numSpeakers,
  };
}
