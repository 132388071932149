import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Chip, IconButton, Table } from "@mui/joy";
import { useState } from "react";
import { toast } from "react-toastify";
import type {
  Department,
  UpdateDepartmentInput,
} from "../../../../../backend/src/api/organization/department/departmentTypes.ts";
import { trpc } from "../../../lib/api/trpc/trpc.ts";
import { useTranslation } from "../../../lib/i18n";
import { DelayedLoader } from "../../util/DelayadLoader";
import { SettingsPage } from "../SettingsPage.tsx";
import { DepartmentEditor } from "./DepartmentEditor";

export function DepartmentsEditor() {
  const { t } = useTranslation();

  const { data: departments } = trpc.organization.department.admin.useQuery();
  const utils = trpc.useUtils();

  const { mutateAsync: createDepartment } =
    trpc.organization.department.create.useMutation();

  const { data: entraIdEnabled } =
    trpc.productConfig.entraId.isEnabled.useQuery();

  if (!departments) return <DelayedLoader />;

  return (
    <SettingsPage
      title={t("department.plural")}
      subtitle={t("department.description")}
      actions={
        <Button
          onClick={async () => {
            await createDepartment({});
            await utils.organization.department.invalidate();
            toast.success("Neue Abteilung erstellt");
          }}
        >
          {t("department.create")}
        </Button>
      }
    >
      <Table>
        <thead>
          <tr>
            <th>{t("name")}</th>
            <th>{t("members")}</th>
            {entraIdEnabled && <th>{t("department.assignedEntraGroups")}</th>}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {departments.map((department) => (
            <DepartmentListItem
              key={department.id}
              department={department}
              entraIdEnabled={entraIdEnabled}
            />
          ))}
        </tbody>
      </Table>
    </SettingsPage>
  );
}

function DepartmentListItem({
  department,
  entraIdEnabled = false,
}: {
  department: Department;
  entraIdEnabled?: boolean;
}) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const { mutateAsync: updateDepartment } =
    trpc.organization.department.update.useMutation();
  const { mutateAsync: deleteDepartment } =
    trpc.organization.department.delete.useMutation();

  const { mutateAsync: mutateContactInfo } =
    trpc.contactInfo.mutateDepartmentContactInfo.useMutation();
  const { mutateAsync: deleteContactInfo } =
    trpc.contactInfo.deleteDepartmentContactInfo.useMutation();
  const utils = trpc.useUtils();

  async function onSubmit(value: Omit<UpdateDepartmentInput, "departmentId">) {
    try {
      await updateDepartment({
        ...value,
        departmentId: department.id,
      });
      value.default
        ? await deleteContactInfo({
            departmentId: department.id,
          })
        : await mutateContactInfo({
            departmentId: department.id,
            ...value.contactInfo,
          });

      toast.success(t("department.saved"));
      await utils.organization.department.invalidate();
      await utils.contactInfo.invalidate();
    } catch (err) {
      console.error(err);
      toast.error(t("department.saveFailed"));
    }
    setOpen(false);
  }

  function onDelete() {
    deleteDepartment({ departmentId: department.id })
      .then(() => {
        toast.success(t("department.deleted"));
        return Promise.all([
          utils.organization.department.invalidate(),
          deleteContactInfo({
            departmentId: department.id,
          }),
        ]);
      })
      .catch((err) => {
        console.error(err);
        toast.error(t("department.deleteFailed"));
      });
  }

  if (!department) return <DelayedLoader />;

  return (
    <>
      <tr>
        <td>{department.name}</td>
        <td>
          {department.default
            ? t("department.visibleForAll")
            : department.userCount}
        </td>
        {entraIdEnabled ? (
          <td>
            {department.isPersonal ||
            !department.entraGroups ||
            department.entraGroups.length == 0
              ? "—"
              : department.entraGroups?.map((group) => (
                  <Chip key={group.id} variant="outlined" sx={{ mr: 1, mb: 1 }}>
                    {group.displayName}
                  </Chip>
                ))}
          </td>
        ) : null}
        <td>
          <div className="flex flex-row justify-end">
            <IconButton
              onClick={() => setOpen(true)}
              className="align-right"
              variant="soft"
            >
              <EditIcon />
            </IconButton>
            {department.id && (
              <IconButton onClick={onDelete} color="danger" variant="plain">
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        </td>
      </tr>
      <DepartmentEditor
        defaultValues={department}
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={onSubmit}
        entraIdEnabled={entraIdEnabled}
      />
    </>
  );
}
