import {
  DeleteForever,
  Edit,
  MoreVert,
  PlayCircle,
  Star,
  StarBorder,
} from "@mui/icons-material";
import {
  Dropdown,
  IconButton,
  ListDivider,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuItem,
} from "@mui/joy";
import { skipToken } from "@tanstack/react-query";
import React, { useState } from "react";
import { toast } from "react-toastify";
import type { WorkflowStep } from "../../../../../backend/src/api/workflow/workflowTypes.ts";

import { trpc } from "../../../lib/api/trpc/trpc.ts";
import { useQueuedMessagesStore } from "../../../lib/context/queuedMessagesStore";
import { handleGenericError } from "../../../lib/errorHandling.tsx";
import { useTranslation } from "../../../lib/i18n";
import { useNavigate, useParams } from "../../../router";
import { replaceAll } from "../../util/polyfill";
import { AreYouSure, TreeItem } from "./LeafItem";
import { RunWorkflowModal } from "./RunWorkflowModal.tsx";

export function WorkflowItem({ workflowId }: { workflowId: string }) {
  const { t } = useTranslation();

  const { data: workflow } = trpc.workflows.getById.useQuery({
    workflow: { id: workflowId },
  });

  const navigate = useNavigate();

  const { mutateAsync: deleteWorkflow } = trpc.workflows.delete.useMutation();
  const { mutateAsync: createChat } = trpc.chat.create.useMutation();
  const { data: favoriteWorkflows } = trpc.workflows.favorites.useQuery();
  const utils = trpc.useUtils();
  const isFavorite =
    favoriteWorkflows?.some((wf) => wf.id === workflowId) ?? false;
  const { mutateAsync: toggleFavorite } =
    trpc.workflows.toggleFavorite.useMutation();
  const params = useParams("/:organizationId/workflows/:workflowId");
  const { data: department } = trpc.organization.department.byId.useQuery(
    workflow?.departmentId
      ? {
          departmentId: workflow?.departmentId,
        }
      : skipToken
  );

  const onDelete = async () => {
    await deleteWorkflow({
      workflow: { id: workflowId },
    });
    await utils.organization.department.invalidate();
    await utils.workflows.invalidate();

    toast.success(t("workflowDeleted"));

    if (params?.workflowId === workflowId) {
      navigate("/:organizationId", {
        params: { organizationId: params.organizationId },
      });
    }
  };

  const { data: documentIntelligenceEnabled } =
    trpc.tools.documentIntelligence.isEnabled.useQuery();

  const hasInputs =
    (workflow?.inputs?.length ?? 0) > 0 ||
    (workflow?.allowDocumentUpload && documentIntelligenceEnabled);

  const [modalOpen, setModalOpen] = useState(false);

  const [areYouSureOpen, setAreYouSureOpen] = useState(false);

  const { mutateAsync: trackUsage } =
    trpc.workflows.trackWorkflowExecution.useMutation();
  const enqueueMessage = useQueuedMessagesStore((s) => s.addQueuedMessage);
  const clearMessageQueue = useQueuedMessagesStore((s) => s.clear);

  if (!workflow) return;

  const hasSteps = workflow.steps.length > 0;

  const onInit = async (
    values: Record<string, string>,
    attachmentIds: string[]
  ) => {
    setModalOpen(false);

    attachmentIds = [
      ...new Set([
        ...attachmentIds,
        ...(workflow.includedDocuments ?? []).map((doc) => doc.id),
      ]),
    ];

    const chat = await createChat({
      name: workflow.name,
      modelOverride: workflow.modelOverride,
    });

    void utils.chat.invalidate();

    trackUsage({
      workflow: { id: workflowId },
    }).catch(console.error);

    navigate("/:organizationId/chats/:chatId", {
      params: {
        organizationId: params.organizationId,
        chatId: chat.id,
      },
    });

    if (hasSteps) {
      clearMessageQueue();
      workflow.steps.forEach((step: WorkflowStep, index: number) => {
        let { promptTemplate: content } = step;
        const { modelOverride } = step;

        // Idea: replace with handlebars
        if (content.length > 0) {
          for (const input of workflow.inputs ?? []) {
            content = replaceAll(
              content,
              `{{${input.key}}}`,
              values[input.key]
            );
          }
          enqueueMessage({
            content,
            chatId: chat.id,
            attachmentIds: index === 0 ? attachmentIds : undefined,
            modelOverride:
              modelOverride ?? workflow.modelOverride ?? "gpt-4o-mini",
          });
        }
      });
    }

    toast.success(t("workflowExecuted"));
  };

  const onClick = () => {
    if (hasInputs) {
      setModalOpen(true);
    } else {
      onInit({}, []).catch(console.error);
    }
  };

  return (
    <>
      <TreeItem
        selected={false}
        icon={<PlayCircle fontSize="small" />}
        name={workflow.name}
        onClick={onClick}
        endDecorator={
          <div onClick={(e) => e.stopPropagation()}>
            <Dropdown>
              <MenuButton
                size="sm"
                slots={{ root: IconButton }}
                slotProps={{ root: { size: "sm", color: "neutral" } }}
              >
                <MoreVert />
              </MenuButton>
              <Menu size="sm" placement="bottom-start">
                <MenuItem
                  disabled={!department?.writePermission}
                  onClick={() => {
                    navigate("/:organizationId/workflows/:workflowId", {
                      params: {
                        organizationId: params.organizationId,
                        workflowId: workflowId,
                      },
                    });
                  }}
                >
                  <ListItemDecorator>
                    <Edit />
                  </ListItemDecorator>
                  {t("edit")}
                </MenuItem>
                <MenuItem
                  onClick={async () => {
                    await toggleFavorite({
                      workflow: { id: workflowId },
                    })
                      .then(() => utils.workflows.favorites.invalidate())
                      .catch((e) => handleGenericError(e, undefined));
                  }}
                >
                  <ListItemDecorator>
                    {isFavorite ? <Star /> : <StarBorder />}
                  </ListItemDecorator>
                  {t(isFavorite ? "removeFromFavorites" : "markAsFavorite")}
                </MenuItem>
                <ListDivider />
                <MenuItem
                  color="danger"
                  disabled={!department?.writePermission}
                  onClick={(event) => {
                    event.stopPropagation();
                    setAreYouSureOpen(true);
                  }}
                >
                  <ListItemDecorator sx={{ color: "inherit" }}>
                    <DeleteForever />
                  </ListItemDecorator>
                  {t("delete")}
                </MenuItem>
              </Menu>
            </Dropdown>
          </div>
        }
      />
      {hasInputs && hasSteps && (
        <RunWorkflowModal
          workflow={workflow}
          open={modalOpen}
          setOpen={setModalOpen}
          onSubmit={onInit}
        />
      )}
      <AreYouSure
        open={areYouSureOpen}
        onClose={() => setAreYouSureOpen(false)}
        onSure={onDelete}
      />
    </>
  );
}
