import { Button, ButtonGroup } from "@mui/joy";
import { TranslationError } from "../../TranslationError";
import { useTranslation } from "react-i18next";
import { Replay, UploadFile } from "@mui/icons-material";
import { ActionButtons } from "../ActionButtons";
import { type TranslationState } from "../DocumentPanel";

export function DocumentTranslationError({
  message,
  handleTranslation,
  resetMutation,
  setFile,
  setTranslationState,
}: {
  message: string | undefined;
  handleTranslation: () => void;
  resetMutation: () => void;
  setFile: (file: File | undefined) => void;
  setTranslationState: (state: TranslationState) => void;
}) {
  const { t } = useTranslation();
  const handleNewDocument = () => {
    resetMutation();
    setFile(undefined);
    setTranslationState("READY");
  };

  return (
    <>
      <TranslationError message={message} />
      <ActionButtons>
        <ButtonGroup>
          <Button onClick={handleTranslation} startDecorator={<Replay />}>
            {t("tryAgain")}
          </Button>
          <Button startDecorator={<UploadFile />} onClick={handleNewDocument}>
            {t("documents.uploadNewDocument")}
          </Button>
        </ButtonGroup>
      </ActionButtons>
    </>
  );
}
