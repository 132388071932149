import { useEffect } from "react";
import { useNavigate } from "../router";
import { useEnv } from "../lib/api/env";

export default function Home() {
  const navigate = useNavigate();
  const env = useEnv();

  useEffect(() => {
    if (!env) return;

    console.log("env", env);
    if (env.IS_WORKSHOP_ONLY_TENANT) {
      navigate("/workshops/join");
    } else {
      navigate("/auth");
    }
  }, [navigate, env]);
}
