import { AccessTime } from "@mui/icons-material";
import { Typography } from "@mui/joy";

export function MeetingDuration({ duration }: { duration: number }) {
  let formattedDuration = "-";

  if (duration != 0) {
    const hrs = Math.floor(duration / 3600);
    const mins = Math.floor((duration % 3600) / 60);
    const secs = duration % 60;

    const parts: string[] = [];
    if (hrs > 0) parts.push(hrs.toString().padStart(2, "0"));
    if (mins > 0 || hrs > 0) parts.push(mins.toString().padStart(2, "0"));
    parts.push(secs.toString().padStart(2, "0"));

    formattedDuration = parts.join(":");
  }

  return (
    <Typography level="body-sm" sx={{ display: "flex", alignItems: "center" }}>
      <AccessTime sx={{ fontSize: 14, mr: 0.5 }} />
      {formattedDuration}
    </Typography>
  );
}
