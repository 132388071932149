import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Switch,
  Typography,
} from "@mui/joy";
import { skipToken } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { ContactInfo } from "../../../../../backend/src/api/organization/contactInfo/contactInfoTypes.ts";
import type {
  Department,
  UpdateDepartmentInput,
} from "../../../../../backend/src/api/organization/department/departmentTypes.ts";
import { trpc } from "../../../lib/api/trpc/trpc.ts";
import { useTranslation } from "../../../lib/i18n";
import { HelpCenterInputs } from "../HelpCenterInputs.tsx";
import { DepartmentUserEditor } from "./DepartmentUserEditor";

export function DepartmentEditor({
  defaultValues,
  onSubmit,
  open,
  onClose,
  entraIdEnabled = false,
}: {
  defaultValues: Partial<Department>;
  onSubmit: (v: Omit<UpdateDepartmentInput, "departmentId">) => void;
  open: boolean;
  onClose: () => void;
  entraIdEnabled?: boolean;
}) {
  const { t } = useTranslation();

  const [name, setName] = useState(defaultValues.name ?? "");
  const [contactInfo, setContactInfo] = useState<ContactInfo>({
    name: "",
    email: "",
    phone: "",
    additionalInfo: "",
  });
  const valid =
    ContactInfo.safeParse({ email: contactInfo.email }).success &&
    ContactInfo.safeParse({ phone: contactInfo.phone }).success;
  const [isDefault, setIsDefault] = useState(defaultValues.default);
  const [selectedGroups, setSelectedGroups] = useState(
    defaultValues.entraGroups ?? []
  );
  const { data: groups } = trpc.organization.entra.groups.useQuery(
    entraIdEnabled ? undefined : skipToken
  );

  const departmentId = defaultValues.id ?? "";

  const { data } = trpc.contactInfo.getDepartmentContactInfo.useQuery(
    departmentId
      ? {
          departmentId,
        }
      : skipToken
  );

  useEffect(() => {
    if (data) {
      setContactInfo(data);
    }
  }, [data]);

  return (
    <Modal
      open={open}
      onClose={(e: React.MouseEvent<HTMLButtonElement>, reason: string) => {
        if (reason !== "backdropClick") onClose();
      }}
      disableEscapeKeyDown
    >
      <ModalDialog sx={{ overflowY: "auto" }}>
        <ModalClose />
        <div
          className="flex flex-col gap-10"
          style={{
            width: "calc(min(80vw, 1200px))",
          }}
        >
          <Typography level="title-lg">{t("department.edit")}</Typography>
          <div className="flex flex-col gap-4">
            <FormControl>
              <FormLabel>{t("name")}</FormLabel>
              <Input
                value={name as string}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("department.visibleForAll")}</FormLabel>
              <Switch
                sx={{ alignSelf: "start" }}
                checked={isDefault as boolean}
                onChange={(e) => setIsDefault(e.target.checked)}
              />
            </FormControl>
            {!isDefault && (
              <>
                <Typography level="title-md" mt={3}>
                  {t("supportContact")}
                </Typography>
                <Typography color="neutral" mb={1}>
                  {t("supportContactInfo")}
                </Typography>
                <HelpCenterInputs
                  contactInfo={contactInfo}
                  setContactInfo={setContactInfo}
                  level="title-sm"
                />
              </>
            )}
            {entraIdEnabled && !defaultValues.isPersonal && !isDefault ? (
              <FormControl>
                <FormLabel>{t("department.assignedEntraGroups")}</FormLabel>
                <Autocomplete
                  multiple
                  placeholder={
                    selectedGroups.length == 0
                      ? t("department.selectEntraGroups")
                      : ""
                  }
                  options={groups ?? []}
                  getOptionLabel={(option) => option.displayName}
                  getOptionKey={(option) => option.id}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={selectedGroups}
                  onChange={(e, value) => setSelectedGroups(value)}
                />
                <FormHelperText>
                  {t("department.assignedEntraGroupsHelp")}
                </FormHelperText>
              </FormControl>
            ) : null}
          </div>
          <DepartmentUserEditor departmentId={defaultValues.id!} />
          <Stack direction="row" justifyContent="end">
            <Button
              disabled={!valid}
              onClick={() =>
                onSubmit({
                  name: name ?? "",
                  default: isDefault ?? false,
                  entraGroupsIds: isDefault
                    ? []
                    : selectedGroups?.map((g) => g.id) ?? [],
                  contactInfo,
                })
              }
            >
              {t("saveChanges")}
            </Button>
          </Stack>
        </div>
      </ModalDialog>
    </Modal>
  );
}
