import { TRPCClientError } from "@trpc/client";
import { useTranslation } from "react-i18next";

export function useTranslateTrpcError() {
  const { t } = useTranslation();

  return (error: unknown) => {
    if (error instanceof TRPCClientError) {
      return t(`trpcErrorCodes.${error.data.code}`);
    }
    return t("trpcErrorCodes.UNKNOWN_ERROR");
  };
}
