import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { twMerge } from "tailwind-merge";
export function CarouselControllers({
  imageUrls,
  imageIndex,
  setImageIndex,
  className,
}: {
  imageUrls: string[];
  imageIndex: number;
  setImageIndex: (infer: number) => void;
  className?: string;
}) {
  if (imageUrls.length < 2) return null;
  return (
    <div
      className={twMerge(
        "absolute top-1/2 flex w-full -translate-y-1/2 justify-between",
        className
      )}
    >
      <NavigateBefore
        className={twMerge(
          imageIndex === 0
            ? "opacity-20"
            : "cursor-pointer opacity-70 hover:opacity-100"
        )}
        sx={{
          fontSize: 50,
          color: "white",
        }}
        onClick={(e) => {
          e.stopPropagation();
          setImageIndex(imageIndex > 0 ? imageIndex - 1 : 0);
        }}
      />

      <NavigateNext
        className={
          imageIndex < imageUrls.length - 1
            ? "cursor-pointer opacity-70 hover:opacity-100"
            : "opacity-20"
        }
        sx={{
          color: "white",
          fontSize: 50,
        }}
        onClick={(e) => {
          e.stopPropagation();
          setImageIndex(
            imageIndex < imageUrls.length - 1
              ? imageIndex + 1
              : imageUrls.length - 1
          );
        }}
      />
    </div>
  );
}
