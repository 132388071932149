import { Button, Card, Input, Stack, Typography } from "@mui/joy";
import { Link, useNavigate } from "../../router";
import { ArrowForward } from "@mui/icons-material";
import { useTranslation } from "../../lib/i18n";
import { trpc } from "../../lib/api/trpc/trpc";
import { useEffect, useState } from "react";
import CreateOrganizationModal from "./CreateOrganizationModal";
import { useTrialStore } from "../../lib/context/trialModalStore";

export function OrganizationSelector() {
  const [search, setSearch] = useState<string>("");
  const [newOrgModal, setNewOrgModal] = useState<boolean>(false);

  const { reset } = useTrialStore();

  const { t } = useTranslation();
  const { data: organizations } =
    trpc.organization.getAllOrganizations.useQuery();

  const navigate = useNavigate();

  useEffect(() => reset(), [reset]);

  const filteredOrganizations =
    organizations?.filter((org) => {
      if (!search) return true;
      return org.name.toLowerCase().includes(search.toLowerCase());
    }) ?? [];

  return (
    <>
      <Stack gap={2}>
        <Typography level="title-lg" textAlign="center" my={2}>
          {t("selectOrganization")}
        </Typography>
        <Card>
          <Stack gap={2} alignItems="stretch">
            <form
              onSubmit={() => {
                if (!filteredOrganizations.length) return;
                navigate("/:organizationId", {
                  params: {
                    organizationId: filteredOrganizations[0].id,
                  },
                  replace: true,
                });
              }}
            >
              <div className="flex gap-3">
                <Input
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder={t("search")}
                  autoFocus
                  fullWidth
                />
                <Button onClick={() => setNewOrgModal(true)}>
                  {t("create")}
                </Button>
              </div>
            </form>
            {filteredOrganizations.map((org, i) => (
              <Link
                key={org.id}
                to="/:organizationId"
                params={{
                  organizationId: org.id,
                }}
              >
                <Button
                  size="lg"
                  variant={i == 0 && search ? "solid" : "outlined"}
                  fullWidth
                >
                  <div className="flex flex-row items-center gap-2">
                    <span>{org.name}</span> <ArrowForward fontSize="small" />
                  </div>
                </Button>
              </Link>
            ))}
          </Stack>
        </Card>
      </Stack>
      <CreateOrganizationModal
        open={newOrgModal}
        onClose={() => setNewOrgModal(false)}
      />
    </>
  );
}
