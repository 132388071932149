import type { ApiUser } from "apiTypes";
import { trpc } from "../api/trpc/trpc.ts";
import { useLoggedInOnly } from "./useLoggedInOnly.tsx";

export function useAllowedRolesOnly(...allowedRoles: ApiUser["roles"]) {
  useLoggedInOnly();
  const { data: user, isLoading } = trpc.user.me.useQuery();
  return {
    isAllowed:
      user != null &&
      (user.isSuperUser ||
        user?.roles.some((role) => allowedRoles.includes(role))),
    isLoading,
  };
}
