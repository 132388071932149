import { CloudUpload } from "@mui/icons-material";
import {
  Button,
  Chip,
  CircularProgress,
  Grid,
  Modal,
  ModalClose,
  ModalDialog,
  ModalOverflow,
  Sheet,
  Typography,
} from "@mui/joy";
import { useState } from "react";
import { trpc } from "../../../../lib/api/trpc/trpc";
import { MeetingCard } from "./_components/MeetingCard.tsx";
import { Uploader } from "./_components/Uploader.tsx";
import { useNavigate, useParams } from "../../../../router.ts";

export default function MeetingTranscriber() {
  const [showUploadModal, setShowUploadModal] = useState(false);

  const navigate = useNavigate();
  const { organizationId } = useParams("/:organizationId");

  const { data: meetings, isLoading } =
    trpc.tools.meetingRecorder.getAll.useQuery(undefined, {
      refetchInterval: 5000,
    });

  const handleNavigation = (meetingId: string) => {
    navigate(`/:organizationId/tools/meetingRecorder/:meetingId`, {
      params: { organizationId, meetingId },
    });
  };

  return (
    <Sheet variant="soft" className="min-h-screen">
      <div className="flex flex-col justify-center gap-10 p-20">
        <div className="flex w-full justify-between">
          <div>
            <Typography level="h1">
              Meeting Aufnehmen{" "}
              <Chip color="primary" variant="solid" size="lg">
                Beta
              </Chip>
            </Typography>
            <Typography level="body-md" whiteSpace="noWrap">
              Lade hier eine Audio-Datei hoch, um sie von KI transkribieren zu
              lassen.
            </Typography>
          </div>
          <Button
            sx={{ alignSelf: "center" }}
            startDecorator={<CloudUpload />}
            onClick={() => setShowUploadModal(true)}
          >
            Aufnahme hochladen
          </Button>
        </div>

        <Modal
          open={showUploadModal}
          onClose={(
            _event: React.MouseEvent<HTMLButtonElement>,
            reason: string
          ) => {
            if (reason === "closeClick") {
              setShowUploadModal(false);
            }
          }}
        >
          <ModalOverflow>
            <ModalDialog>
              <ModalClose />
              <Uploader onUpload={() => setShowUploadModal(false)} />
            </ModalDialog>
          </ModalOverflow>
        </Modal>

        {meetings && meetings.length > 0 ? (
          <Grid container spacing={2}>
            {meetings.map((meeting) => (
              <Grid key={meeting.id} xs={12} sm={6} xl={3}>
                <MeetingCard
                  meeting={meeting}
                  onClick={() => handleNavigation(meeting.id)}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <div className="flex flex-col items-center gap-4 py-24">
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <Typography level="body-lg">
                  Noch keine Meetings vorhanden
                </Typography>
                <Button
                  startDecorator={<CloudUpload />}
                  onClick={() => setShowUploadModal(true)}
                >
                  Aufnahme hochladen
                </Button>
              </>
            )}
          </div>
        )}
      </div>
    </Sheet>
  );
}
