import { DocumentScanner, LanguageOutlined } from "@mui/icons-material";
import {
  Alert,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Tab,
  tabClasses,
  TabList,
  TabPanel,
  Tabs,
  Typography,
} from "@mui/joy";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DocumentPanel from "../../../components/translateContent/documentTranslation/DocumentPanel.tsx";
import {
  GlossaryModal,
  type GlossaryModalVariant,
} from "../../../components/translateContent/GlossaryModal";
import type { ChosenGlossary } from "../../../components/translateContent/GlossarySelect.tsx";
import GlossarySelect from "../../../components/translateContent/GlossarySelect.tsx";
import { TextPanel } from "../../../components/translateContent/TextPanel";
import { trpc } from "../../../lib/api/trpc/trpc";
import ToolPage from "../../../components/sidebar/ToolPage.tsx";

interface TranslationCategory {
  title: string;
  subtitle: string;
  icon: JSX.Element;
  component: JSX.Element;
  isEnabled: boolean;
}

export interface SelectedLanguages {
  sourceLanguage: string;
  targetLanguage: string;
}

export default function TranslateContent() {
  const [glossaryModalVariant, setGlossaryModalVariant] =
    useState<GlossaryModalVariant>(null);
  const [selectedLanguages, setSelectedLanguages] = useState<SelectedLanguages>(
    {
      sourceLanguage: "detect",
      targetLanguage: "en-GB",
    }
  );
  const [modalGlossary, setModalGlossary] = useState<ChosenGlossary | null>(
    null
  );
  const [chosenGlossary, setChosenGlossary] = useState<ChosenGlossary | null>(
    null
  );

  const glossaryMismatch = useMemo(() => {
    if (!chosenGlossary) return false;
    return (
      chosenGlossary?.sourceLang !== selectedLanguages.sourceLanguage ||
      chosenGlossary.targetLang !== selectedLanguages.targetLanguage
    );
  }, [chosenGlossary, selectedLanguages]);

  const { t } = useTranslation();
  const { data: textTranslationEnabled } =
    trpc.tools.translateContent.textTranslator.isEnabled.useQuery();
  const { data: documentTranslatorEnabled } =
    trpc.tools.translateContent.documentTranslator.isEnabled.useQuery();
  const { data: glossaries } =
    trpc.tools.translateContent.glossary.get.useQuery();

  const isGlossaryApplicable =
    selectedLanguages.sourceLanguage === chosenGlossary?.sourceLang &&
    selectedLanguages.targetLanguage === chosenGlossary?.targetLang;

  useEffect(() => {
    const localId = localStorage.getItem("chosenGlossaryId");
    if (!localId || !glossaries) return;

    const savedGlossary = glossaries.find(({ id }) => id === localId);
    if (!savedGlossary) setChosenGlossary(null);
    setChosenGlossary(savedGlossary as ChosenGlossary);
  }, [glossaries]);

  function storeChosenGlossary(glossary: ChosenGlossary | null) {
    localStorage.removeItem("chosenGlossaryId");
    if (glossary) localStorage.setItem("chosenGlossaryId", glossary.id);
    setChosenGlossary(glossary);
  }

  function updateGlossary(value: ChosenGlossary) {
    if (value.id !== chosenGlossary?.id) {
      storeChosenGlossary(value);
    } else {
      storeChosenGlossary(null);
    }
  }

  function showGlossaryModal(
    variant: "create" | "edit" | null,
    glossary: ChosenGlossary | null
  ) {
    setGlossaryModalVariant(variant);
    setModalGlossary(glossary);
  }

  const translationCategories: TranslationCategory[] = useMemo(
    () =>
      [
        {
          title: t("tools.translateText.text"),
          subtitle: "30+ " + t("languages.word"),
          icon: <LanguageOutlined sx={{ fontSize: "30px" }} />,
          component: (
            <TextPanel
              glossaryId={isGlossaryApplicable ? chosenGlossary?.id : undefined}
              selectedLanguages={selectedLanguages}
              setSelectedLanguages={setSelectedLanguages}
            />
          ),
          isEnabled: !!textTranslationEnabled,
        },
        {
          title: t("tools.translateText.file"),
          subtitle: "PDF, Word, Excel...",
          icon: <DocumentScanner sx={{ fontSize: "30px" }} />,
          component: (
            <DocumentPanel
              glossaryId={isGlossaryApplicable ? chosenGlossary?.id : undefined}
              selectedLanguages={selectedLanguages}
              setSelectedLanguages={setSelectedLanguages}
            />
          ),
          isEnabled: !!documentTranslatorEnabled,
        },
      ].filter((category) => category.isEnabled),
    [
      chosenGlossary?.id,
      documentTranslatorEnabled,
      isGlossaryApplicable,
      selectedLanguages,
      t,
      textTranslationEnabled,
    ]
  );

  return (
    <ToolPage title={t("tools.translateText.title")}>
      <Modal
        open={glossaryModalVariant !== null}
        onClose={(_, reason) => {
          reason !== "backdropClick" && setGlossaryModalVariant(null);
        }}
      >
        <ModalDialog
          sx={{
            maxHeight: "90%",
            width: "min(50%, 1000px)",
          }}
        >
          <ModalClose />
          <GlossaryModal
            variant={glossaryModalVariant}
            initGlossary={modalGlossary}
            closeModal={() => {
              setModalGlossary(null);
              setGlossaryModalVariant(null);
            }}
          />
        </ModalDialog>
      </Modal>
      <Tabs aria-label="tabs" defaultValue={0} sx={{ bgcolor: "transparent" }}>
        <Stack gap={2} pb={5}>
          <div className="flex justify-between">
            <TabList
              disableUnderline
              size="sm"
              sx={{
                p: 0.4,
                borderRadius: "xl",
                width: "fit-content",
                [`& .${tabClasses.root}[aria-selected="true"]`]: {
                  boxShadow: "sm",
                  bgcolor: "background.surface",
                },
              }}
            >
              <Stack direction="row" gap={2}>
                {translationCategories.map((translation, index) => (
                  <TabWithIcon
                    key={index}
                    icon={translation.icon}
                    title={translation.title}
                    subtitle={translation.subtitle}
                  />
                ))}
              </Stack>
            </TabList>
            <GlossarySelect
              value={chosenGlossary}
              onChange={updateGlossary}
              showModal={showGlossaryModal}
            />
          </div>
          {glossaryMismatch && (
            <Alert color="primary" sx={{ mr: "auto", mb: -3 }} size="sm">
              {t("tools.translateText.glossary.languagesNotMatching")}
            </Alert>
          )}
        </Stack>
        {translationCategories.map((category, index) => (
          <TabPanel key={category.title} value={index} sx={{ p: 0 }}>
            {category.component}
          </TabPanel>
        ))}
      </Tabs>
    </ToolPage>
  );
}

function TabWithIcon({
  icon,
  title,
  subtitle,
}: {
  icon: JSX.Element;
  title: string;
  subtitle: string;
}) {
  return (
    <Tab
      disableIndicator
      sx={{
        border: "1px solid lightgray",
        px: 2,
        py: 1,
      }}
    >
      <Stack direction="row" alignItems="center" gap={1.5}>
        {icon}
        <Stack>
          <Typography level="title-md">{title}</Typography>
          <Typography level="body-sm">{subtitle}</Typography>
        </Stack>
      </Stack>
    </Tab>
  );
}
