import { ContentCopy, Edit, Replay, Send } from "@mui/icons-material";
import {
  Avatar,
  Button,
  ButtonGroup,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/joy";

import type { RefObject } from "react";
import { useRef, useState } from "react";
import { BounceLoader } from "react-spinners";
import { useOrganization } from "../../lib/api/organization";
import { useCopySafe } from "../../lib/hooks/useCopySafe";
import { usePrimaryColor } from "../../lib/hooks/useTheme.ts";
import { useTranslation } from "../../lib/i18n";
import { ModelIcon } from "../../lib/ModelIcon.tsx";
import { UserAvatar } from "../auth/UserMenu";
import { DocumentChip } from "./attachments/DocumentChip.tsx";
import { Citation } from "./Citation";
import { DataPoolChip } from "./DataPoolChip.tsx";
import { MarkdownRenderer } from "./MarkdownRenderer";
import { useSmoothTypingText } from "./useSmoothTypingText";
import "./ChatMessage.scss";
import type {
  Message,
  MessageGenerationProgress,
  RagSource,
  ResultComponent,
} from "../../../../backend/src/api/chat/message/messageTypes.ts";
import type { Chat } from "../../../../backend/src/api/chat/chatTypes.ts";
import { ChatItem } from "./ChatItem.tsx";
import { MessageEditor } from "./MessageEditor.tsx";
import { LlmName } from "../../../../backend/src/ai/llmMeta.ts";
import { useGuide } from "../onboarding/useGuide.tsx";

export function ChatMessage({
  chat,
  message,
  onRegenerate,
  onEdit,
  embedded,
  progress,
}: {
  chat: Chat;
  message: Message;
  onRegenerate: () => void;
  onEdit: (content: string) => void;
  embedded?: boolean;
  progress?: MessageGenerationProgress;
}) {
  const { t } = useTranslation();

  const messageContentRef = useRef<HTMLDivElement>(null);
  const [editing, setEditing] = useState(false);

  const m = message;
  const citations = m.citations.map((citation) => ({
    link: citation,
    title: citation,
  }));

  const { completed, setRun, run } = useGuide();

  if (citations.length > 0) {
    m.content.replace(/\[(\d+)\]/g, (match, number) => {
      return `[#${number}]`; // could also display an icon with a number
    });
  }

  const [editedContent, setEditedContent] = useState(m.content);

  const generating = m.fromAi && !m.responseCompleted;

  if (!generating && !completed && !run) {
    setTimeout(() => {
      setRun(true);
    }, 8000);
  }

  const smoothContent = useSmoothTypingText(m.content);
  const content = generating ? smoothContent : m.content;

  const onEditSafe = (newContent: string) => {
    if (
      newContent.trim().length > 0 &&
      newContent.trim() !== m.content.trim()
    ) {
      onEdit(newContent);
    }
    setEditing(false);
    setEditedContent(m.content);
  };

  const organization = useOrganization();
  const currentModel = chat?.modelOverride
    ? LlmName.parse(chat.modelOverride)
    : organization?.defaultModel;

  const model: LlmName = (m.generationModel ??
    currentModel ??
    "gpt-3.5-turbo") as LlmName;

  const primaryColor = usePrimaryColor();

  function combineRagSources(ragSources: RagSource[]): {
    knowledgeCollectionId: string;
    queries: Record<string, ResultComponent[]>;
  }[] {
    const combinedSourcesMap = new Map();

    ragSources.forEach((source) => {
      const { knowledgeCollectionId, query, resultComponents } = source;

      if (!combinedSourcesMap.has(knowledgeCollectionId)) {
        combinedSourcesMap.set(knowledgeCollectionId, {});
      }

      const collection = combinedSourcesMap.get(knowledgeCollectionId);

      if (collection[query ?? "-"] == undefined) {
        collection[query ?? "-"] = [];
      }

      collection[query ?? "-"].push(...resultComponents);
    });

    const combined = Array.from(combinedSourcesMap.entries()).map(
      ([knowledgeCollectionId, queries]) => ({
        knowledgeCollectionId,
        queries,
      })
    );
    return combined;
  }

  const combinedSources = combineRagSources(m.ragSources);

  return (
    <ChatItem
      icon={
        m.fromAi ? (
          <Avatar variant="outlined">
            <ModelIcon modelName={model} />
          </Avatar>
        ) : (
          <UserAvatar userId={m.authorId} />
        )
      }
      message={message}
      embedded={embedded}
      generating={generating}
    >
      {generating && (
        <div className="flex items-center gap-2">
          <BounceLoader color={primaryColor} key="spinner" size={20} />
          {progress && (
            <Typography color="neutral">
              {t(
                "messageGenerationProgress." +
                  (progress?.currentStepType ?? "initializing"),
                {
                  numQueries: progress.queryCount,
                  numPools: progress.dataPoolCount,
                }
              )}
            </Typography>
          )}
        </div>
      )}
      <div
        ref={messageContentRef}
        style={{
          minHeight: !embedded ? "3rem" : "0",
        }}
      >
        <div>
          <Typography color="neutral" component="div">
            {editing && (
              <MessageEditor
                editedContent={editedContent}
                setEditedContent={setEditedContent}
                setEditing={setEditing}
                onEditSafe={onEditSafe}
              />
            )}
            {m.cancelled && (
              <div>
                <Typography level="body-sm" fontStyle="italic">
                  {t("messageCancelled")}
                </Typography>
              </div>
            )}
            {!editing && !m.cancelled && (
              <div className="pr-7">
                <MarkdownRenderer content={content} sources={m.ragSources} />
                <Citation citations={citations} />
              </div>
            )}
          </Typography>
        </div>
      </div>
      <div className="flex flex-row flex-wrap gap-2">
        {m.attachmentIds.map((id) => (
          <DocumentChip documentId={id} key={id} />
        ))}
        {(combinedSources ?? []).map((source) => (
          <DataPoolChip
            id={source.knowledgeCollectionId}
            key={source.knowledgeCollectionId}
            results={source}
          />
        ))}
      </div>
      <div className="absolute bottom-0 right-0 p-2">
        {m.fromAi && m.responseCompleted && (
          <AiMessageOptions
            onRegenerate={onRegenerate}
            messageContentRef={messageContentRef}
            content={m.content}
          />
        )}
        {!m.fromAi && (
          <UserMessageOptions
            onEdit={() => {
              setEditing(true);
            }}
            onEditSafe={onEditSafe}
            editedContent={editedContent}
            isEditing={editing}
          />
        )}
      </div>
    </ChatItem>
  );
}

function UserMessageOptions({
  onEdit,
  onEditSafe,
  editedContent,
  isEditing,
}: {
  onEdit: () => void;
  onEditSafe: (newContent: string) => void;
  editedContent: string;
  isEditing?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row items-center gap-2 opacity-0 transition-all group-hover:opacity-100">
      {isEditing ? (
        <Tooltip title={t("sendMessage")}>
          <IconButton
            size="sm"
            onClick={() => onEditSafe(editedContent.trim())}
          >
            <Send />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={t("editMessage")}>
          <IconButton size="sm" onClick={onEdit}>
            <Edit />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}

function AiMessageOptions({
  messageContentRef,
  content,
  onRegenerate,
}: {
  messageContentRef: RefObject<HTMLDivElement>;
  content: string;
  onRegenerate: () => void;
}) {
  const { t } = useTranslation();
  const copy = useCopySafe();

  return (
    <div className="flex flex-row items-center gap-2 opacity-0 transition-all group-hover:opacity-100">
      <Tooltip
        variant="outlined"
        title={
          <ButtonGroup orientation="vertical" variant="plain">
            <Button
              onClick={() => {
                copy(content);
              }}
            >
              {t("copyMarkdown")}
            </Button>
            <Button
              onClick={() => {
                copy(messageContentRef.current?.innerText ?? "");
              }}
            >
              {t("copyText")}
            </Button>
          </ButtonGroup>
        }
      >
        <IconButton
          size="sm"
          onClick={() => {
            copy(
              messageContentRef.current?.innerHTML ?? "",
              messageContentRef.current?.innerText ?? ""
            );
          }}
        >
          <ContentCopy />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("regenerate")}>
        <IconButton size="sm" onClick={onRegenerate}>
          <Replay />
        </IconButton>
      </Tooltip>
    </div>
  );
}
