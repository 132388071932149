import { CalendarToday } from "@mui/icons-material";
import { Typography } from "@mui/joy";
import { format } from "date-fns";
import { de, enUS, es, fr, it } from "date-fns/locale";
import { useTranslation } from "../../../../../lib/i18n.ts";

//TODO create hook for this
function getLocale(key: string) {
  const locale = {
    de: de,
    fr: fr,
    it: it,
    es: es,
  }[key];
  return locale ?? enUS;
}

export function MeetingDate({ date }: { date: string }) {
  const { i18n } = useTranslation();

  const formattedDate = format(new Date(date), "P", {
    locale: getLocale(i18n.language),
  });

  return (
    <Typography level="body-sm" sx={{ display: "flex", alignItems: "center" }}>
      <CalendarToday sx={{ fontSize: 14, mr: 0.5 }} />
      {formattedDate}
    </Typography>
  );
}
