import { Sheet, Typography } from "@mui/joy";
import { AdoptionMetrics } from "../../components/metrics/AdoptionMetrics.tsx";
import { useTranslation } from "../../lib/i18n";

export default function OrganisationAdoptionView() {
  const { t } = useTranslation();

  return (
    <Sheet className="min-h-screen" variant="soft">
      <div className="flex flex-col items-center px-10 pt-10">
        <div className="container flex flex-col gap-10 pb-20">
          <Typography level="h2">{t("settings.tabs.metrics")}</Typography>
          <AdoptionMetrics />
        </div>
      </div>
    </Sheet>
  );
}
