import type { Workflow } from "../../../../backend/src/api/workflow/workflowTypes";

function examplePayload(workflow: Workflow) {
  const json = {
    input: {} as Record<string, string | boolean>,
  };

  for (const input of workflow.inputs ?? []) {
    switch (input.type) {
      case "short_text":
      case "long_text":
        json.input[input.key] = "example";
        break;
      case "enum":
        json.input[input.key] = input.options[0].value ?? "example";
        break;
      case "toggle":
        json.input[input.key] = true;
        break;
    }
  }

  return JSON.stringify(json, null, 2);
}

function exampleJavascript(workflow: Workflow, url: string) {
  return `
const response = await fetch('${url}', {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer sk_meingpt_<YOUR_API_KEY>',
    },
    body: JSON.stringify(${examplePayload(workflow)}),
});
const json = await response.json();
console.log(json.response);
`;
}

function examplePython(workflow: Workflow, url: string) {
  return `
import requests

response = requests.post('${url}', json=${examplePayload(workflow)}, headers={
    'Content-Type': 'application/json',
    'Authorization': 'Bearer sk_meingpt_<YOUR_API_KEY>',
})
print(response.json()['response'])
`;
}

function exampleCurl(workflow: Workflow, url: string) {
  return `
curl -X POST '${url}' \\
    -H 'Content-Type: application/json' \\
    -H 'Authorization: Bearer sk_meingpt_<YOUR_API_KEY>' \\
    -d '${examplePayload(workflow)}'
`;
}

export function generateCodeExamples(workflow: Workflow, url: string) {
  return {
    payload: examplePayload(workflow),
    examples: [
      {
        language: "JavaScript",
        highlight: "javascript",
        code: exampleJavascript(workflow, url),
      },
      {
        language: "Python",
        highlight: "python",
        code: examplePython(workflow, url),
      },
      {
        language: "cURL",
        highlight: "bash",
        code: exampleCurl(workflow, url),
      },
    ],
  };
}
