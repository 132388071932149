import {
  Chat as ChatIcon,
  Close,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  List,
  ListSubheader,
  Modal,
  ModalClose,
  ModalDialog,
  Tooltip,
  Typography,
} from "@mui/joy";
import { differenceInDays } from "date-fns";
import { Form, Formik } from "formik";
import type { ReactElement } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import z from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import type { Chat } from "../../../../backend/src/api/chat/chatTypes";
import { trpc } from "../../lib/api/trpc/trpc";
import { handleGenericError } from "../../lib/errorHandling.tsx";
import { useTranslation } from "../../lib/i18n";
import { maxStringLength } from "../../lib/util";
import { useNavigate, useParams } from "../../router";
import { AreYouSure, TreeItem } from "./tree/LeafItem";

export default function ChatTree() {
  const { t, i18n } = useTranslation();
  const DEFAULT_AMOUNT_OF_CHATS = 5;

  const clearAllChats = useDeleteAllChats();
  const [clearAllChatsConfirmOpen, setClearAllChatsConfirmOpen] =
    useState(false);
  const [amountOfChats, setAmountOfChats] = useState(DEFAULT_AMOUNT_OF_CHATS);
  const chats = trpc.chat.getAll.useQuery().data;

  const totalChats = chats?.length ?? 0;

  if (chats === undefined) return;

  const lastChats = chats
    ?.sort(
      (a, b) =>
        new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    )
    .slice(0, amountOfChats);

  if (!lastChats) return;

  const loadMoreChats = () => {
    setAmountOfChats((prevAmount) => {
      return prevAmount + 15;
    });
  };

  let lastSubheaderLabel = "";

  const groupedChats = lastChats.map((chat) => {
    let currentSubheaderLabel = "";
    const updatedAt = new Date(chat.updatedAt);
    const daysDifference = differenceInDays(new Date(), updatedAt);
    if (daysDifference < 7) {
      currentSubheaderLabel = t("previous7Days");
    } else if (daysDifference < 30) {
      currentSubheaderLabel = t("previous30Days");
    } else {
      currentSubheaderLabel = new Intl.DateTimeFormat(i18n.language, {
        month: "long",
        year: daysDifference > 365 ? "numeric" : undefined,
      })
        .format(updatedAt)
        .toString();
    }
    let subheader: ReactElement | null = null;
    if (
      lastSubheaderLabel !== currentSubheaderLabel &&
      amountOfChats > DEFAULT_AMOUNT_OF_CHATS
    ) {
      lastSubheaderLabel = currentSubheaderLabel;
      subheader = (
        <ListSubheader sx={{ pt: 1, pb: 0, pl: 2 }}>
          {currentSubheaderLabel}
        </ListSubheader>
      );
    }
    return (
      <div key={chat.id}>
        <div>{subheader}</div>
        <ChatItem chat={chat} />
      </div>
    );
  });

  return (
    <>
      <div className="group">
        <AreYouSure
          open={clearAllChatsConfirmOpen}
          onClose={() => setClearAllChatsConfirmOpen(false)}
          onSure={clearAllChats}
        />
        <div className="flex flex-row items-center">
          <Typography
            level="title-md"
            color="neutral"
            sx={{
              px: 2,
              py: 1,
            }}
          >
            {t("lastChats")}
          </Typography>
          <div className="opacity-0 transition-all group-hover:opacity-100">
            <Tooltip title={t("deleteAllChats")}>
              <IconButton
                size="sm"
                onClick={() => setClearAllChatsConfirmOpen(true)}
              >
                <Close fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <List size="sm">
          {groupedChats}
          {lastChats.length === 0 ? (
            <Typography
              level="body-sm"
              color="neutral"
              sx={{
                px: 2,
                py: 1,
                fontStyle: "italic",
              }}
            >
              {t("noChats")}
            </Typography>
          ) : (
            <div
              className="my-1 flex-row justify-between"
              style={{ userSelect: "none" }}
            >
              <ButtonGroup
                size="sm"
                variant="plain"
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  pt: 1,
                  "& > *:only-child": {
                    border: "none",
                  },
                }}
              >
                {lastChats.length < totalChats && (
                  <Button
                    onClick={() => {
                      loadMoreChats();
                    }}
                    startDecorator={<KeyboardArrowDown />}
                  >
                    {t("moreChats")}
                  </Button>
                )}
                {amountOfChats > DEFAULT_AMOUNT_OF_CHATS && (
                  <Button
                    onClick={() => setAmountOfChats(DEFAULT_AMOUNT_OF_CHATS)}
                    startDecorator={<KeyboardArrowUp />}
                  >
                    {t("hide")}
                  </Button>
                )}
              </ButtonGroup>
            </div>
          )}
        </List>
      </div>
    </>
  );
}

function ChatItem({ chat }: { chat: Chat }) {
  const { t } = useTranslation();

  const params = useParams("/:organizationId/chats/:chatId");
  const active = params.chatId === chat.id;
  const navigate = useNavigate();
  const { mutateAsync: deleteChat } = trpc.chat.archive.useMutation();
  const utils = trpc.useUtils();
  const [renameModalOpen, setRenameModalOpen] = useState(false);

  return (
    <>
      <TreeItem
        icon={<ChatIcon fontSize="small" />}
        name={maxStringLength(chat.name ?? t("newChat"), 25)!}
        onDelete={() => {
          toast
            .promise(
              deleteChat({ chatId: chat.id }).then(() =>
                utils.chat.invalidate()
              ),
              {
                success: t("chatDeleted"),
                error: t("chatDeleteFailed"),
              }
            )
            .catch((e) => console.error(e));
          if (!active) {
            return;
          }
          navigate("/:organizationId", {
            params: {
              ...params,
            },
          });
        }}
        endDecorator={
          <Tooltip title={t("rename")}>
            <div
              onClick={async (event) => {
                event.stopPropagation();
                setRenameModalOpen(true);
              }}
            >
              <Edit fontSize="small" />
            </div>
          </Tooltip>
        }
        selected={active}
        onClick={() => {
          navigate("/:organizationId/chats/:chatId", {
            params: {
              ...params,
              chatId: chat.id,
            },
          });
        }}
      />
      <RenameChatModal
        chat={chat}
        open={renameModalOpen}
        onClose={() => setRenameModalOpen(false)}
      />
    </>
  );
}

const validationSchema = z.object({
  name: z.string().min(1).max(50),
});
type FormValues = z.infer<typeof validationSchema>;

function RenameChatModal({
  open,
  chat,
  onClose,
}: {
  open: boolean;
  chat: Chat | null;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const { mutateAsync: renameChat } = trpc.chat.rename.useMutation();

  const utils = trpc.useUtils();

  async function handleSubmit(values: FormValues) {
    try {
      await renameChat({
        chatId: chat!.id,
        name: values.name,
      });
      await utils.chat.invalidate();
      onClose();
    } catch (e) {
      handleGenericError(
        e instanceof Error ? e : new Error("rename chat", { cause: e }),
        "rename chat"
      );
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog>
        <ModalClose />
        <Typography level="title-lg">{t("renameChat")}</Typography>

        <Formik
          initialValues={{ name: chat?.name ?? "" }}
          validationSchema={toFormikValidationSchema(validationSchema)}
          onSubmit={handleSubmit}
          validateOnMount={true}
        >
          {({
            errors,
            touched,
            getFieldProps,
            isValid,
            isSubmitting,
            resetForm,
          }) => (
            <Form className="flex flex-col gap-4 overflow-hidden">
              <FormControl
                error={Boolean(errors.name) && touched.name}
                required
              >
                <FormLabel>{t("name")}</FormLabel>
                <Input placeholder="Name" {...getFieldProps("name")} />
              </FormControl>
              <div className="flex justify-end gap-2">
                <Button
                  variant="plain"
                  onClick={() => {
                    resetForm();
                    onClose();
                  }}
                >
                  {t("cancel")}
                </Button>
                <Button
                  type="submit"
                  variant="solid"
                  disabled={!isValid}
                  loading={isSubmitting}
                >
                  {t("rename")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalDialog>
    </Modal>
  );
}

function useDeleteAllChats() {
  const utils = trpc.useUtils();
  const { mutateAsync } = trpc.chat.archiveAll.useMutation();
  const params = useParams("/:organizationId");
  const navigate = useNavigate();

  return async () => {
    await mutateAsync();
    await utils.chat.invalidate();
    toast.success("Deleted all chats");
    return navigate(`/:organizationId`, { params });
  };
}
