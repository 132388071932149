import { useEffect } from "react";
import { useNavigate } from "../../router.ts";

export default function Page() {
  const navigate = useNavigate();

  useEffect(() => {
    // This page is not used anymore since 2024-09-17
    navigate("/teach");
  }, [navigate]);

  return null;
}
