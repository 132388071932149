import { Outlet } from "react-router-dom";
import { AuthLayout } from "../../components/auth/AuthLayout";

export default function AuthScreensLayout() {
  return (
    <AuthLayout>
      <Outlet />
    </AuthLayout>
  );
}
