import { Typography } from "@mui/joy";
import { Alert } from "@mui/joy";
import { t } from "i18next";
import WarningIcon from "@mui/icons-material/Warning";
export function TranslationError({ message }: { message: string | undefined }) {
  return (
    <Alert
      sx={{ alignItems: "flex-start" }}
      startDecorator={<WarningIcon />}
      color="danger"
      role="alert"
      aria-live="polite"
    >
      <div>
        <div>{t("error")}</div>
        <Typography level="body-sm" color="danger">
          {message ?? t("deeplErrorCodes.INTERNAL_SERVER_ERROR")}
        </Typography>
      </div>
    </Alert>
  );
}
