import ClearIcon from "@mui/icons-material/Clear";
import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  Typography,
} from "@mui/joy";
import { toast } from "react-toastify";
import { z } from "zod";
import { useOrganization } from "../../lib/api/organization";
import { trpc } from "../../lib/api/trpc/trpc";
import { useMe } from "../../lib/api/user";
import { useTranslation } from "../../lib/i18n";
import { EnabledModelsSelector } from "../input/ModelSelectorModal";
import { DelayedLoader } from "../util/DelayadLoader";
import { PopoverPicker } from "./PopoverPicker";
import type { LlmName } from "../../../../backend/src/ai/llmMeta";
import { LLM_META } from "../../../../backend/src/ai/llmMeta";
import { useBlocker, useNavigate } from "react-router-dom";
import { SettingsPage } from "./SettingsPage";
import { SuperUserSettings } from "./SuperAdminSettings";
import { FormikProvider, useFormik } from "formik";
import { ContactInfo } from "../../../../backend/src/api/organization/contactInfo/contactInfoTypes";
import { toFormikValidationSchema } from "zod-formik-adapter";
import {
  FormikTextField,
  type FormikTextFieldProps,
} from "../forms/FormikTextField";
import {
  FormikTextarea,
  type FormikTextAreaProps,
} from "../forms/FormikTextArea";
import { useEffect } from "react";

export interface OrganizationSettingsInputs {
  name: string;
  customPrimaryColor?: string;
  avatarUrl?: string;
  logoUrl?: string;
  context?: string;
  contactInfo?: ContactInfo;
}

const urlSettingsZod = (error: string) =>
  z
    .union([z.string().url(), z.literal("")], {
      invalid_type_error: error,
    })
    .transform((value) => (value === "" ? null : value));

function SettingsField(props: FormikTextFieldProps) {
  const { t } = useTranslation();
  return (
    <FormikTextField
      name={props.name}
      label={t(`organizationSettings.${props.name}`)}
      {...props}
    />
  );
}

function SettingsTextarea(props: FormikTextAreaProps) {
  const { t } = useTranslation();
  return (
    <FormikTextarea
      name={props.name}
      label={t(`organizationSettings.${props.name}`)}
      {...props}
    />
  );
}

export function GeneralSettings() {
  const { t } = useTranslation();
  const organization = useOrganization();
  const updateOrganization = trpc.organization.mutateOrganization.useMutation();
  const archiveOrganizationMutation = trpc.organization.archive.useMutation();
  const utils = trpc.useUtils();
  const navigate = useNavigate();

  const { data: enabledModels } = trpc.modelConfig.getEnabled.useQuery();
  const { data: activeModels } = trpc.modelConfig.getActive.useQuery();

  const { mutateAsync: enableModel } = trpc.modelConfig.enable.useMutation();
  const { mutateAsync: disableModel } = trpc.modelConfig.disable.useMutation();
  const user = useMe();

  const contactInfoQuery =
    trpc.contactInfo.getOrganizationContactInfo.useQuery().data;

  const contactInfoMutation =
    trpc.contactInfo.mutateOrganizationContactInfo.useMutation();

  const availableModels: LlmName[] = (activeModels ?? []).filter(
    (m) => LLM_META[m]?.allowChat
  );

  const formik = useFormik<OrganizationSettingsInputs>({
    initialValues: {
      name: organization?.name ?? "",
      customPrimaryColor: organization?.customPrimaryColor ?? "",
      avatarUrl: organization?.avatarUrl ?? "",
      logoUrl: organization?.logoUrl ?? "",
      context: organization?.context ?? "",
      contactInfo: {
        name: contactInfoQuery?.name ?? "",
        email: contactInfoQuery?.email ?? "",
        phone: contactInfoQuery?.phone ?? "",
        additionalInfo: contactInfoQuery?.additionalInfo ?? "",
      },
    },

    onSubmit: async (values: OrganizationSettingsInputs) => {
      const { contactInfo, ...organizationData } = values;
      let success = true;
      try {
        await updateOrganization.mutateAsync(organizationData);
        void utils.organization.getOrganization.invalidate();
      } catch {
        toast.error(t("organizationUpdateFailed"));
        success = false;
      }
      try {
        contactInfo && (await contactInfoMutation.mutateAsync(contactInfo));
        void utils.contactInfo.getOrganizationContactInfo.invalidate();
      } catch {
        toast.error(t("contactsUpdateFailed"));
        success = false;
      }
      if (success) {
        toast.success(t("organizationSettings.updated"));
      }
    },

    validationSchema: toFormikValidationSchema(
      z.object({
        name: z.string().min(1).max(30),
        customPrimaryColor: z
          .string()
          .regex(/^#[0-9A-Fa-f]{6}$/)
          .nullable()
          .optional(),
        avatarUrl: urlSettingsZod(t("errors.invalidUrl")).nullable().optional(),
        logoUrl: urlSettingsZod(t("errors.invalidUrl")).nullable().optional(),
        context: z.string().nullable().optional(),
        contactInfo: ContactInfo.nullable().optional(),
      })
    ),
    enableReinitialize: true,
  });

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      formik.dirty && currentLocation.pathname !== nextLocation.pathname
  );
  useEffect(() => {
    if (blocker.state === "blocked" && confirm(t("unsavedChangesWarning"))) {
      blocker.proceed();
    }
  }, [blocker, t]);

  if (!organization || !contactInfoQuery) return <DelayedLoader />;

  /**
   * @returns If the model was actually updated
   */
  async function handleUpdateModel(
    modelKey: string,
    enabled: boolean
  ): Promise<boolean> {
    if (enabled) {
      await enableModel({
        model: modelKey,
      });
      void utils.invalidate();
      return true;
    } else {
      if ((enabledModels?.length ?? 0) <= 1) {
        toast.error(t("cantDisableLastModel"));
        return false;
      }
      if (organization!.defaultModel === modelKey) {
        toast.error(t("cantDisableDefaultModel"));
        return false;
      }
      await disableModel({
        model: modelKey,
      });
      void utils.invalidate();
      return true;
    }
  }
  const archiveOrganization = () => {
    const res = prompt(
      `Please enter the organization name to confirm (${organization.name})`
    );
    if (res === organization.name) {
      archiveOrganizationMutation
        .mutateAsync()
        .then(() => {
          void utils.organization.invalidate();
        })
        .catch(() => {
          alert("Failed to archive organization");
        });
      navigate("/");
    } else if (res !== null) {
      alert("Organization name does not match");
    }
  };
  return (
    <FormikProvider value={formik}>
      <SettingsPage title={t("generalSettings")}>
        {user?.isSuperUser && (
          <SuperUserSettings>
            <div className="flex w-full flex-wrap gap-4 ">
              <SettingsField
                name="name"
                sx={{ minWidth: "40ch" }}
                containerClass="grow"
              />
              <SettingsField
                name="customPrimaryColor"
                sx={{ minWidth: "40ch" }}
                containerClass="grow"
                startDecorator={
                  <PopoverPicker
                    color={formik.values.customPrimaryColor ?? ""}
                    onChange={async (value) => {
                      await formik.setFieldValue("customPrimaryColor", value);
                      await formik.setTouched({ customPrimaryColor: true });
                    }}
                  />
                }
                endDecorator={
                  formik.values.customPrimaryColor !== null ? (
                    <IconButton
                      onClick={async () => {
                        await formik.setFieldValue("customPrimaryColor", "");
                        await formik.setTouched({ customPrimaryColor: true });
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  ) : null
                }
              />
            </div>
            <SettingsField name="avatarUrl" />
            <SettingsField name="logoUrl" />
            <Typography level="title-lg" mt={3}>
              {t("supportContact")}
            </Typography>
            <Typography color="neutral">{t("supportContactInfo")}</Typography>
            <SettingsField name="contactInfo.name" />
            <div className="flex flex-wrap gap-4">
              <SettingsField
                name="contactInfo.email"
                type="email"
                containerClass="min-w-[30ch] grow"
              />
              <SettingsField
                name="contactInfo.phone"
                type="tel"
                containerClass="min-w-[30ch] grow"
              />
            </div>
            <SettingsTextarea
              name={t("contactInfo.additionalInfo")}
              minRows={3}
              maxRows={3}
            />
          </SuperUserSettings>
        )}
        <SettingsTextarea
          name="context"
          minRows={4}
          maxRows={4}
          placeholder={t("organizationContextPromptExample", {
            organization: organization.name,
          })}
          helperText={t("organizationContextPromptInfo")}
        />
        <Button
          className="!mb-10 self-start"
          onClick={formik.submitForm}
          disabled={!formik.isValid || !formik.dirty}
        >
          {t("save")}
        </Button>

        <Typography level="title-lg">{t("enabledLLMs")}</Typography>

        <FormControl key="nonEuWarningSkippable">
          <Checkbox
            label={t("removeNonEuDontShow")}
            checked={organization?.nonEuWarningSkippable ?? false}
            onChange={async (e) => {
              updateOrganization
                .mutateAsync({
                  nonEuWarningSkippable: e.target.checked,
                })
                .then(() => {
                  toast.success(
                    `${t("removeNonEuDontShow")} ${t("settingsChange")}`
                  );
                  void utils.organization.getOrganization.invalidate();
                })
                .catch(() => {
                  toast.error(t("organizationUpdateFailed"));
                  e.target.checked = organization.nonEuWarningSkippable;
                });
            }}
          />
          <FormHelperText>{t("removeNonEuDontShowHelperText")}</FormHelperText>
        </FormControl>
        <EnabledModelsSelector
          availableModels={availableModels}
          selectedModels={enabledModels ?? []}
          updateModel={handleUpdateModel}
          setDefaultModel={async (model) => {
            await updateOrganization.mutateAsync({
              defaultModel: model,
            });
            await utils.invalidate();
            toast.success(t("changeDefaultLLMConfirm"));
          }}
        />
        {user?.isSuperUser && (
          <div className="mt-10">
            <Typography level="title-lg" mb={2}>
              Danger Zone
            </Typography>
            <SuperUserSettings color="danger">
              <div className="flex items-center justify-between">
                <Typography level="title-md">
                  Archive the organization and all its data
                </Typography>
                <Button onClick={archiveOrganization} color="danger">
                  Archive organization
                </Button>
              </div>
            </SuperUserSettings>
          </div>
        )}
      </SettingsPage>
    </FormikProvider>
  );
}
