import z from 'zod';
import { ShallowObjectArray } from '../../common/commonTypes';
import { SearchConfig } from './searchSettingsTypes';

export const KnowledgeCollection = z.object({
  id: z.string().cuid(),
  name: z.string().min(1),
  description: z.string().nullable(),
  departments: ShallowObjectArray,
  ragSettings: SearchConfig.nullable().transform(
    (v) =>
      v ?? {
        reranking: { enabled: false, topN: 3, minScore: 0.1 },
        search: { minScore: 0.1, searchLimit: 10, useHybridSearch: false },
      },
  ),
  dataConnectors: ShallowObjectArray,
});
export type KnowledgeCollection = z.infer<typeof KnowledgeCollection>;
