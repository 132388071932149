import { trpc } from "./trpc/trpc";
import { useNavigate } from "../../router";

export function useOrganization() {
  return trpc.organization.getOrganization.useQuery().data;
}

export function usePartOfCurrentOrganization() {
  const navigate = useNavigate();
  const { error: orgError } = trpc.organization.getOrganization.useQuery();

  if (
    orgError &&
    ["NOT_FOUND", "FORBIDDEN"].includes(orgError.data?.code ?? "")
  ) {
    navigate("/", { replace: true });
  }
}
