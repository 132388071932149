import { ApiDate } from 'apiTypes';
import z from 'zod';
import { LlmName } from '../../../ai/llmMeta';
import { WorkflowInput, WorkflowStep } from '../workflowTypes';

export const WorkflowPortSingle = z.object({
  name: z.string(),
  description: z.string().nullable(),
  modelOverride: LlmName.nullable(),
  departmentName: z.string(),
  allowDocumentUpload: z.boolean(),
  estimatedMinutesSaved: z.number().nullable(),

  steps: z.array(WorkflowStep.omit({ id: true })),
  inputs: z.array(WorkflowInput),
});

export type WorkflowPortSingle = z.infer<typeof WorkflowPortSingle>;

export const WorkflowPortPackage = z.object({
  version: z.literal('1'),
  exportedAt: ApiDate,
  exportedBy: z.string(),
  originalOrganizationId: z.string(),
  originalOrganizationName: z.string(),

  workflows: z.array(WorkflowPortSingle),
});

export type WorkflowPortPackage = z.infer<typeof WorkflowPortPackage>;
