import "highlight.js/styles/github.css";
import type { ComponentProps, CSSProperties } from "react";
import React from "react";
import ReactMarkdown from "react-markdown";
import RehypeHighlight from "rehype-highlight";
import remarkBreaks from "remark-breaks";
import remarkDirective from "remark-directive";
import RemarkDirectiveRehype from "remark-directive-rehype";
import remarkGfm from "remark-gfm";
import { CodeBlock } from "./markdown/CodeBlock.tsx";
import { InlineCitation } from "./markdown/InlineCitation.tsx";
import RemarkCitePlugin from "./markdown/RemarkCitePlugin.ts";
import type { RagSource } from "../../../../backend/src/api/chat/message/messageTypes.ts";

const remarkPlugins = [
  remarkDirective,
  remarkGfm,
  remarkBreaks,
  RemarkCitePlugin,
];
const rehypePlugins = [
  [
    RehypeHighlight,
    {
      ignoreMissing: true,
      detect: true,
    },
  ],
  RemarkDirectiveRehype,
] as ComponentProps<typeof ReactMarkdown>["rehypePlugins"];

/**
 * Renders markdown content with syntax highlighting and citation support.
 * @param content The markdown content to render.
 * @param style Optional CSS styles to apply to the rendered content.
 * @param sources List of available sources for citations.
 */
function _MarkdownRenderer({
  content,
  style,
  sources,
}: {
  content: string;
  style?: CSSProperties;
  sources?: RagSource[];
}) {
  return (
    <div className="markdown-body .light" style={style ?? {}}>
      <ReactMarkdown
        remarkPlugins={remarkPlugins}
        rehypePlugins={rehypePlugins}
        className="white-space-pre-wrap leading-loose"
        components={{
          p: (pProps) => <p {...pProps} dir="auto" />,
          a: (aProps) => {
            const href = aProps.href || "";
            const isInternal = /^\/#/i.test(href);
            const target = isInternal ? "_self" : aProps.target ?? "_blank";
            return <a {...aProps} target={target} />;
          },
          pre: CodeBlock,
          cite(props) {
            return sources != undefined && sources.length > 0 ? (
              <InlineCitation {...props} availableSources={sources ?? []} />
            ) : undefined;
          },
        }}
      >
        {content}
      </ReactMarkdown>
    </div>
  );
}

/**
 * {@inheritDoc _MarkdownRenderer}
 * Memoized version of {@link _MarkdownRenderer}.
 */
export const MarkdownRenderer = React.memo(_MarkdownRenderer);
