import { Download, ImageOutlined, Warning } from "@mui/icons-material";
import { Alert, Card, Typography } from "@mui/joy";
import { twMerge } from "tailwind-merge";
import { type ImageAspectRatio } from "../../../../backend/src/api/tools/images/imagesTypes";
import { useTranslation } from "../../lib/i18n";
import { type ImageModal } from "../../pages/[organizationId]/tools/imageFactory";

function calculateImageDimensions(
  aspectRatio: ImageAspectRatio,
  numberOfImages: number,
  totalWidth: number = 450,
  totalHeight: number = 450,
  padding: number = 24
): { width: string; height: string } {
  const columns = numberOfImages > 1 ? 2 : 1;
  const rows = Math.ceil(numberOfImages / columns);

  const availableWidth = (totalWidth - (columns - 1) * padding) / columns;
  const availableHeight = (totalHeight - (rows - 1) * padding) / rows;

  const aspectRatioValue = {
    "16:9": 16 / 9,
    "1:1": 1,
    "9:16": 9 / 16,
  }[aspectRatio];

  const width = Math.min(availableWidth, availableHeight * aspectRatioValue);
  const height = Math.min(
    availableHeight,
    availableWidth * (1 / aspectRatioValue)
  );

  return { width: width + "px", height: height + "px" };
}

/**
 * @param {string[]} [props.urls] - Array of generated image URLs.
 * @param {boolean} [props.generating] - Flag indicating if images are being generated.
 * @param {string} [props.error] - Error message to display if image generation fails.
 */
export function GeneratedImages({
  numberOfImages,
  aspectRatio,
  urls,
  generating,
  error,
  setModal,
}: {
  setModal: (modal: ImageModal) => void;
  numberOfImages: number;
  aspectRatio: ImageAspectRatio;
  urls?: string[];
  generating?: boolean;
  error?: string;
}) {
  return (
    <>
      <Card
        color={error ? "danger" : undefined}
        sx={{
          p: 2,
        }}
      >
        <RenderImage
          setModal={setModal}
          numberOfImages={numberOfImages}
          aspectRatio={aspectRatio}
          generating={generating}
          error={error}
          urls={urls}
          key={urls?.join("")}
        />
      </Card>
    </>
  );
}

function RenderImage({
  numberOfImages,
  aspectRatio,
  urls,
  generating,
  setModal,
  error,
}: {
  numberOfImages: number;
  aspectRatio: ImageAspectRatio;
  setModal: (modal: ImageModal) => void;
  urls?: string[];
  generating?: boolean;
  error?: string;
}) {
  const { t } = useTranslation();

  if (error) {
    return (
      <div className="grid h-[450px] min-w-[450px] grow place-content-center">
        <Alert color="danger" startDecorator={<Warning />}>
          {error}
        </Alert>
      </div>
    );
  }
  return (
    <div className="flex flex-1 flex-col p-2">
      {(!urls || urls.length === 0) && (
        <>
          <Typography level="h3">{t("images.preview")}</Typography>
          <Typography level="body-md" mb={2}>
            {generating
              ? t("images.generating_other")
              : t("images.previewDescription")}
          </Typography>
        </>
      )}
      <div
        className={twMerge(
          "grid h-[450px] min-h-0 w-[450px] auto-rows-fr items-center justify-items-center gap-[24px]  overflow-hidden",
          numberOfImages > 1 ? "grid-cols-2" : "grid-cols-1"
        )}
      >
        {Array.from({ length: numberOfImages }, (_, index) => {
          if (urls && urls[index]) {
            return (
              <div
                key={urls[index]}
                className="group relative cursor-pointer"
                style={calculateImageDimensions(aspectRatio, numberOfImages)}
              >
                <img
                  src={urls[index]}
                  alt="Generated image"
                  onClick={() =>
                    setModal({ content: "imagePreview", urls, index })
                  }
                  className={twMerge(
                    "h-full w-full select-none rounded-lg object-contain group-hover:brightness-75"
                  )}
                />
                <a
                  href={urls[index]}
                  download
                  className={twMerge(
                    "absolute right-1 top-1 hidden rounded-lg p-1 hover:bg-[#00000080] group-hover:block"
                  )}
                >
                  <Download sx={{ color: "white" }} />
                </a>
              </div>
            );
          } else {
            return (
              <div
                key={`image${index}`}
                style={calculateImageDimensions(aspectRatio, numberOfImages)}
                className={twMerge(
                  "flex items-center justify-center rounded-lg bg-slate-200",
                  generating && "animate-pulse"
                )}
              >
                <ImageOutlined
                  sx={{ fontSize: numberOfImages > 1 ? "50px" : "100px" }}
                />
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}
