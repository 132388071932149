import { ApiDate } from 'apiTypes';
import z from 'zod';
import type { LlmName } from '../../ai/llmMeta';
import { LlmNames } from '../../ai/llmMeta';
import { Message } from './message/messageTypes';

/**
 * Enum for chat model override settings.
 * Includes all options from ApiTextModelEnum and an additional 'automatic' option
 * which allows the system to select the best model automatically.
 */
export const ModelOverride = z
  .enum(['automatic', ...LlmNames] as const)
  .catch(() => 'gpt-4o-mini' as const satisfies LlmName);

export type ModelOverride = z.infer<typeof ModelOverride>;

export const Chat = z.object({
  id: z.string(),
  name: z.string().nullable(),
  createdAt: ApiDate,
  updatedAt: ApiDate,
  hidden: z.boolean(),
  modelOverride: ModelOverride.nullable(),
  organizationId: z.string(),
  customSystemPromptSuffix: z.string().nullable(),
  ragMode: z.boolean(),
  creditWarningAccepted: z.boolean(),
});

export type Chat = z.infer<typeof Chat>;

export const ChatCreateInput = Chat.omit({
  id: true,
  organizationId: true,
  createdAt: true,
  updatedAt: true,
})
  .extend({
    messages: Message.pick({
      content: true,
      fromAi: true,
    }).array(),
  })
  .partial();
