import { AppRegistration, Check, Close, ExpandMore } from "@mui/icons-material";
import { Box, Card, LinearProgress, Stack, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";
import type {
  ExerciseDetails,
  ExerciseWithCompletion,
} from "../../lib/api/learning";
import { useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";
import Collapse from "@mui/material/Collapse";
import { useNavigate, useParams } from "../../router.ts";

interface ExerciseOverviewProps {
  exercises: ExerciseDetails[];
  exerciseInView: ExerciseWithCompletion;
  className?: string;
}

type ExerciseListProps = ExerciseOverviewProps & {
  onClick: (id: number) => void;
};

export default function ExerciseOverview({
  exercises,
  exerciseInView,
  className,
}: ExerciseOverviewProps) {
  const [expanded, setExpanded] = useState<boolean>(false);

  const { t } = useTranslation();

  const navigate = useNavigate();
  const { workshopId, organizationId, courseId } = useParams(
    "/:organizationId/learn/:workshopId/course/:courseId/exercise/:exerciseId"
  );

  const [pre, text] = useMemo(() => {
    const { title } = exerciseInView;
    const colonIndex = title.indexOf(":");
    return [title.substring(0, colonIndex), title.substring(colonIndex + 1)];
  }, [exerciseInView]);

  const { percentage, currentIndex } = useMemo(() => {
    const count = exercises.filter(({ exercise }) => exercise.completed).length;

    return {
      percentage: Math.floor(
        exercises.length > 0 ? (count / exercises.length) * 100 : 0
      ),
      currentIndex: exercises.findIndex(
        ({ exercise: { id } }) => id === exerciseInView.id
      ),
    };
  }, [exercises, exerciseInView]);

  const handleCourseNavigation = (id: number) => {
    navigate(
      `/:organizationId/learn/:workshopId/course/:courseId/exercise/:exerciseId`,
      {
        params: {
          workshopId: workshopId,
          organizationId: organizationId,
          courseId: courseId,
          exerciseId: String(id),
        },
      }
    );
  };

  return (
    <Box
      onMouseEnter={() => setExpanded(true)}
      onMouseLeave={() => setExpanded(false)}
      onClick={() => setExpanded((prev) => !prev)}
      sx={{
        position: "relative",
        alignSelf: "stretch",
        gridArea: "stepper",
        cursor: "pointer",
        flex: 1,
        bgcolor: "#E5E9ED",
        px: 2,
        pt: 1.5,
        pb: 1.5,
        borderRadius: 10,
      }}
      className={twMerge("space-y-2 border border-neutral-500/10", className)}
    >
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <Typography level="title-sm" fontWeight="700">
          {t("taskOutOf", {
            current: currentIndex + 1,
            total: exercises.length,
          })}
        </Typography>
        <ExpandMore sx={{ rotate: expanded ? "180deg" : "0deg" }} />
      </Stack>
      <div className="flex items-center space-x-2">
        <Typography>
          <Typography color="neutral" level="body-md">
            {`${pre}${pre ? ":" : ""}`}
          </Typography>
          <Typography
            color="neutral"
            fontWeight="500"
            textColor="common.black"
            level="body-md"
          >
            {text}
          </Typography>
        </Typography>
      </div>
      <div className="relative pt-2">
        <LinearProgress
          determinate
          sx={{ backgroundColor: "rgb(144, 238, 144, .4)" }}
          value={percentage}
          color="success"
          variant="soft"
        />
        <Typography
          color="success"
          level="body-xs"
          fontWeight="700"
          sx={{ position: "absolute", right: 0, bottom: 12 }}
        >
          {`${percentage}%`}
        </Typography>
      </div>

      <Collapse
        sx={{ position: "absolute", cursor: "default" }}
        in={expanded}
        timeout={100}
      >
        <ExerciseList
          exercises={exercises}
          exerciseInView={exerciseInView}
          onClick={handleCourseNavigation}
        />
      </Collapse>
    </Box>
  );
}

enum TaskStatus {
  DONE = "done",
  IN_PROGRESS = "inProgress",
  OPEN = "open",
}

const exerciseData = {
  done: {
    icon: <Check sx={{ fill: "white", fontSize: 16 }} />,
    color: "bg-green-600",
  },
  inProgress: {
    icon: <AppRegistration sx={{ fill: "white", fontSize: 16 }} />,
    color: "bg-blue-700",
  },
  open: {
    icon: <Close sx={{ opacity: 0.5, fontSize: 16 }} />,
    color: "bg-neutral-300",
  },
};

function ExerciseList({
  exercises,
  exerciseInView,
  onClick,
}: ExerciseListProps) {
  const nextOpenExerciseIndex = useMemo(
    () => exercises.findIndex(({ exercise }) => !exercise.completed),
    [exercises]
  );

  return (
    <Card
      sx={{ padding: 1 }}
      className="no-scrollbar z-20 max-h-[30vh] flex-col overflow-auto rounded-md bg-white"
    >
      {exercises.map(({ exercise, id }, index) => {
        const {
          completed,
          title,
          id: exerciseId,
        } = exercise as ExerciseWithCompletion;

        let status: TaskStatus = TaskStatus.OPEN;
        if (completed) status = TaskStatus.DONE;
        if (index === nextOpenExerciseIndex) status = TaskStatus.IN_PROGRESS;
        const disabled = status === TaskStatus.OPEN;
        const { color, icon } = exerciseData[status];

        const colonIndex = title.indexOf(":");
        const [pre, text] = [
          title.substring(0, colonIndex),
          title.substring(colonIndex + 1),
        ];

        const isInView = exercise.id === exerciseInView.id;

        return (
          <Box
            onClick={() => !disabled && onClick(exerciseId)}
            key={id}
            className={twMerge(
              "flex cursor-default space-x-2 rounded-md p-2",
              !disabled && "cursor-pointer hover:bg-black/5"
            )}
          >
            <div className="flex w-8  justify-center ">
              <div
                className={twMerge(
                  "flex h-6 w-6 items-center justify-center rounded-full",
                  color,
                  isInView &&
                    "p-0 outline outline-2 outline-offset-2 outline-blue-500"
                )}
              >
                {icon}
              </div>
            </div>
            <Typography sx={{ opacity: disabled ? 0.5 : 1 }}>
              <Typography color="neutral" level="body-md">
                {`${index + 1}. ${pre}${pre ? ":" : ""}`}
              </Typography>
              <Typography
                color="neutral"
                textColor="common.black"
                level="body-md"
                fontWeight="500"
              >
                {text}
              </Typography>
            </Typography>
          </Box>
        );
      })}
    </Card>
  );
}
