import { Typography } from "@mui/joy";
import { Stack } from "@mui/system";
import type { SxProps, Theme } from "@mui/system";

export function SettingsPage({
  title,
  subtitle,
  actions,
  children,
  sx,
}: {
  title: React.ReactNode;
  subtitle?: string;
  actions?: React.ReactNode;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}) {
  return (
    <Stack spacing={3} pb={10} sx={sx}>
      <div className="flex justify-between">
        <Stack spacing={2}>
          <Typography level="h3">{title}</Typography>
          {subtitle && (
            <Typography color="neutral" className="!mb-4 !mt-2">
              {subtitle}
            </Typography>
          )}
        </Stack>
        <div>{actions}</div>
      </div>
      {children}
    </Stack>
  );
}
