import { create } from "zustand";
import { persist } from "zustand/middleware";

interface AuthStore {
  loggedIn: boolean;
  setLoggedIn: (loggedIn: boolean) => void;
}

export const useAuthStore = create(
  persist<AuthStore>(
    (set) => ({
      loggedIn: false,
      setLoggedIn: (loggedIn) => set({ loggedIn }),
    }),
    {
      name: "auth-store",
    }
  )
);
