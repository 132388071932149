import type { Locale } from "date-fns";
import { format } from "date-fns";
import { de, enUS, es, fr, it } from "date-fns/locale";
import type { Locales } from "../i18n.ts";
import { useTranslation } from "../i18n.ts";

const localeMap: Record<Locales, Locale> = {
  en: enUS,
  de: de,
  it: it,
  fr: fr,
  es: es,
};

export function useDateFormatter(): typeof format {
  const { i18n } = useTranslation();
  const localeName: Locales = (i18n.resolvedLanguage as Locales) ?? "en";
  const locale = localeMap[localeName];
  return (date, f, options) =>
    format(date, f, {
      ...options,
      locale,
    });
}
