import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Textarea,
  Typography,
} from "@mui/joy";

export function ConnectorEditModal({
  name,
  description,
  onChange,
  open,
  onClose,
}: {
  name: string;
  description: string;
  onChange: (name: string, description: string) => void;
  open: boolean;
  onClose: VoidFunction;
}) {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog>
        <ModalClose />
        <Typography level="h3">Quelle bearbeiten</Typography>
        <form
          onSubmit={(e) => {
            e.preventDefault();

            const formData = new FormData(e.target as HTMLFormElement);
            onChange(
              formData.get("name") as string,
              formData.get("description") as string
            );

            onClose();
          }}
        >
          <div className="flex flex-col gap-4">
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input defaultValue={name} name="name" />
            </FormControl>
            <FormControl>
              <FormLabel>Beschreibung</FormLabel>
              <Textarea
                minRows={3}
                maxRows={10}
                defaultValue={description}
                name="description"
              />
            </FormControl>
            <Button type="submit" variant="outlined">
              Speichern
            </Button>
          </div>
        </form>
      </ModalDialog>
    </Modal>
  );
}
