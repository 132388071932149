import { Box, Typography, styled } from "@mui/joy";
import { parseInt } from "lodash";
import { useTranslation } from "../../lib/i18n";
import {
  useSuccessColor,
  useWarningColor,
  useDangerColor,
} from "../../lib/hooks/useTheme";

export function PromptingFeedbackAcademy({
  promptEvaluations,
}: {
  promptEvaluations: {
    rating: string;
    comment: string;
  }[];
}) {
  const { t } = useTranslation();

  // from 1 to 3: danger, warning, success
  const ratingColors = [useDangerColor(), useWarningColor(), useSuccessColor()];
  const feedbackTitles = [
    t("promptingAssistant.assumption"),
    t("promptingAssistant.task"),
    t("promptingAssistant.output"),
  ];

  const Circle = styled("div")({
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    display: "inline-block",
    margin: "1.5px 3px 0px 0px",
  });

  const TooltipContent = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  }));

  return (
    <Box display="flex" alignItems="center" position="relative">
      <TooltipContent>
        {promptEvaluations.map((res, index) => (
          <div className="my-2.5" key={index}>
            <div key={index} className="flex">
              <div className="col">
                <Circle
                  style={{
                    backgroundColor: ratingColors[parseInt(res.rating) - 1],
                  }}
                />
              </div>

              <div className="col ml-1">
                <Typography level="title-md">
                  {feedbackTitles[index]}
                </Typography>
              </div>
            </div>
            <Typography level="body-sm">{res.comment}</Typography>
          </div>
        ))}
      </TooltipContent>
    </Box>
  );
}
