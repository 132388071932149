import { BackHand } from "@mui/icons-material";
import {
  Card,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/joy";
import { useCallback, useEffect, useState } from "react";
import { BounceLoader } from "react-spinners";
import { toast } from "react-toastify";
import { useCaptureAnalyticsEvent } from "../../lib/api/learning.ts";
import { useWorkshop } from "../../lib/api/workshop.ts";
import {
  useRootApi,
  useRootResource,
  useRootSWR,
} from "../../lib/hooks/useApi";
import { useTranslation } from "react-i18next";

export function WorkshopCountDown({ target }: { target: number }) {
  const [time, setTime] = useState(target - Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(target - Date.now());
    }, 1000);

    return () => clearInterval(interval);
  }, [target]);

  const flooredTime = Math.max(0, time);

  const minutes = Math.floor(flooredTime / 60000);
  const seconds = Math.floor((flooredTime % 60000) / 1000);

  return (
    <>
      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
    </>
  );
}

export function HelpHand({ workshopId }: { workshopId: string }) {
  const currentHelp = useRootResource(`/workshops/${workshopId}/help`, {
    refreshInterval: 5000,
  });
  const mutateCurrentHelp = useRootSWR(`/workshops/${workshopId}/help`).mutate;
  const hasHelp = !!currentHelp;
  const api = useRootApi();

  const { t } = useTranslation();

  const dismissToast = useCallback(() => {
    toast.dismiss("help");
  }, []);

  useEffect(() => {
    if (!hasHelp) {
      dismissToast();
    }
  }, [hasHelp, dismissToast]);

  const cancel = () => {
    void captureAnalyticsEvent("HELP_REQUEST_RESOLVED");
    api
      .delete(`/workshops/${workshopId}/help`)
      .catch(console.error)
      .finally(async () => {
        dismissToast();
        await mutateCurrentHelp();
        toast.success(t("helpRequestWithdrawn"));
      });
  };

  const captureAnalyticsEvent = useCaptureAnalyticsEvent();

  const getHelp = () => {
    void captureAnalyticsEvent("HELP_REQUESTED");
    api
      .post(`/workshops/${workshopId}/help`)
      .then(() => {
        //TODO translate!
        toast.warn(t("tutorIsOnTheWayToast"), {
          autoClose: false,
          isLoading: true,
          toastId: "help",
          closeOnClick: false,
          closeButton: false,
          draggable: false,
          icon: <BackHand />,
          theme: "colored",
        });
      })
      .catch(console.error)
      .finally(async () => {
        await mutateCurrentHelp();
      });
  };

  return (
    <Tooltip title={hasHelp ? t("cancel") : t("requestHelp")}>
      <IconButton
        color={hasHelp ? "warning" : "success"}
        variant="soft"
        size="sm"
        sx={{ border: 1, borderColor: "white", py: 1, px: 2 }}
        onClick={hasHelp ? cancel : getHelp}
      >
        <div className="flex flex-row items-center gap-2">
          {hasHelp ? <CircularProgress /> : <BackHand fontSize="small" />}
          {hasHelp ? t("tutorIsOnTheWay") : t("callTutor")}
        </div>
      </IconButton>
    </Tooltip>
  );
}

export function LiveWorkshopCard({ workshopId }: { workshopId: string }) {
  const workshop = useWorkshop(workshopId);

  return (
    <Card size="sm" sx={{ borderRadius: 10 }}>
      <div className="flex h-full flex-col items-center justify-between space-y-3">
        <div className="flex items-center space-x-2 px-4 py-1">
          <Typography fontWeight="600" sx={{ color: "red", opacity: 0.7 }}>
            Live Workshop
          </Typography>
          <BounceLoader color="red" size={12} />
        </div>

        <div className="flex space-x-1">
          <div className="ml-1">
            <HelpHand workshopId={workshopId} />
          </div>
          {workshop?.currentTask && (
            <div className="flex items-center space-x-2 rounded-lg border px-3">
              <Typography
                color="neutral"
                className="line-clamp-1"
              >{`${workshop?.currentTask}:`}</Typography>
              <Typography
                fontWeight="bold"
                sx={{ minWidth: 50, flex: 1, textAlign: "center" }}
              >
                <WorkshopCountDown
                  target={
                    new Date(workshop?.currentTaskTimer ?? 0).getTime() ?? 0
                  }
                />
              </Typography>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
}
