import { Alert, Button, Card, Typography } from "@mui/joy";

import { AuthLayout } from "../components/auth/AuthLayout";
import { useSearchParams } from "react-router-dom";
import { trpc } from "../lib/api/trpc/trpc";
import { DelayedLoader } from "../components/util/DelayadLoader";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "../router";
import { CustomLogoWithFallback } from "../components/branding/BrandedLogo";
import { useAuthStore } from "../lib/context/authStore";

export default function InvitesScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // join token logic
  const [params] = useSearchParams();
  const joinToken = params.get("joinToken");
  const validQuery = trpc.organizationInvites.checkInviteTokenValid.useQuery({
    joinToken: joinToken ?? "",
  });

  const consumeTokenMutation =
    trpc.organizationInvites.consumeInviteToken.useMutation();

  const loggedIn = useAuthStore((s) => s.loggedIn);

  const setReturnUrlAndNavigate = (subPage: string) => {
    localStorage.setItem(
      "returnUrl",
      window.location.pathname + window.location.search
    );
    navigate({
      pathname: "/auth",
      search: `?page=${subPage}`,
    });
  };

  if (validQuery.isLoading) {
    return <DelayedLoader />;
  }

  return (
    <AuthLayout>
      <Card>
        {validQuery.data?.valid ? (
          <>
            <CustomLogoWithFallback
              className="h-8 px-10 py-5"
              customSource={validQuery.data.organization.logoUrl}
              style={{ width: "100%", height: "auto" }}
            />
            <Typography level="h4" textAlign="center">
              {t("invites.joinOrganization", {
                organization: validQuery.data.organization.name,
              })}
            </Typography>

            {!loggedIn && (
              <Alert color="warning" className="my-5">
                <div className="flex flex-col items-center gap-4">
                  <p>{t("invites.loginToJoin")}</p>
                  <div className="flex flex-row gap-2">
                    <Button
                      onClick={() => {
                        setReturnUrlAndNavigate("signup");
                      }}
                    >
                      {t("signUp")}
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setReturnUrlAndNavigate("login");
                      }}
                    >
                      {t("login")}
                    </Button>
                  </div>
                </div>
              </Alert>
            )}

            <Button
              disabled={!loggedIn}
              onClick={async () => {
                if (!validQuery.data.valid) return;
                await consumeTokenMutation.mutateAsync({
                  joinToken: joinToken ?? "",
                });
                navigate("/:organizationId/onboarding", {
                  params: {
                    organizationId: validQuery.data.organization.id,
                  },
                });
              }}
            >
              {t("invites.accept")}
            </Button>
          </>
        ) : (
          <>
            <Typography level="h4">{t("errors.invalidJoinToken")}</Typography>
            <Link to="/">
              <Button>{t("back")}</Button>
            </Link>
          </>
        )}
      </Card>
    </AuthLayout>
  );
}
