import { Help } from "@mui/icons-material";
import {
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  Table,
  Tooltip,
  Typography,
} from "@mui/joy";
import { useTranslation } from "../../lib/i18n.ts";
import { DocumentDropzone } from "../chat/attachments/DocumentDropzone.tsx";
import type { UpdateWorkflowInput } from "../../../../backend/src/api/workflow/workflowRouter.ts";
import { trpc } from "../../lib/api/trpc/trpc.ts";
import { InputRow } from "./InputRow.tsx";
import type { WorkflowInput } from "../../../../backend/src/api/workflow/workflowTypes.ts";

export function WorkflowInputEditor({
  variablesInText,
  currentValues,
  updateDocumentIds,
  updateAllowUploads,
  updateInput,
  deleteInput,
}: {
  variablesInText: string[];
  currentValues: UpdateWorkflowInput;
  updateDocumentIds: (documentIds: { id: string }[]) => void;
  updateAllowUploads: (allowUploads: boolean) => void;
  updateInput: (input: WorkflowInput) => void;
  deleteInput: (input: WorkflowInput) => void;
}) {
  const { t } = useTranslation();

  const { data: documentIntelligenceEnabled } =
    trpc.tools.documentIntelligence.isEnabled.useQuery();

  const inputs = currentValues.inputs ?? [];

  const includedDocumentIds =
    currentValues.includedDocuments?.map((doc) => doc.id) ?? [];

  return (
    <div className="flex flex-col">
      <Typography level="h3">Inputs</Typography>

      <Typography color="neutral" mb={2}>
        {t("workflowEditor.inputsDescription")}
      </Typography>
      <Card sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        {documentIntelligenceEnabled && (
          <>
            <Stack>
              <Typography level="h4">
                {t("workflowEditor.documents")}
              </Typography>
              <Divider />
            </Stack>
            <FormControl>
              <FormLabel>{t("workflowEditor.includedDocuments")}</FormLabel>
              <div>
                <DocumentDropzone
                  documentIds={includedDocumentIds}
                  setDocumentIds={(e) => {
                    // e is a dispatch, it might be a function
                    const docIds =
                      typeof e === "function" ? e(includedDocumentIds) : e;
                    updateDocumentIds(docIds.map((id) => ({ id })));
                  }}
                  allowMultiple
                />
                <FormHelperText>
                  {t("workflowEditor.includedDocumentsTooltip")}
                </FormHelperText>
              </div>
            </FormControl>
            <FormControl>
              <Checkbox
                label={<div>{t("workflowEditor.allowDocumentUpload")}</div>}
                checked={currentValues?.allowDocumentUpload}
                onChange={(e) => updateAllowUploads(e.target.checked)}
              />
              <FormHelperText sx={{ ml: 0 }}>
                {t("workflowEditor.documentsTooltip")}
              </FormHelperText>
            </FormControl>
          </>
        )}
        <Stack>
          <Typography level="h4">{t("workflowEditor.variables")}</Typography>
          <Divider />
        </Stack>
        <Table>
          <thead>
            <tr>
              <th
                style={{
                  width: "12%",
                }}
              >
                {t("workflowEditor.variableId")}
              </th>
              <th className="w-1/5">{t("name")}</th>
              <th
                style={{
                  width: "15%",
                }}
              >
                {t("type")}
              </th>
              <th
                style={{
                  width: "30%",
                }}
              >
                {t("workflowEditor.options")}
              </th>
              <th className="w-1/5">
                <div className="flex gap-1">
                  {t("placeholder")}
                  <Tooltip
                    title={t("workflowEditor.placeholderTooltip")}
                    placement="top"
                    sx={{ maxWidth: "400px" }}
                  >
                    <Help sx={{ fontSize: "16px" }} />
                  </Tooltip>
                </div>
              </th>
              <th
                style={{
                  width: "5%",
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            {inputs.map((input) => (
              <InputRow
                key={input.key}
                input={input}
                isUsed={variablesInText.includes(input.key)}
                onDelete={() => {
                  deleteInput(input);
                }}
                setInput={updateInput}
              />
            ))}
            {inputs.length == 0 && (
              <tr>
                <td colSpan={3}>
                  <Typography level="body-sm" color="neutral">
                    {t("workflowEditor.noVariables")}
                  </Typography>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card>
    </div>
  );
}
