import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from "@mui/joy";
import { Form, Formik } from "formik";
import type { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { KnowledgeCollection } from "../../../../../backend/src/api/rag/knowledgeCollection/knowledgeCollectionTypes.ts";
import { trpc } from "../../../lib/api/trpc/trpc.ts";
import { FormikSwitch } from "../../forms/FormikSwitch.tsx";
import { FormikTextarea } from "../../forms/FormikTextArea.tsx";
import { FormikTextField } from "../../forms/FormikTextField.tsx";
import { DataSourcesEditor } from "./sources/DataSourcesEditor.tsx";

function SettingsTabPanel({
  value,
  children,
}: PropsWithChildren<{ value: number }>) {
  return (
    <TabPanel value={value}>
      <div className="flex flex-col gap-4">{children}</div>
    </TabPanel>
  );
}

export function KnowledgeCollectionEditor({
  onSubmit,
  collection,
}: {
  onSubmit: (values: KnowledgeCollection) => void;
  collection: KnowledgeCollection;
}) {
  const { t } = useTranslation();

  const { data: departments } = trpc.organization.department.all.useQuery();

  return (
    <div>
      <Tabs sx={{ background: "transparent" }}>
        <TabList>
          <Tab>{t("knowledgeBase.baseSettings")}</Tab>
          <Tab>{t("knowledgeBase.sources")}</Tab>
          <Tab>{t("knowledgeBase.permissions")}</Tab>
          <Tab>{t("knowledgeBase.searchConfig.title")}</Tab>
        </TabList>
        <Formik
          initialValues={collection}
          validationSchema={toFormikValidationSchema(KnowledgeCollection)}
          onSubmit={onSubmit}
          validateOnMount={true}
        >
          {({ values, isSubmitting, setFieldValue }) => (
            <Form className="flex flex-col gap-4 overflow-hidden">
              <SettingsTabPanel value={0}>
                <FormikTextField name="name" label={t("name")} required />
                <FormikTextarea
                  name="description"
                  label={t("description")}
                  minRows={3}
                  helperText={t("knowledgeBase.descriptionHelper")}
                  required
                />
              </SettingsTabPanel>

              <SettingsTabPanel value={1}>
                <DataSourcesEditor knowledgeCollectionId={collection.id} />
              </SettingsTabPanel>

              <SettingsTabPanel value={2}>
                <FormControl>
                  <div className="flex flex-col gap-4">
                    <FormLabel>
                      {t("knowledgeBase.assignedDepartments")}
                    </FormLabel>
                    <Autocomplete
                      multiple
                      options={departments ?? []}
                      getOptionLabel={(option) =>
                        departments?.find((d) => d.id === option.id)?.name ?? ""
                      }
                      getOptionKey={(option) => option.id}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      value={values.departments}
                      onChange={(e, value) =>
                        setFieldValue("departments", value)
                      }
                    />
                    <FormHelperText>
                      {t("knowledgeBase.assignedDepartmentsHelper")}
                    </FormHelperText>
                  </div>
                </FormControl>
              </SettingsTabPanel>
              <SettingsTabPanel value={3}>
                Disabled because hybrid search is not implement
                <FormikSwitch
                  name="ragSettings.search.useHybridSearch"
                  label={t("knowledgeBase.searchConfig.doHybridSearch")}
                  helperText={t(
                    "knowledgeBase.searchConfig.hybridSearchHelper"
                  )}
                />
                <FormikTextField
                  name="ragSettings.search.searchLimit"
                  label={t("knowledgeBase.searchConfig.searchLimit")}
                  // helperText={t("knowledgeBase.searchConfig.searchLimitHelper")}
                  type="number"
                  slotProps={{
                    input: {
                      min: 1,
                      max: 100,
                      step: 1,
                    },
                  }}
                />
                {/*<FormikTextField
                  name="ragSettings.search.minScore"
                  label={t("knowledgeBase.searchConfig.minScore")}
                  // helperText={t("knowledgeBase.searchConfig.minScoreHelper")}
                  type="number"
                  slotProps={{
                    input: {
                      min: 0,
                      max: 1,
                      step: 0.05,
                    },
                  }}
                />*/}
                <div className="my-1" />
                <FormikSwitch
                  name="ragSettings.reranking.enabled"
                  label={t("knowledgeBase.searchConfig.useReranking")}
                  helperText={t("knowledgeBase.searchConfig.rerankingHelper")}
                />
                <FormikTextField
                  disabled={!values.ragSettings?.reranking?.enabled}
                  name="ragSettings.reranking.topN"
                  label={t("knowledgeBase.searchConfig.rerankLimit")}
                  // helperText={t("knowledgeBase.searchConfig.rerankLimitHelper")}
                  type="number"
                  slotProps={{
                    input: {
                      min: 1,
                      max: 100,
                      step: 1,
                    },
                  }}
                />
                {/*<FormikTextField
                  disabled={!values.ragSettings?.reranking?.enabled}
                  name="ragSettings.reranking.minScore"
                  label={t("knowledgeBase.searchConfig.minScoreAfterRerank")}
                  /*helperText={t(
                  "knowledgeBase.searchConfig.minScoreAfterRerankHelper"
                )}*/
                /*
                  type="number"
                  slotProps={{
                    input: {
                      min: 0,
                      max: 1,
                      step: 0.05,
                    },
                  }}
                />*/}
              </SettingsTabPanel>

              <div className="flex justify-end gap-2">
                <Button type="submit" variant="solid" loading={isSubmitting}>
                  {t("save")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Tabs>
    </div>
  );
}
