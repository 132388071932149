import { FormControl } from "@mui/base";
import { Download } from "@mui/icons-material";
import { Button, FormLabel, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { type ImageModal } from "../../pages/[organizationId]/tools/imageFactory";
import { CarouselControllers } from "./CarouselControllers";

export function ImagePreviewModalContent({
  modal,
  setModal,
}: {
  modal: ImageModal;
  setModal: (modal: ImageModal) => void;
}) {
  const { t } = useTranslation();
  if (!modal.urls) {
    return null;
  }
  const imageIndex = modal.index ?? 0;

  return (
    <>
      <div className="relative flex justify-center">
        <img
          src={modal.urls[imageIndex]}
          className={twMerge(
            "max-w-[90vw] rounded-md",
            modal.prompt ? "max-h-[70vh]" : "max-h-[80vh]"
          )}
        />
        <CarouselControllers
          imageIndex={imageIndex}
          imageUrls={modal.urls}
          setImageIndex={(index: number) => setModal({ ...modal, index })}
        />
      </div>
      {modal.prompt && (
        <FormControl>
          <FormLabel>{t("prompt")}:</FormLabel>
          <Typography className="max-h-20 cursor-default overflow-auto rounded-md border border-gray-300 px-3 py-2">
            {t(`images.styles.${modal.style}`)}: {modal.prompt}
          </Typography>
        </FormControl>
      )}
      <div className="flex w-full justify-end">
        <a href={modal.urls[imageIndex]} download>
          <Button size="sm" startDecorator={<Download />}>
            {t("download")}
          </Button>
        </a>
      </div>
    </>
  );
}
