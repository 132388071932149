import { Add } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/joy";
import type { MouseEvent, ReactNode } from "react";
import { toast } from "react-toastify";
import { trpc } from "../../../lib/api/trpc/trpc.ts";
import { useTranslation } from "../../../lib/i18n";
import { useNavigate, useParams } from "../../../router";

export function NewWorkflowButton({
  children,
  onCreated,
}: {
  children?: ReactNode;
  onCreated?: () => void;
}) {
  const { t } = useTranslation();
  const { mutateAsync: createWorkflow } = trpc.workflows.create.useMutation();
  const navigate = useNavigate();
  const params = useParams("/:organizationId");

  const { data: personalDepartment } =
    trpc.organization.department.personal.useQuery();
  const utils = trpc.useUtils();

  function onClick(e: MouseEvent) {
    e.stopPropagation();
    createWorkflow({
      workflow: {
        name: t("unnamedWorkflow"),
        departmentId: personalDepartment?.id ?? "",
      },
    })
      .then(async (workflow) => {
        await utils.organization.department.invalidate();
        navigate(`/:organizationId/workflows/:workflowId`, {
          params: {
            ...params,
            workflowId: workflow.id,
          },
        });
        toast.success(t("createdWorkflow"));
      })
      .catch((err) => {
        console.error(err);
        toast.error(t("createWorkflowFailed"));
      });
    onCreated?.();
  }

  return (
    <>
      {children ? (
        <div onClick={onClick}>{children}</div>
      ) : (
        <Tooltip title={t("unnamedWorkflow")}>
          <IconButton onClick={onClick}>
            <Add fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}
