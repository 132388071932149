import ClearIcon from "@mui/icons-material/Clear";
import { Button, IconButton, Switch, Table, Typography } from "@mui/joy";
import { useState } from "react";
import { toast } from "react-toastify";
import type { DepartmentUser } from "../../../../../backend/src/api/organization/department/departmentTypes.ts";
import { trpc } from "../../../lib/api/trpc/trpc.ts";
import { useUser } from "../../../lib/api/user";
import { useTranslation } from "../../../lib/i18n";
import { DelayedLoader } from "../../util/DelayadLoader";
import { UserSearch } from "../../util/user/UserSearch";

export function DepartmentUserEditor({
  departmentId,
}: {
  departmentId: string;
}) {
  const { t } = useTranslation();
  const { data: users } = trpc.organization.department.user.all.useQuery({
    departmentId: departmentId,
  });

  if (!users) return <DelayedLoader />;

  return (
    <div className="flex flex-col gap-6">
      <Typography level="title-md">{t("manageUsers")}</Typography>

      <UserAdder departmentId={departmentId} />

      <div className="max-h-96 overflow-y-auto">
        <Table stickyHeader>
          <thead>
            <tr>
              <th>{t("name")}</th>
              <th>E-Mail</th>
              <th
                style={{
                  width: "20%",
                }}
              >
                {t("writePermissions")}
              </th>
              <th
                style={{
                  width: "5%",
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            {users?.map((user) => (
              <UserRow
                user={user}
                key={user.userId}
                departmentId={departmentId}
              />
            ))}
            {users?.length === 0 && (
              <tr>
                <td colSpan={3}>{t("noUsersFound")}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

function UserRow({
  user: departmentUser,
  departmentId,
}: {
  user: DepartmentUser;
  departmentId: string;
}) {
  const { t } = useTranslation();

  const user = useUser(departmentUser.userId);
  const { mutateAsync: updateUser } =
    trpc.organization.department.user.update.useMutation();
  const { mutateAsync: removeUser } =
    trpc.organization.department.user.remove.useMutation();

  const utils = trpc.useUtils();

  function invalidateUsers() {
    return utils.organization.department.invalidate();
  }

  const setWritePermission = (value: boolean) => {
    toast
      .promise(
        updateUser({
          departmentId,
          userId: departmentUser.userId,
          writePermission: value,
        }),
        {
          success: t("permissionsUpdated"),
          error: t("permissionsUpdateFailed"),
        }
      )
      .then(invalidateUsers)
      .catch(console.error);
  };

  const onRemoveUser = () => {
    toast
      .promise(
        removeUser({
          departmentId,
          userId: departmentUser.userId,
        }),
        {
          success: t("userRemoved"),
          error: t("userRemoveFailed"),
        }
      )
      .then(invalidateUsers)
      .catch(console.error);
  };

  if (!user) return <DelayedLoader />;

  return (
    <tr>
      <td>
        {user.firstName} {user.lastName}
      </td>
      <td>{user.primaryEmail}</td>
      <td>
        <SegmentSwitch
          writePermission={departmentUser.writePermission}
          setWritePermission={setWritePermission}
        />
      </td>
      <td className="flex flex-row items-center justify-end">
        <IconButton onClick={onRemoveUser}>
          <ClearIcon />
        </IconButton>
      </td>
    </tr>
  );
}

function SegmentSwitch({
  writePermission,
  setWritePermission,
}: {
  writePermission: boolean;
  setWritePermission: (value: boolean) => void;
}) {
  return (
    <Switch
      checked={writePermission}
      onChange={(e) => {
        setWritePermission(e.target.checked);
      }}
    />
  );
}

function UserAdder({ departmentId }: { departmentId: string }) {
  const { t } = useTranslation();

  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const { mutateAsync: addUser } =
    trpc.organization.department.user.add.useMutation();
  const { data: users } = trpc.organization.department.user.all.useQuery({
    departmentId,
  });

  const utils = trpc.useUtils();

  const onAddUser = () => {
    toast
      .promise(
        addUser({
          departmentId,
          userId: selectedUserId!,
        }),
        {
          success: t("userAdded"),
          error: t("userAddFailed"),
        }
      )
      .then(() => {
        setSelectedUserId(null);
        return utils.organization.department.invalidate();
      })
      .catch(console.error);
  };

  return (
    <div className="flex flex-row gap-2">
      <UserSearch
        blacklist={users?.map((u) => u.userId)}
        selectedUserId={selectedUserId}
        setSelectedUserId={setSelectedUserId}
      />
      <Button disabled={!selectedUserId} onClick={onAddUser}>
        {t("add")}
      </Button>
    </div>
  );
}
