import { Typography } from "@mui/joy";
import { useEffect } from "react";
import { useNavigate as useReactNavigate } from "react-router-dom";
import { trpc } from "../../lib/api/trpc/trpc";
import { useMe } from "../../lib/api/user";
import { useAuthStore } from "../../lib/context/authStore";
import { useTranslation } from "../../lib/i18n";
import { useNavigate } from "../../router";
import { DelayedLoader } from "../util/DelayadLoader";
import { AuthDialog } from "./AuthDialog";
import { AuthLayout } from "./AuthLayout";
import { OrganizationSelector } from "./OrganizationSelector";

export function AuthScreen() {
  const loggedIn = useAuthStore((s) => s.loggedIn);
  const { data: organizations, isLoading } =
    trpc.organization.getAllOrganizations.useQuery();

  const navigate = useNavigate();
  const reactNavigate = useReactNavigate();

  useEffect(() => {
    const returnUrl = localStorage.getItem("returnUrl");
    if (returnUrl && loggedIn) {
      // if user was redirected to login page, redirect back to the original page
      reactNavigate(returnUrl);
      localStorage.removeItem("returnUrl");
    }
  }, [loggedIn, reactNavigate]);

  useEffect(() => {
    if (!loggedIn || organizations == null) return;
    const orgCount = organizations.length ?? 0;
    if (orgCount === 1) {
      // if logged in and only one organization, redirect to that organization
      navigate("/:organizationId", {
        params: {
          organizationId: organizations[0].id,
        },
        replace: true,
      });
    }
  }, [organizations, loggedIn, navigate]);

  if (loggedIn && isLoading) {
    return <DelayedLoader />;
  }

  return (
    <AuthLayout>
      <AuthScreenContent />
    </AuthLayout>
  );
}

function AuthScreenContent() {
  const { t } = useTranslation();
  const me = useMe();
  const loggedIn = useAuthStore((s) => s.loggedIn);
  const { data: organizations, isLoading } =
    trpc.organization.getAllOrganizations.useQuery();

  if (loggedIn) {
    if (!isLoading) {
      const orgCount = organizations?.length ?? 0;
      if (orgCount > 1) {
        return <OrganizationSelector />;
      } else {
        if (!me) return <DelayedLoader />;
        return (
          <div className="flex flex-col">
            <div className="flex flex-col items-center">
              <Typography level="h2">{t("welcome")}</Typography>
              <Typography>{t("onlyAFewSteps")}</Typography>
            </div>
            <iframe
              style={{
                height: "700px",
              }}
              src={`https://forms.selectcode.dev/s/cls0eph6h00i7htqkmk0biqpq?email=${me.primaryEmail}`}
            ></iframe>
          </div>
        );
      }
    } else {
      return <DelayedLoader />;
    }
  } else {
    return <AuthDialog />; // not logged in
  }
}
