import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  LinearProgress,
  Tab,
  Table,
  TabList,
  TabPanel,
  Tabs,
  Typography,
} from "@mui/joy";
import { tabClasses } from "@mui/joy/Tab";
import Popover from "@mui/material/Popover";
import type { ApiWorkshopParticipantStats } from "apiTypes";
import type { MouseEvent } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DelayedLoader } from "../../../components/util/DelayadLoader.tsx";
import { useWorkshopParticipantsStats } from "../../../lib/api/learning.ts";
import { useParams } from "../../../router.ts";

function EvaluationResultIcon({ passed }: { passed: boolean | null }) {
  if (passed === null) return null;

  return passed ? (
    <CheckCircleIcon color="success" />
  ) : (
    <CancelIcon color="error" />
  );
}

export default function WorkshopView() {
  const params = useParams("/teach/:workshopId");
  const { t } = useTranslation();
  //  const workshop = useWorkshop(params.workshopId);

  const participantStats = useWorkshopParticipantsStats(params.workshopId);
  if (!participantStats) {
    return <DelayedLoader />;
  }

  return (
    <Tabs variant="soft">
      <TabList
        sx={{
          alignSelf: "flex-start",
          p: 0.5,
          gap: 0.5,
          borderRadius: "xl",
          bgcolor: "background.level2",
          [`& .${tabClasses.root}[aria-selected="true"]`]: {
            boxShadow: "sm",
            bgcolor: "background.surface",
          },
        }}
        disableUnderline
      >
        <Tab variant="plain" color="neutral" disableIndicator>
          {t("participants")}
        </Tab>
        <Tab variant="plain" color="neutral" disableIndicator>
          {t("statistics")}
        </Tab>
      </TabList>
      <TabPanel value={0} sx={{ overflowX: "hidden" }}>
        <div className="w-full overflow-x-auto">
          <Table
            size="md"
            hoverRow
            sx={{ whiteSpace: "nowrap", tableLayout: "auto" }}
          >
            <thead>
              <tr>
                <th>{t("name")}</th>
                <th>{t("course")}</th>
                <th className="w-44"></th>
                <th>{t("task")}</th>
                <th className="w-min">{t("attempt")} Nr.</th>
                <th>{t("lastEvaluation")}</th>
                <th>{t("succeded")}</th>
              </tr>
            </thead>
            <tbody>
              {/*TODO: nice loading state*/}

              {(participantStats ?? []).map((stats) => (
                <ParticipantStatsRow key={stats.userId} stats={stats} />
              ))}
            </tbody>
          </Table>
        </div>
      </TabPanel>
      <TabPanel value={1}>{t("comingSoon")}</TabPanel>
    </Tabs>
  );
}

function EvaluationResults({
  evaluations,
}: {
  evaluations: { passed: boolean; taskTitle: string }[];
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openPopover = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <div
        className="flex gap-1"
        onMouseEnter={openPopover}
        onMouseLeave={closePopover}
      >
        {evaluations?.map((evaluation, index) => (
          <EvaluationResultIcon key={index} passed={evaluation.passed} />
        ))}
      </div>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={closePopover}
        disableRestoreFocus
      >
        <div className="flex flex-col gap-3 p-4">
          {evaluations?.map((evaluation, index) => (
            <div key={index} className="flex gap-1">
              <EvaluationResultIcon passed={evaluation.passed} />
              <Typography>{evaluation.taskTitle}</Typography>
            </div>
          ))}
        </div>
      </Popover>
    </>
  );
}

interface CourseParticipantRowProps {
  stats: ApiWorkshopParticipantStats;
}

function ParticipantStatsRow({ stats }: CourseParticipantRowProps) {
  // const navigate = useNavigate();

  // const { organizationId, courseId, workshopId } = useParams(
  //   "/:organizationId/learn/:workshopId/course/:courseId"
  // );

  function handleRowClick() {
    //TODO navigate to details
  }

  const showWarning = stats.attemptCount > 5 && !stats.solved;

  return (
    <tr
      className={"cursor-pointer " + (showWarning ? "bg-amber-200" : "")}
      onClick={handleRowClick}
    >
      <td className="whitespace-nowrap">
        {stats.name}{" "}
        <span className="text-slate-500">{"(" + stats.mail + ")"}</span>
      </td>
      <td>{stats.courseTitle ?? "-"}</td>
      <td>
        <LinearProgress
          determinate
          variant="solid"
          value={stats.courseProgress * 100}
        />
      </td>
      <td>{stats.exerciseTitle ?? "-"}</td>
      <td>{stats.attemptCount ?? "-"} </td>
      <td>
        <EvaluationResults evaluations={stats.lastEvaluation} />
      </td>
      <td>
        {stats.solved ? <EvaluationResultIcon passed={stats.solved} /> : "-"}
      </td>
    </tr>
  );
}
