export const DEFAULT_GUIDELINES = `
**Einleitung**

Die meinGPT KI-Plattform bietet eine zentrale Lösung für den Einsatz von KI-Modellen im Unternehmen. Die Nutzung erfolgt DSGVO-konform und mit der Zusicherung, dass externe Anbieter Anfragen nicht länger als technisch notwendig
speichern. Diese Richtlinien regeln die Nutzung der Plattform durch Mitarbeiter.

Jeder Mitarbeiter ist für die Einhaltung dieser Richtlinien bei der Nutzung der meinGPT Plattform verantwortlich. Bei
Fragen oder Unsicherheiten wenden Sie sich bitte an den IT-Administrator.

**1. Datenverarbeitung**

Die meinGPT Plattform bietet verschiedene KI-Modelle, die auf der Hardware von Infrastrukturpartnern (meist Google oder
Microsoft) betrieben werden. Wir unterscheiden dabei zwischen zwei Modellarten:

- Normale Modelle:
  Behandeln Sie eingegebene Daten wie interne, aber öffentlich zugängliche Dokumente. Die Weitergabe von wichtigen
  Geheimnissen und Passwörtern ist zu vermeiden. Interne Dokumente oder Dokumente aus dem täglichen Gebrauch können
  verwendet werden.
- Experimentelle Modelle:
  Diese Modelle sind immer explizit gekennzeichnet. Sie werden in den USA gehostet, sind jedoch trotzdem DSGVO-konform. Die Weitergabe von wichtigen Geheimnissen,
  Passwörtern oder personenbezogenen Daten ist dennoch nicht akzeptabel.

**2. Korrektheit der generierten Antworten**

Die KI-Modelle erstellen Antworten basierend auf vortrainierten Daten. Insbesondere bei komplexen Anfragen können
inkorrekte Antworten nicht ausgeschlossen werden. Bitte prüfen Sie die Antworten sorgfältig (z.B. durch das googeln relevanter Informationen).

**3. Urheberrecht**

Wenn urheberrechtlich geschützte Inhalte in der Eingabe vorhanden sind oder explizit nach ihnen gefragt wird, kann auch die
Ausgabe urheberrechtlich geschützt sein (z.B. "Gib mir den Song-Text eines Taylor Swift Songs"). Prüfen Sie vor der Veröffentlichung eventuell problematischer Texte durch eine kurze Google-Suche, ob diese
urheberrechtlich geschützt sein könnten.

**4. Monitoring und Reporting**

Eine Überwachung der eingegebenen Inhalte findet nicht statt. Aggregierte Metadaten zur Nutzung (also z.B. wie oft die Plattform verwendet wird) werden zentral
gesammelt. Geteilte Workflows sind für Mitarbeiter der gleichen Abteilung sowie Administratoren sichtbar, konkrete Ausführungen werden nicht
geteilt.

**5. Notfallmaßnahmen**

Bei Verstößen gegen diese Richtlinien oder Verdacht auf Datenschutzverletzungen melden Sie den Vorfall sofort an den
IT-Administrator.

**6. Aktualisierungen**

Wenn diese Richtlinien aktualisiert werden, werden Sie erneut aufgefordert, sie zu akzeptieren. 
`;
