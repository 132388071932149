import { Typography, Button, Option, Select } from "@mui/joy";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useGuidelines } from "../../lib/api/guidelines";
import { useCurrentOrganizationId } from "../../lib/api/trpc/helpers/useCurrentOrganizationId";
import { Error, Replay } from "@mui/icons-material";
import { useNavigate } from "../../router";
import { GuidelinesModal } from "../util/GuidelinesModal";
import { ChatItem } from "./ChatItem";
import { MessageEditor } from "./MessageEditor";
import { ModelIcon } from "../../lib/ModelIcon";
import type { Message } from "../../../../backend/src/api/chat/message/messageTypes";
import type { LlmName } from "../../../../backend/src/ai/llmMeta";
import { LLM_META } from "../../../../backend/src/ai/llmMeta";
import { trpc } from "../../lib/api/trpc/trpc";

export function ErrorMessage({
  message,
  onEdit,
  selectModel,
}: {
  message: Message;
  onEdit: (content: string) => void;
  selectModel: (model: LlmName) => void;
}) {
  const [errorCode, errorCodeDetails] = message.errorCode?.split(":") ?? [];

  const [editing, setEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(message.content);

  const { guidelines } = useGuidelines();

  const onEditSafe = (newContent: string) => {
    console.log("onEditSafe", newContent);
    if (
      newContent.trim().length > 0 &&
      newContent.trim() !== message.content.trim()
    ) {
      onEdit(newContent);
    }
    setEditing(false);
    setEditedContent(message.content);
  };

  return (
    <ChatItem
      icon={<Error sx={{ fontSize: "38px", color: "#F16B15" }} />}
      message={message}
      error
    >
      <div style={{ minHeight: "3rem" }}>
        <div>
          {editing ? (
            <MessageEditor
              editedContent={editedContent}
              setEditedContent={setEditedContent}
              setEditing={setEditing}
              onEditSafe={onEditSafe}
            />
          ) : (
            <>
              <ErrorMessageText
                errorCode={errorCode}
                errorCodeDetails={errorCodeDetails}
                guidelines={guidelines}
                message={message}
              />
              <ErrorMessageOptions
                errorCode={errorCode}
                errorCodeDetails={errorCodeDetails}
                guidelines={guidelines}
                message={message}
                onEdit={onEdit}
                selectModel={selectModel}
                setEditing={setEditing}
              />
            </>
          )}
        </div>
      </div>
    </ChatItem>
  );
}

function ErrorMessageText({
  errorCode,
  errorCodeDetails,
  message,
  guidelines,
}: {
  errorCode: string;
  errorCodeDetails: string;
  message: Message;
  guidelines: string | null | undefined;
}) {
  const { t } = useTranslation();
  let errorMessageExtension = "";
  let filterCategories: string | null = null;
  if (errorCode === "content_filter") {
    if (errorCodeDetails !== "") {
      errorMessageExtension = "_reason";
    }
    filterCategories = errorCodeDetails === "" ? null : errorCodeDetails;
  }
  return (
    <>
      {errorCode !== "context_length_exceeded" && (
        <Typography
          level="body-md"
          fontStyle="italic"
          color="danger"
          mb={2}
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          &quot;{message.content}&quot;
        </Typography>
      )}

      <Typography whiteSpace="pre-wrap" mb={2} pr={7}>
        {t("modelErrors." + errorCode + errorMessageExtension, {
          model: LLM_META[message.generationModel ?? ""]?.name,
          filterCategories,
        })}
        {errorCode === "content_filter" &&
          guidelines &&
          t("modelErrors.readGuidelines")}
        {errorCode === "unknown_error" ? "." : ":"}
      </Typography>
    </>
  );
}

function ErrorMessageOptions({
  errorCode,
  errorCodeDetails,
  onEdit,
  message,
  selectModel,
  guidelines,
  setEditing,
}: {
  errorCode: string;
  errorCodeDetails: string;
  onEdit: (content: string) => void;
  message: Message;
  selectModel: (model: LlmName) => void;
  guidelines: string | null | undefined;
  setEditing: (isEditing: boolean) => void;
}) {
  const { t } = useTranslation();
  const [guidelinesModalOpen, setGuidelinesModalOpen] = useState(false);
  const switchToModel = (model: LlmName) => {
    selectModel(model);
    onEdit(message.content);
  };

  function retry() {
    onEdit(message.content);
  }

  return (
    <>
      <GuidelinesModal
        isOpen={guidelinesModalOpen}
        onClose={() => {
          setGuidelinesModalOpen(false);
        }}
      />
      <div className="flex gap-2">
        <Button
          onClick={retry}
          variant="outlined"
          color="neutral"
          startDecorator={<Replay />}
        >
          {t("retry")}
        </Button>
        {errorCode === "content_filter" && (
          <ContentFilterActions
            guidelines={guidelines}
            setEditing={setEditing}
            setGuidelinesModalOpen={setGuidelinesModalOpen}
          />
        )}
        {errorCode === "context_length_exceeded" && (
          <ContextLengthActions
            errorCodeDetails={errorCodeDetails}
            switchToModel={switchToModel}
          />
        )}
        {errorCode == "rate_limit_exceeded" && (
          <RateLimitActions message={message} switchToModel={switchToModel} />
        )}
      </div>
    </>
  );
}

function ContentFilterActions({
  guidelines,
  setEditing,
  setGuidelinesModalOpen,
}: {
  guidelines: string | null | undefined;
  setEditing: (isEditing: boolean) => void;
  setGuidelinesModalOpen: (isOpen: boolean) => void;
}) {
  const { t } = useTranslation();
  return (
    <>
      <Button
        onClick={() => setEditing(true)}
        variant="outlined"
        color="neutral"
      >
        {t("editMessage")}
      </Button>
      {guidelines && (
        <Button
          onClick={() => {
            setGuidelinesModalOpen(true);
          }}
          variant="outlined"
          color="neutral"
        >
          {t("openGuidelines")}
        </Button>
      )}
    </>
  );
}

function ContextLengthActions({
  errorCodeDetails,
  switchToModel,
}: {
  errorCodeDetails: string;
  switchToModel: (model: LlmName) => void;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const organizationId = useCurrentOrganizationId();

  const { data: enabledModels } = trpc.modelConfig.getEnabled.useQuery();

  const chatContextTokens = parseInt(errorCodeDetails ?? "0", 10);
  const largerModels: LlmName[] =
    enabledModels
      ?.filter(
        (model) =>
          LLM_META[model].allowChat &&
          LLM_META[model].contextWindow >= chatContextTokens
      )
      .sort() ?? [];

  return (
    <div className="flex items-center gap-3">
      {largerModels.length > 0 && (
        <Select variant="outlined" placeholder={t("switchToLargerModel")}>
          {largerModels.map((model) => (
            <Option
              key={model}
              onClick={() => {
                switchToModel(model);
              }}
              value={model}
            >
              <div className="space-between flex">
                {LLM_META[model].name}
                <ModelIcon modelName={model} className="ml-2 h-5 w-5" />
              </div>
            </Option>
          ))}
        </Select>
      )}
      <Typography>{t("or")}</Typography>
      <Button
        onClick={() =>
          navigate("/:organizationId", {
            params: {
              organizationId,
            },
          })
        }
      >
        {t("newChat")}
      </Button>
    </div>
  );
}

function RateLimitActions({
  switchToModel,
  message,
}: {
  switchToModel: (model: LlmName) => void;
  message: Message;
}) {
  const { t } = useTranslation();

  const { data: enabledModels } = trpc.modelConfig.getEnabled.useQuery();

  return (
    <Select variant="outlined" placeholder={t("switchToAnotherModel")}>
      {enabledModels?.map(
        (model) =>
          model !== message.generationModel && (
            <Option
              key={model}
              onClick={() => {
                switchToModel(model);
              }}
              value={model}
            >
              <div className="space-between flex">
                {LLM_META[model].name}
                <ModelIcon modelName={model} className="ml-2 h-5 w-5" />
              </div>
            </Option>
          )
      )}
    </Select>
  );
}
