import { ArrowForward } from "@mui/icons-material";
import { Card, Typography } from "@mui/joy";
import { useState } from "react";
import type {
  Chat,
  ModelOverride,
} from "../../../../backend/src/api/chat/chatTypes";
import { ChatInput } from "../../components/chat/ChatInput";
import { ChatSettingsMenu } from "../../components/chat/ChatSettingsMenu";
import { useGuide } from "../../components/onboarding/useGuide";
import { trpc } from "../../lib/api/trpc/trpc";
import { useQueuedMessagesStore } from "../../lib/context/queuedMessagesStore";
import { useTranslation } from "../../lib/i18n";
import { useRelativeTime } from "../../lib/util";
import { useNavigate, useParams } from "../../router";

export function ChatIndexScreen() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-1 flex-col items-stretch">
      <div className="flex flex-1 flex-col items-center">
        <div className="flex flex-col items-center gap-10">
          <Typography level="h1" color="neutral">
            {t("continue")}
          </Typography>
          <LastChatsTiles />
        </div>
      </div>
      <div className="flex flex-col items-stretch justify-center gap-4 bg-gradient-to-t from-white from-50% to-transparent p-10 py-8">
        <QuickStart />
      </div>
    </div>
  );
}

function QuickStart() {
  const navigate = useNavigate();
  const params = useParams("/:organizationId");
  const utils = trpc.useUtils();
  const { mutateAsync: createChat } = trpc.chat.create.useMutation();
  const queueMessage = useQueuedMessagesStore((s) => s.addQueuedMessage);
  const { setRun, setStep, step, completed } = useGuide();

  const [selectedModel, setSelectedModel] = useState<ModelOverride | null>(
    null
  );
  const [ragMode, setRagMode] = useState(false);

  async function postMessage(firstMessage: string, attachmentIds: string[]) {
    const chat = await createChat({
      modelOverride: selectedModel ?? undefined,
      ragMode,
    });

    await utils.chat.invalidate();

    navigate(`/:organizationId/chats/:chatId`, {
      params: {
        ...params,
        chatId: chat.id,
      },
    });

    queueMessage({
      content: firstMessage,
      chatId: chat.id,
      attachmentIds,
      ragMode,
    });
    console.log("completed", completed);
    if (!completed) {
      setRun(false);
      setStep(step + 1);
    }
  }

  return (
    <ChatInput
      ragMode={ragMode}
      setRagMode={setRagMode}
      postMessage={postMessage}
      model={selectedModel}
      setModelOverride={setSelectedModel}
      startDecorator={
        <ChatSettingsMenu
          selectedModel={selectedModel}
          setSelectedModel={setSelectedModel}
        />
      }
    />
  );
}

function LastChatsTiles() {
  const chats = trpc.chat.getAll.useQuery().data;
  const lastChats = chats?.slice(0, 3) ?? [];

  return (
    <div className="grid grid-cols-3 gap-3">
      {lastChats.map((chat) => (
        <ChatTile key={chat.id} chat={chat} />
      ))}
    </div>
  );
}

function ChatTile({ chat }: { chat: Chat }) {
  const navigate = useNavigate();
  const params = useParams("/:organizationId");
  const time = useRelativeTime(new Date(chat.updatedAt));

  return (
    <Card
      className="flex w-64 cursor-pointer flex-col justify-between transition-all hover:shadow-lg"
      onClick={() => {
        navigate(`/:organizationId/chats/:chatId`, {
          params: {
            ...params,
            chatId: chat.id,
          },
        });
      }}
    >
      <Typography
        level="title-lg"
        sx={{
          maxHeight: "8rem",
          overflow: "hidden",
          wordBreak: "break-word",
        }}
      >
        {chat.name}
      </Typography>
      <div className="flex flex-row items-center justify-between">
        <Typography>{time}</Typography>
        <ArrowForward />
      </div>
    </Card>
  );
}
