import { List } from "@mui/icons-material";
import { Dropdown, Menu, MenuButton, MenuItem, Typography } from "@mui/joy";
import type { SxProps } from "@mui/system";
import { Stack } from "@mui/system";
import { useMyWorkshops } from "../../lib/api/workshop";
import { useNavigate } from "../../router";
import { useParams } from "../../router";

export function WorkshopSwitcher({ sx }: { sx?: SxProps }) {
  const params = useParams("/:organizationId/learn/:workshopId");
  const organization = params.organizationId;
  const workshopIds = useMyWorkshops();
  const navigate = useNavigate();
  return (
    <div>
      {(workshopIds?.length ?? 0) > 1 ? (
        <Stack direction="row" alignItems="center" spacing={1} sx={sx}>
          <Typography>Meine Workshops</Typography>
          <Dropdown>
            <MenuButton>
              <List />
            </MenuButton>
            <Menu>
              {workshopIds?.map((w) => (
                <MenuItem
                  key={w.id}
                  onClick={() => {
                    navigate("/:organizationId/learn/:workshopId", {
                      params: {
                        organizationId: organization,
                        workshopId: w.id,
                      },
                    });
                  }}
                >
                  {w.name}
                </MenuItem>
              ))}
            </Menu>
          </Dropdown>
        </Stack>
      ) : null}
    </div>
  );
}
