import { SegmentDisplay } from "../input/SegmentDisplay.tsx";
import { useTranslation } from "../../lib/i18n.ts";
import { type ImageAspectRatio } from "../../../../backend/src/api/tools/images/imagesTypes.ts";

export function SizeSelector({
  input,
  setInput,
  disabled,
}: {
  input: ImageAspectRatio;
  setInput: (aspectRatio: ImageAspectRatio) => void;
  disabled?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <SegmentDisplay<ImageAspectRatio>
      size="md"
      buttonFlex={1}
      disabled={disabled}
      options={[
        {
          label: t("square"),
          value: "1:1",
        },
        {
          label: t("images.wide"),
          value: "16:9",
        },
        {
          label: t("images.high"),
          value: "9:16",
        },
      ]}
      value={input}
      setValue={setInput}
    />
  );
}
