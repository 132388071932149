import { create } from "zustand";
import { persist } from "zustand/middleware";

const useInfoModalStore = create(
  persist<{
    closedIds: string[];
    onModalClose: (id: string) => void;
  }>(
    (set) => ({
      closedIds: [],
      onModalClose: (id) =>
        set((state) => ({ closedIds: [...state.closedIds, id] })),
    }),
    {
      name: "closable-info-modal",
    }
  )
);

export function useClosableInfoModal(id: string) {
  const { closedIds, onModalClose } = useInfoModalStore();
  return {
    onModalClose: () => onModalClose(id),
    modalOpen: !(closedIds ?? []).includes(id),
  };
}
