import { Card, Modal, Typography, LinearProgress } from "@mui/joy";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import chatBotLoading from "../../assets/chat-bot-show-process.json";
import { GenericLoadingText } from "./GenericLoadingText";

export function FullScreenLoader() {
  const { t } = useTranslation();
  return (
    <Modal open>
      <div className="flex h-screen w-screen items-center justify-center">
        <div className="flex h-full w-full flex-col items-center justify-center pb-36">
          {/* <Card>
          </Card> */}
          <Card>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: chatBotLoading,
              }}
              height={350}
              width={350}
              isClickToPauseDisabled
            />
            <div className="flex flex-col items-center gap-4 px-4 pb-8">
              <Typography level="h3">
                {t("workflowWizard.progressTitle")}
              </Typography>
              <div className="flex flex-row items-center gap-4">
                <Typography level="body-lg">
                  <GenericLoadingText />
                </Typography>
              </div>
              <div className="mt-3 w-full">
                <LinearProgress color="primary" size="md" value={35} />
              </div>
            </div>
          </Card>
        </div>
      </div>
    </Modal>
  );
}
