import AddIcon from "@mui/icons-material/Add";
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  List,
  Modal,
  ModalClose,
  ModalDialog,
  Option,
  Select,
  Typography,
} from "@mui/joy";
import ListItem from "@mui/joy/ListItem";
import Pagination from "@mui/material/Pagination";
import type { Tag } from "apiTypes/dist/payload-types";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { useCreateWorkshop } from "../../lib/api/learning.ts";
import { trpc } from "../../lib/api/trpc/trpc.ts";

export function NewWorkshopButton() {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Button startDecorator={<AddIcon />} onClick={() => setModalOpen(true)}>
        {t("newWorkshop")}
      </Button>
      <NewWorkshopModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
      />
    </>
  );
}

function generateCode() {
  let code = Math.floor(Math.random() * 1000000).toString();
  while (code.length < 6) {
    code = "0" + code;
  }
  return code;
}

function NewWorkshopModal({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: VoidFunction;
}) {
  const { t } = useTranslation();

  const { data: user } = trpc.user.me.useQuery();

  const validationSchema = z.object({
    title: z.string({
      required_error: `${t("title")} ${t("errors.cantBeEmpty")}`,
    }),
    loginCode: z
      .string({
        required_error: `${t("accessCode")} ${t("errors.cantBeEmpty")}`,
      })
      .regex(/^\d{6}$/, t("sixDigits")),
    includedCourses: z.array(z.number()),
    organizationId: z.string(),
  });

  type FormValues = z.infer<typeof validationSchema>;

  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

  const [page, setPage] = useState(1);

  const { data: courseResponse } = trpc.elearning.course.getAll.useQuery(
    {
      tags: selectedTags.map((tag) => tag.id),
      page,
      pageSize: 15,
    },
    { placeholderData: (prev) => prev }
  );

  const courses = courseResponse?.docs;
  const totalPages = courseResponse?.totalPages;

  const { data: tags } = trpc.elearning.tag.getAll.useQuery();

  const createWorkshop = useCreateWorkshop();

  async function handleSubmit(
    { title, loginCode, includedCourses, organizationId }: FormValues,
    { resetForm }
  ) {
    await createWorkshop({
      name: title,
      loginCode,
      courses: (includedCourses ?? []).map((id) => String(id)),
      status: "PENDING",
      organizationId,
    }).then(() => {
      handleClose();
      resetForm();
    });
  }

  if (!user) {
    return;
  }
  const viableOrganizations = user.organizations.filter(
    (org) => org.id !== "academy"
  );
  const initialValues: FormValues = {
    title: "",
    loginCode: generateCode(),
    includedCourses: [],
    organizationId: viableOrganizations[0].id,
  };

  return (
    <Modal
      open={open}
      onClose={(_e, reason: string) => {
        if (reason === "closeClick") handleClose();
      }}
    >
      <ModalDialog minWidth="sm">
        <ModalClose />
        <Typography level="title-lg">{t("createWorkshop")}</Typography>

        <Formik
          initialValues={initialValues}
          validationSchema={toFormikValidationSchema(validationSchema)}
          onSubmit={handleSubmit}
          validateOnMount={true}
        >
          {({
            errors,
            touched,
            resetForm,
            setFieldValue,
            values,
            getFieldProps,
            isValid,
            isSubmitting,
          }) => (
            <Form className="flex flex-col gap-4 overflow-hidden">
              <FormControl
                error={Boolean(errors.title) && touched.title}
                required
              >
                <FormLabel>{t("title")}</FormLabel>
                <Input placeholder="Titel" {...getFieldProps("title")} />
                <FormHelperText>
                  {errors.title && touched.title
                    ? errors.title
                    : t("titleForWorkshop")}
                </FormHelperText>
              </FormControl>

              <FormControl
                error={Boolean(errors.loginCode) && touched.loginCode}
              >
                <FormLabel>{t("accessCode")}</FormLabel>
                <Input
                  id="loginCode"
                  placeholder="Zugangscode"
                  inputMode="numeric"
                  {...getFieldProps("loginCode")}
                  error={Boolean(errors.loginCode) && touched.loginCode}
                />
                <FormHelperText>
                  {errors.loginCode && touched.loginCode
                    ? errors.loginCode
                    : t("accessCodeForWorkshop")}
                </FormHelperText>
              </FormControl>

              <FormControl
                error={Boolean(errors.organizationId) && touched.organizationId}
              >
                <FormLabel>{t("organization")}</FormLabel>
                <Select
                  disabled={viableOrganizations.length === 1}
                  value={values.organizationId}
                  onChange={(organizationId) =>
                    setFieldValue("organizationId", organizationId)
                  }
                  id="organizationId"
                >
                  {viableOrganizations.map((organization) => (
                    <Option value={organization.id} key={organization.id}>
                      {organization.name}
                    </Option>
                  ))}
                </Select>
                <FormHelperText>
                  {t("owningOrganizationOfWorkshop")}
                </FormHelperText>
              </FormControl>

              <div role="group" className="flex flex-col overflow-hidden">
                <Typography id="course-group" level="body-sm" fontWeight="lg">
                  {t("courses")}
                </Typography>
                <Autocomplete
                  className="mt-1"
                  multiple
                  id="tags-default"
                  placeholder="Tags"
                  loading={tags === undefined}
                  options={tags ?? []}
                  getOptionLabel={(tag) => tag.name}
                  getOptionKey={(tag) => tag.id}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={selectedTags}
                  onChange={(e, value) =>
                    // todo: should selected courses be unselected when their tag is no longer included in the filter?
                    setSelectedTags(value)
                  }
                />
                <List className="min-h-0 overflow-auto">
                  {(courses ?? [])
                    .sort((a, b) => a.title.localeCompare(b.title))
                    .map((course, index) => (
                      <ListItem key={index}>
                        <Checkbox
                          label={course.title}
                          checked={
                            values.includedCourses?.includes(course.id) ?? false
                          }
                          onChange={async (e) => {
                            if (e.target.checked) {
                              await setFieldValue("includedCourses", [
                                ...(values.includedCourses ?? []),
                                course.id,
                              ]);
                            } else {
                              await setFieldValue(
                                "includedCourses",
                                values.includedCourses?.filter(
                                  (id) => id !== course.id
                                )
                              );
                            }
                          }}
                        />
                        <div className="flex gap-2">
                          {course.Tags?.map((tag) => {
                            if (typeof tag == "number") return null;
                            return (
                              <Chip
                                key={tag.id}
                                onClick={() => {
                                  setSelectedTags((prev) => {
                                    if (
                                      prev.some(
                                        (prevTag) => prevTag.id === tag.id
                                      )
                                    ) {
                                      return prev;
                                    }
                                    return [...prev, tag];
                                  });
                                }}
                                title={tag.name}
                                color="primary"
                                size="md"
                                variant="soft"
                              >
                                {tag.name}
                              </Chip>
                            );
                          })}
                        </div>
                      </ListItem>
                    ))}
                </List>
                {totalPages && totalPages > 1 && (
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={(_e, value) => setPage(value)}
                  />
                )}
              </div>
              <div className="flex justify-end gap-2">
                <Button
                  variant="plain"
                  onClick={() => {
                    resetForm();
                    handleClose();
                  }}
                >
                  {t("cancel")}
                </Button>
                <Button
                  type="submit"
                  variant="solid"
                  disabled={!isValid}
                  loading={isSubmitting}
                >
                  {t("create")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalDialog>
    </Modal>
  );
}
