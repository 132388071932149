import { Button } from "@mui/joy";
import { LargeDocumentPreview } from "../../../chat/attachments/DocumentChip";
import { useTranslation } from "react-i18next";
import { Delete } from "@mui/icons-material";
import { ActionButtons } from "../ActionButtons";

export function FileChosen({
  tooltip,
  title,
  setFile,
  handleTranslation,
  loading,
}: {
  tooltip: string;
  title: string;
  setFile: (file: File | undefined) => void;
  handleTranslation: () => void;
  loading?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <>
      <LargeDocumentPreview tooltip={tooltip} title={title} />

      {!loading && (
        <ActionButtons>
          <Button
            sx={{ maxWidth: "400px" }}
            size="lg"
            fullWidth
            onClick={handleTranslation}
          >
            {t("translate")}
          </Button>
          <Button
            size="sm"
            color="neutral"
            variant="plain"
            onClick={() => {
              setFile(undefined);
            }}
            endDecorator={<Delete />}
          >
            {t("remove")}
          </Button>
        </ActionButtons>
      )}
    </>
  );
}
