import { PlayCircle } from "@mui/icons-material";
import { CircularProgress, Skeleton } from "@mui/joy";
import {
  BarList,
  Bold,
  Card,
  Flex,
  LineChart,
  Metric,
  Text,
  Title,
} from "@tremor/react";
import type { PropsWithChildren } from "react";
import { useState } from "react";
import { trpc } from "../../lib/api/trpc/trpc";
import { useTranslation } from "../../lib/i18n";
import TimespanSelection from "./TimespanSelection";

function LazyMetric({
  loading,
  children,
}: PropsWithChildren<{ loading: boolean }>) {
  return (
    <Metric>
      {loading ? (
        <Skeleton variant="inline" height="1em" animation="wave">
          1234.56
        </Skeleton>
      ) : (
        children
      )}
    </Metric>
  );
}

function BarListSkeleton() {
  return (
    <div className="flex flex-col gap-2">
      {[...Array(20)].map((_, index) => {
        return (
          <Skeleton
            key={index}
            variant="rectangular"
            height="1.7em"
            width={((20 - index) / 20) * 100 + "%"}
          />
        );
      })}
    </div>
  );
}

export function AdoptionMetrics() {
  const { t } = useTranslation();
  const [timeSpan, setTimeSpan] = useState(30);

  const timeSpanMap = {
    30: t("timespan.30days"),
    90: t("timespan.90days"),
    365: t("timespan.365days"),
    [365 * 100]: t("timespan.lifetime"),
  };

  const { data: metrics, isFetching } =
    trpc.organizationMetrics.getOrganizationMetrics.useQuery({
      timeSpanDays: timeSpan,
    });

  const { data: productConfig } = trpc.productConfig.get.useQuery();
  const anonymize = !productConfig || productConfig.anonymizeMetrics;

  return (
    <div className="flex flex-col gap-3 p-1">
      <TimespanSelection
        timespan={timeSpan}
        setTimespan={setTimeSpan}
        timespanMap={timeSpanMap}
      />
      <div className="flex flex-row items-stretch gap-3">
        <Card>
          <Title>{t("metrics.promptCount")}</Title>
          <LazyMetric loading={isFetching}>{metrics?.numPrompts}</LazyMetric>
        </Card>
        <Card>
          <Title>{t("activeUsers")}</Title>
          <LazyMetric loading={isFetching}>
            {metrics?.activeUserCount}
          </LazyMetric>
          <Text>
            {t("metrics.ofTotalUsers", {
              total: metrics?.totalUserCount,
              percentage: metrics?.percentageActiveUsers,
            })}
          </Text>
        </Card>
        <Card>
          <Title>{t("metrics.promptsPerDay")}</Title>
          <LazyMetric loading={isFetching}>
            {metrics?.averagePromptsPerDay}
          </LazyMetric>
        </Card>
        <Card>
          <Title>{t("metrics.workflowsPerDay")}</Title>
          <LazyMetric loading={isFetching}>
            {metrics?.averageWorkflowRunsPerDay}
          </LazyMetric>
        </Card>
        <Card>
          <Title>{t("metrics.totalMinutesSaved")}</Title>
          <Text>{t("metrics.totalMinutesSavedDescriptions")}</Text>
          <LazyMetric loading={isFetching}>
            {minutesToHoursAndMinutes(metrics?.totalMinutesSaved ?? 0)}
          </LazyMetric>
        </Card>
      </div>

      <Card>
        <Title>{t("activeUsers")}</Title>
        {isFetching ? (
          <div className="flex h-80 items-center justify-center">
            <CircularProgress />
          </div>
        ) : (
          <LineChart
            className="h-80"
            data={
              metrics?.dailyActiveUsers.map((day) => ({
                day: new Date(day.day).toLocaleDateString(),
                "Active user count": day.count,
              })) ?? []
            }
            index="day"
            categories={["Active user count"]}
            yAxisWidth={60}
            allowDecimals={false}
          />
        )}
      </Card>

      <Card>
        <Title>{t("metrics.mostUsedWorkflows")}</Title>
        <Flex className="my-4">
          <Text>
            <Bold>Workflow</Bold>
          </Text>
          <Text>
            <Bold>{t("metrics.workflowRuns")}</Bold>
          </Text>
        </Flex>
        {isFetching ? (
          <BarListSkeleton />
        ) : (
          <BarList
            data={
              metrics?.mostUsedWorkflows.map((workflow) => ({
                name: workflow.name,
                value: workflow.value,
                icon: () => (
                  <PlayCircle
                    fontSize="small"
                    style={{ marginRight: "8px", alignSelf: "center" }}
                  />
                ),
              })) ?? []
            }
            valueFormatter={(value) => value.toFixed(0)}
          />
        )}
      </Card>

      {!anonymize && (
        <Card>
          <Title>{t("metrics.topUsers")}</Title>
          <Text>{t("metrics.topPromptUsersDescription")}</Text>
          <Flex className="mb-1 mt-4">
            <Text>
              <Bold>User</Bold>
            </Text>
            <Text>
              <Bold>Prompts</Bold>
            </Text>
          </Flex>
          {isFetching ? (
            <BarListSkeleton />
          ) : (
            <BarList data={metrics?.topUsersByMessages ?? []} />
          )}
        </Card>
      )}
    </div>
  );
}

function minutesToHoursAndMinutes(minutes: number) {
  minutes = Math.round(minutes);
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  return `${hours}h ${remainingMinutes}min`;
}
